import React, {useState, useEffect} from 'react';
import { navigate } from "gatsby"
import styles from './ResetPassword.module.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BeckLogoShadow3D from "../../../images/beck-logo-3d-shadow.png"
import { resetPassword } from '../../../api/api'
import ErrorTooltip from '../../ErrorTooltip/ErrorTooltip';
import { FaArrowLeft } from 'react-icons/fa';

const ResetPassword = ({onRouteChange, setToken, requestId}) => {

    const [errors, setErrors] = useState({
        password: "",
        confirm: "",
        requestId: ""
    }); //list of errors

    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    useEffect(() => {
      if(errors.requestId) {
          alert(errors.requestId);
          navigate("/portal/signin")
      }  
    }, [errors])
    
    // Function to traverse through the array of errors, and passing the appropriate message into the errors state
    const initializeFlags = (newErrors) => {
        let flags = { ...errors };
        for(const key in newErrors)
        {
            flags[key] = newErrors[key];
        }
        
        setErrors(flags);
    }

    const formatErrorResponse = (data) => {
        let obj = {};
        data.forEach((item) => {
            obj[item.param] = item.msg;
        })
        return obj;
    }

    const clearError = (field) => {
        let obj = {...errors};
        obj[field] = '';
        setErrors(obj);
    }


    const handleSubmit = (event) => {
        event.preventDefault();
      
        if(confirm !== password) {
            console.log(confirm, password);
            initializeFlags({
                confirm: "Confirmed password must be the same as the new password."
            })
            return;
        }

        let data = {
          requestId: requestId,
          password: password
        };
        
        resetPassword(data)
            .then(res => { 
                alert("Reset password successful.")
                navigate("/portal/signin")
            })
            .catch(function (err) {
                if(err.response.data.status == 404) {
                    initializeFlags({
                        requestId: "The password reset request has expired or does not exist."
                    })
                }
                else if(err.response.data?.data) {
                    const errs = formatErrorResponse(err.response.data.data);
                    initializeFlags(errs);
                }
                
            });
    }

    return (
        <div className={`${styles.page} d-flex justify-content-center align-items-center`}>
            <img alt="Beck College Prep Logo" className={`${styles.logo}`} src={BeckLogoShadow3D}></img>
            <div className={`${styles.contact}`}>
                <h1 className={`${styles.heading}`}>Reset Password</h1>
                <div className = 'm-3'>
                    <Form id='resetpassword' className="" onSubmit={handleSubmit}>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                <div onFocus={()=> clearError("password")} style={{position: 'relative'}}>
                                    {errors?.password && errors.password !== "" ? <ErrorTooltip errorMsg={errors.password}/> : null}
                                    <Form.Control className = {`${styles.control} ${styles.box}`} id='password' type="password" placeholder="New Password" required onChange={(e) => setPassword(e.target.value)}/>
                                </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col>
                                <Form.Group>
                                <div onFocus={()=>clearError("confirm")} style={{position: 'relative'}}>
                                    {errors?.confirm && errors.confirm !== "" ? <ErrorTooltip errorMsg={errors.confirm}/> : null}
                                    <Form.Control className = {`${styles.control} ${styles.box}`} id='confirmPassword' type='password' placeholder="Confirm Password" required onChange={(e) => setConfirm(e.target.value)}/>
                                </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Row className='justify-content-start'>
                            <Col xs={12} sm={8} >
                                <Button type='submit' id='resetPasswordBtn' className={`${styles.bcpButton} btn-block `}>
                                   Reset Password
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div className="mt-3">
                        <a className={styles.backLink} href="/portal/signin"><FaArrowLeft width={18} height={18}/>&emsp;Back to Sign In</a>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default ResetPassword;