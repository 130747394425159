import React,{useState, useEffect} from 'react'
import styles from './SearchAllButton.module.css'
import './SearchAllButton.css'
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import 'rc-time-picker/assets/index.css';
import { FaSearch } from 'react-icons/fa';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {getDiagnostics, getCourses} from '../../../../api/api'

// Component
const SearchAllButton = ({setCurrentEvent}) => {
    const [show, setShow] = useState(false); // modal status
    const [diagnosticResults, setDiagnosticResults] = useState(null);
    const [courseResults, setCourseResults] = useState(null);
    const [name, setName] = useState('')

    useEffect(() => {
        document.addEventListener('keydown', handleSearchEnter, { passive: true })

        return function cleanup() {
            document.removeEventListener('keydown', handleSearchEnter, { passive: true })
        }
    }, [name])

    //closes modal and resets data
    const handleClose = () => {
        setShow(false);
        setTimeout(()=> {
            setDiagnosticResults(null)
            setCourseResults(null)
            setName('')
        }, 500); // stops info from changing before closing animation finishes
    }

    // Function to submit search when enter is pressed
    const handleSearchEnter = (event) => {
        if (event.code == 'Enter') {
            if(show) {
                console.log(name)
                handleSearch()
            }
        }
    }

    const handleSearch = () => {
        
        let searchObj = {
            studentName: name
        }

        getDiagnostics(searchObj)
        .then(res => {
            console.log(res.data)
            setDiagnosticResults(res.data)
        })
        .catch(err => console.log(err))

        getCourses(searchObj)
        .then(res => {
            console.log(res.data)
            setCourseResults(res.data)
        })
        .catch(err => console.log(err))
    }

    const handleSelect = (event, type) => {
        let calendarEvent
        if(type === 'diagnostic'){
            calendarEvent = {
                id: event._id,
                title: `${event.name}`,
                desc: event.description,
                type: 'diagnostic',
                start: new Date(event.date.start),
                end: new Date(event.date.end)
            }
        }
        else if(type==='course'){
            calendarEvent = {
                id: event._id,
                title: `${event.name}`,
                desc: event.description,
                type: (event.courseType ? event.courseType : event.meetings[0].entryType),
                start: new Date(event.meetings[0].start),
                end: new Date(event.meetings[0].end)
            }
        }
        
        console.log(calendarEvent)
        setCurrentEvent(calendarEvent);
        handleClose()
    }
    
    return (
        <>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            id='add-event-modal'
        >
            <Modal.Header closeButton>
            <Modal.Title>Search for a Student</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control placeholder='Name' onChange={(e) => setName(e.target.value)}></Form.Control>
                    </Form.Group>
                    {diagnosticResults || courseResults ?
                        <>
                            <p style={{marginBottom: '5px'}}>Student was Found in:</p>
                            <div style={{marginBottom: '20px'}}>
                            <p style={{marginBottom: '2px', color: '#DC3F3A'}}>Diagnostics:</p>
                            {diagnosticResults && diagnosticResults.length > 0 ? 
                                <>
                                {diagnosticResults.map(event => {
                                    return(
                                        <p key={`diagnostic entry ${event._id}`} className={styles.resultEntry} onClick={() => handleSelect(event, 'diagnostic')}>
                                            {new Date(event.date.start).toLocaleDateString()} {new Date(event.date.start).toLocaleTimeString()} {event.name}
                                        </p>
                                    )
                                })}
                                </>
                            :
                                <p style={{color: 'black', marginBottom: '0', marginLeft: '10px'}}>No Diagnostic Results</p>
                            }
                            <p style={{marginBottom: '2px', color: '#DC3F3A'}}>Courses:</p>
                            {courseResults && courseResults.length > 0 ?
                                <>
                                {courseResults.map(event => {
                                    return(
                                        <p key={`course entry ${event._id}`} className={styles.resultEntry} onClick={() => handleSelect(event, 'course')}>
                                            {new Date(event.meetings[0].start).toLocaleDateString()}-{new Date(event.meetings[event.meetings.length-1].start).toLocaleDateString()} {event.name}
                                        </p>
                                    )
                                })}
                                </>
                            :
                                <p style={{color: 'black', marginBottom: '0', marginLeft: '10px'}}>No Course Results</p>
                            }
                            </div>
                        </>
                    :
                        null
                    }
                <Button className={`${styles.newButton} ${styles.submitBtn}`} onClick={handleSearch}>
                    Search
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Body>
        </Modal>
        <Button className={`${styles.newButton}`} onClick={() => setShow(true)}><FaSearch style={{fontSize: '18px'}}></FaSearch></Button>
        </>
    )
}

export default SearchAllButton;