import React, {useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby'
import styles from './Register.module.css'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import ErrorTooltip from '../../ErrorTooltip/ErrorTooltip';
import { verifyRegistrationKey, register, generateRegistrationLink } from '../../../api/api'

const Register = ( props ) => {
    const [loaded, setLoaded] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [successful, setSuccessful] = useState()
    const [show, setShow] = useState()
    const [errors, setErrors] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [role, setRole] = useState()
    const [requestId, setRequestId] = useState()

    let initial = useRef(true)

    useEffect(() => {
        // Check if the registration key provided in the url is valid, if not redirect them off of the registration page
        verifyRegistrationKey(props.id)
            .then(res => {
                console.log(res)
                setRole(res.data.registrationRequest.role)
                setRequestId(res.data.registrationRequest.requestId)
            })
            .catch((err) => {
                console.log(err)
                navigate('/portal')
            })
    }, [])

    useEffect(() => {
        setLoaded(true)
    }, [role])

    useEffect(() => {
        if (initial.current !== true) {
            setShow(true)
        }

        initial.current = false
    }, [successful])

    useEffect(() => {
        console.log(errors)

        if (checkErrorsValid()) {
            setSubmitted(false)
        }
    }, [errors])

    const handleRegister = (event) => {
        event.preventDefault();

        setSubmitted(true)

        if (confirmPassword !== password) {
            handleError('confirmPass', 'Passwords do not Match!')
        } else {
            let data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                role: role,
                requestId: requestId
            }

            register(data)
                .then(res => {
                    console.log(res)
                    setSuccessful(true)
                })
                .catch(err => {
                    console.log(err.response.data)
                    let resErrors = err.response.data.data

                    handleMultipleErrors(resErrors)

                    setSuccessful(false)
                })
        }
    }

    function clearError (field) {
        handleError(field, '')
    }

    function handleError (field, value) {
        let temp = { ...errors }
        temp[field] = value
        setErrors(temp)
    }

    function handleMultipleErrors(errorList) {
        let temp = { ...errors }

        for (let i = 0; i < errorList.length; i++) {
            // Only set the error if it is currently undefined
            if (temp[errorList[i].param] === '' || temp[errorList[i].param] === undefined || temp[errorList[i].param] === null) {
                // Don't set errors for requestId and role since the user can't set these values
                if (temp[errorList[i].param] !== 'requestId' || temp[errorList[i].param] !== 'role') {
                    temp[errorList[i].param] = errorList[i].msg
                }
            }
        }

        setErrors(temp)
    }

    function checkErrorsValid() {
        let valid = true

        for (const property in errors) {
            if (errors[property] !== '') {
                valid = false
            }
        }

        return valid
    }

    if (loaded) {
        return (
            <>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Body>
                        {successful ? (
                            <div>
                                Your account was successfully created!
                            </div>
                        ):(
                            <div>
                                There was an error creating your account. Please verify that the provided information is correct and valid.
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        {successful ? (
                            <Link to="/portal/signin">
                                <Button variant="secondary">
                                    Back to Sign In
                                </Button>
                            </Link>
                        ):(
                            <Button variant="secondary" onClick={() => setShow(false)}>
                                Close
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                <div className={`${styles.page} d-flex flex-column align-items-center justify-content-center`}>
                    <div className={`animate__animated animate__fadeIn ${styles.headingContainer}`}>
                        {role === 'ADMIN' && (
                            <h2 className={`${styles.heading}`}>Create Admin Account</h2>
                        )}
                        {role === 'TUTOR' && (
                            <h2 className={`${styles.heading}`}>Create Tutor Account</h2>
                        )}
                        {role === 'USER' && (
                            <h2 className={`${styles.heading}`}>Create Client Account</h2>
                        )}
                    </div>
                    <div className={`${styles.formContainer} animate__animated animate__fadeInUp`}>
                        <Form noValidate id='signin' onSubmit={(e) => handleRegister(e)}>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <div onFocus={() => clearError("firstName")} style={{ position: 'relative' }}>
                                            {errors?.firstName && errors.firstName !== "" && <ErrorTooltip errorMsg={errors.firstName}/>}
                                            <Form.Control className = {`${styles.control} ${styles.box} ${errors?.firstName && errors.firstName !== "" && styles.errorForm}`} id='firstName' type='text' placeholder="First Name" required onChange={(e) => setFirstName(e.target.value)}/>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <div onFocus={() => clearError("lastName")} style={{ position: 'relative' }}>
                                            {errors?.lastName && errors.lastName !== "" && <ErrorTooltip errorMsg={errors.lastName}/>}
                                            <Form.Control className = {`${styles.control} ${styles.box} ${errors?.lastName && errors.lastName !== "" && styles.errorForm}`} id='lastName' type='text' placeholder="Last Name" required onChange={(e) => setLastName(e.target.value)}/>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <div onFocus={() => clearError("email")} style={{ position: 'relative' }}>
                                            {errors?.email && errors.email !== "" && <ErrorTooltip errorMsg={errors.email}/>}
                                            <Form.Control className = {`${styles.control} ${styles.box} ${errors?.email && errors.email !== "" && styles.errorForm}`} id='email' type="email" placeholder="E-mail" required onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <div onFocus={() => clearError("password")} style={{ position: 'relative' }}>
                                            {errors?.password && errors.password !== "" && <ErrorTooltip errorMsg={errors.password}/>}
                                            <Form.Control className = {`${styles.control} ${styles.box} ${errors?.password && errors.password !== "" && styles.errorForm}`} id='password' type='password' autoComplete='new-password' placeholder="Password" required onChange={(e) => setPassword(e.target.value)}/>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <div onFocus={() => clearError("confirmPass")} style={{ position: 'relative' }}>
                                            {errors?.confirmPass && errors.confirmPass !== "" && <ErrorTooltip errorMsg={errors.confirmPass}/>}
                                            <Form.Control className = {`${styles.control} ${styles.box} ${errors?.confirmPass && errors.confirmPass !== "" && styles.errorForm}`} id='confirmPass' type='password' autoComplete='new-password' placeholder="Confirm Password" required onChange={(e) => setConfirmPassword(e.target.value)}/>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Row>
                                <Col>
                                    <div className={`${styles.buttonContainer} d-flex justify-content-center`}>
                                        <Button type='submit' id='signinbtn' className={`${styles.bcpButton} btn-block `} disabled={submitted}>
                                            <span>Register</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}

export default Register;