import React, {useState, useEffect} from 'react';
import { navigate } from "gatsby"
import styles from './SignIn.module.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BeckLogoShadow3D from "../../../images/beck-logo-3d-shadow.png"
import { FaExclamationCircle } from 'react-icons/fa';
import { signIn } from '../../../api/api'
import ErrorTooltip from '../../ErrorTooltip/ErrorTooltip';

// const signInOnEnter = (event) => {
//     if (event.keyCode === 13) {
//         document.getElementById("signinbtn").click();
//     } else if(event.key === 13){
//         document.getElementById("signinbtn").click();
//     }
// }

const SignIn = () => {

    const [errors, setErrors] = useState(['', '']); //list of errors
    const [badLogin, setBadLogin] = useState(false); //bool for wrong login credentials

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const clearError = (index) => {
        let copy = {...errors};
        copy[index] = '';
        setErrors(copy);
    }

    // Function to traverse through the array of errors, and passing the appropriate message into the errors state
    const initializeFlags = (newErrors) => {
        let flags = { ...errors };
        for(const key in newErrors)
        {
            flags[key] = newErrors[key];
        }

        setErrors(flags);
    }

    const formatErrorResponse = (data) => {
        let obj = {};
        data.forEach((item) => {
            obj[item.param] = item.msg;
        })
        return obj;
    }

        
    const handleLogin = (event) => {
        event.preventDefault();
            
        let data = {
          email: email,
          password: password
        };
        
        signIn(data)
            .then(res => { 
                console.log(res)
                navigate("/portal/home")
            })
            .catch(function (err) {
                if(err.response.data.status == 401) 
                    setBadLogin(true);
                else {
                    const errs = formatErrorResponse(err.response.data.data);
                initializeFlags(errs);
                }
            });
    }
    

    return (
        <div className={`${styles.page} d-flex justify-content-center align-items-center`}>
            <img alt="Beck College Prep Logo" className={`${styles.logo} animate__animated animate__fadeIn animate__slow`} src={BeckLogoShadow3D}></img>
            <div className={`${styles.contact} animate__animated animate__fadeInUp`}>
                <h1 className={`${styles.heading}`}>Portal Log-In</h1>
                <div className = 'm-3'>
                    <Form id='signin' onSubmit={(e) => handleLogin(e)}>
                    <Form.Row>
                            <Col>
                                <Form.Group>
                                <div onFocus={()=> clearError("email")} style={{position: 'relative'}}>
                                    {errors?.email && errors.email !== "" ? <ErrorTooltip errorMsg={errors.email}/> : null}
                                    <Form.Control className = {`${styles.control} ${styles.box}`} id='email' type="email" placeholder="E-mail" required onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col>
                                <Form.Group>
                                <div onFocus={()=>clearError("password")} style={{position: 'relative'}}>
                                    {errors?.confirm && errors.confirm !== "" ? <ErrorTooltip errorMsg={errors.confirm}/> : null}
                                    <Form.Control className = {`${styles.control} ${styles.box}`} id='password' type='password' placeholder="Password" required onChange={(e) => setPassword(e.target.value)}/>
                                </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Row className='justify-content-start'>
                            <Col xs={6} sm={5} >
                                <Button type='submit' id='signinbtn' className={`bcp-general-button btn-block `}>
                                    <span>Sign In</span>
                                </Button>
                            </Col>
                        </Row>
                        {badLogin ? <p style={{color: 'red', marginTop: 15}}><FaExclamationCircle className={styles.loginError}/> Wrong email or password</p> : null}
                    </Form>
                    <div className="mt-3">
                        <a className={styles.forgotPassLink} href="/portal/forgot-password">Forgot Password?</a>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default SignIn;