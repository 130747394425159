import React,{useState, useEffect} from 'react'
import styles from '../../../calendar_components/BootcampEventModal/BootcampEventModal.module.css'
import '../../../calendar_components/BootcampEventModal/BootcampEventModal.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import ErrorTooltip from '../../../ErrorTooltip/ErrorTooltip';
import moment from 'moment'
import {newBootcampRequest} from '../../../../api/api'
import { scryRenderedComponentsWithType } from 'react-dom/test-utils'
import { getCodeList } from 'country-list'

const BLANK_ERRORS =  ['', '', '', '', '', '', '', '', '', '']

// Function to traverse through the array of errors, and passing the appropriate message into the errors state
const initializeFlags = (errors, setErrors) => {
    let newErrors = BLANK_ERRORS.slice();
    if(errors)
    {
      for(var i = 0; i < errors.length; i++)
      {
        if(errors[i].param === "studentName")
        {
          newErrors[0] = errors[i].msg;
        }
        else if(errors[i].param === "studentPhone")
        {
          if(newErrors[1].length === 0)
          {
            newErrors[1] = errors[i].msg
          }
        }
        else if(errors[i].param === "gradeLevel")
        {
          newErrors[2] = errors[i].msg
        }
        else if(errors[i].param === "school")
        {
          newErrors[3] = errors[i].msg
        }
        else if(errors[i].param === "parentName")
        {
          newErrors[4] = errors[i].msg
        }
        else if(errors[i].param === "parentEmail")
        {
          if(newErrors[5].length === 0)
          {
            newErrors[5] = errors[i].msg
          }
        }
        else if(errors[i].param === "parentPhone")
        {
          if(newErrors[6].length === 0)
          {
            newErrors[6] = errors[i].msg
          }
        }
        else if(errors[i].param === "mathClass")
        {
            newErrors[8] = errors[i].msg
        }
        else if(errors[i].param === "extendedTime")
        {
            newErrors[7] = errors[i].msg
        }
        else if(errors[i].param === "location")
        {
            newErrors[9] = errors[i].msg
        }
      }
    }
    setErrors(newErrors);
}
  
// Component
const AddStudentCourse = ({show, setShowCourse, bootcampModalInfo, refresh}, getList) => {
    
    const [errors, setErrors] = useState(BLANK_ERRORS); //list of errors
    const [studentSubmitted, setSubmit] = useState(false); //request status
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState('')

    useEffect(() => {
        if(show){
          document.documentElement.style.overflow = 'hidden';
        }
        else{
          document.documentElement.style.overflow = 'auto';
        }

        return () => {document.documentElement.style.overflow = 'auto';}
    }, [show]);

    // Reset errors when a request is submitted, or new courseModalInfo is opened
    useEffect(() => {
        setErrors(BLANK_ERRORS);
    }, [studentSubmitted, show]);

   
    // function to send a student request to the database
    const handleRegister = () => {
        setLoading(true);
        let name = document.getElementById('name').value;
        let phone = document.getElementById('phone').value;
        let parentEmail = document.getElementById('parentemail').value;
   
        let requestObject = {
            studentName: name, 
            studentPhone: phone,
            parentName: null, 
            parentEmail: parentEmail, 
            parentPhone: null, 
            codeUsed: 'Portal',
            location: location
        };

        newBootcampRequest(bootcampModalInfo._id, requestObject)
        .then(res => {
            console.log(res); 
            setLoading(false);
            setSubmit(true);
            refresh();
        })
        .catch(err => {
            console.log('error', err)
            if(err.response.data.status === 500){
                alert(err.response.data.message)
            }
            else {
                initializeFlags(err.response.data.data, setErrors)
            }
            setLoading(false);
        });
    }

    const handleClose = () => {
        setShowCourse(false);
        setTimeout(()=> {
            setSubmit(false);
            setLoading(false);
            setLocation('');
        }, 500);
    }

    const clearError = (index) => {
        let copy = errors.slice();
        copy[index] = '';
        setErrors(copy);
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='dialog'
        >
            <Modal.Header closeButton>
            <Modal.Title>Add Student to Course</Modal.Title>
            </Modal.Header>
            {studentSubmitted 
            ?
                <>
                <Modal.Body>
                    <p style={{fontWeight:'500'}}>Your request has been submitted!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
                </>
            
            :
                <>
                <Modal.Body>
                    <Form id='requestForm'>
                        <p style={{marginBottom: '10px', fontSize: '18px'}}>Please fill out the form below: </p>
                        <Form.Group>
                        <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                            {errors[0].length > 0 ? <ErrorTooltip id='name' errorMsg={errors[0]}/> : null}
                            <Form.Control  className = {``} id='name' placeholder="Student Name" required/>
                        </div>
                        </Form.Group>
                        <Form.Group>
                        <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
                            {errors[1].length > 0 ? <ErrorTooltip id='phone' errorMsg={errors[1]}/> : null}
                        <Form.Control className = {``} id='phone' placeholder="Student Phone Number" required/>
                        </div>
                        </Form.Group>
                        <Form.Group>
                        <div onFocus={()=>clearError(5)} style={{position: 'relative'}}>
                            {errors[5].length > 0 ? <ErrorTooltip id='parentemail' errorMsg={errors[5]}/> : null}
                            <Form.Control className = {``} id='parentemail' type='email' placeholder="Parent/Guardian Email" required/>
                        </div>
                        </Form.Group>
                        <Form.Group>
                            <div onFocus={()=>clearError(9)} style={{position: 'relative'}}>
                                {errors[9].length > 0 ? <ErrorTooltip id='extended' errorMsg={errors[9]}/> : null}
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>How will the Student be attending?: </p>
                                <div>
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='location'
                                        value={'Zoom'}
                                        label={`Zoom`}
                                        onChange={() => setLocation('Zoom')}
                                    />
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='location'
                                        value={'In Person'}
                                        label={`In Person`}
                                        onChange={() => setLocation('In Person')}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                
                <Modal.Footer>
                <Button variant="secondary" className={styles.cancel} onClick={handleClose}>
                    Cancel
                </Button>
                 
                    {loading ?
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            disabled
                            style={{minWidth: '83px'}}
                        >
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    :
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            type='submit' 
                            onClick={function () {handleRegister()}}
                        >
                            Register
                        </Button>
                    }
                </Modal.Footer>
                </>
            }
        </Modal>
    )
}
export default AddStudentCourse;