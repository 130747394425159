import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const TooltipWrapper = ({ children, text, placement }) => {
    return (
        <OverlayTrigger className="bcp-tooltip" placement={placement || 'top'} overlay={
            <Tooltip id="tooltip-disabled" className="bcp-tooltip">
                {text || 'Tooltip'}
            </Tooltip>
        }>
            <div>
                {children}
            </div>
        </OverlayTrigger>
    )
}

export default TooltipWrapper