import React,{useState, useEffect} from 'react'
import styles from './AddEventButton.module.css'
import "react-datepicker/dist/react-datepicker.css";
// import './NewCourseButton.css';
import DatePicker from "react-datepicker"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ErrorTooltip from '../../../ErrorTooltip/ErrorTooltip';
import { FaPlus } from 'react-icons/fa';
import moment from 'moment';
import { FaMinusCircle } from 'react-icons/fa';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { createCourse } from '../../../../api/api'
import { navigate } from "gatsby"

// Component
const CreateDiagnosticForm = ({setSubmitted}) => {

    const [errors, setErrors] = useState(['', '', '', '', '', '']); //list of errors
    const [entries, setEntries] = useState(
        [{
            type: '', 
            date: new Date(), 
            start: moment({ hour:15, minute:0 }), 
            end: moment({ hour:16, minute:0 })
        }]
    );
    const [courseType, setCourseType] = useState(null)


    useEffect(() => {
        console.log(entries);
    }, [entries]);

    useEffect(() => {
        setEntries([])
    }, [courseType]);

    // Function to create a new course in the database
    const handleCreate = () => {
        let name = document.getElementById('name').value;
        let description = document.getElementById('desc').value;
        let max;
        if(max = document.getElementById('max').value === '')
            max = undefined;
        else
            max = document.getElementById('max').value
        let price;
        if(document.getElementById('price').value === ''){
            price = -1;
        }
        else{
            let str = document.getElementById('price').value;
            price = parseFloat(str.replace(/\$/g, ""));
        }

        let meetings = [];
        let sessions = [];

        entries.slice().map((item, i) => {
            if(item.type !== 'study'){
                let start= new Date(
                    item.date.getFullYear(),
                    item.date.getMonth(),
                    item.date.getDate(),
                    item.start.hour(),
                    item.start.minute(),
                    0, 0
                );
                let end= new Date(
                    item.date.getFullYear(),
                    item.date.getMonth(),
                    item.date.getDate(),
                    item.end.hour(),
                    item.end.minute(),
                    0, 0
                );
                meetings[meetings.length] = {
                    entryType: item.type,
                    start: start,
                    end: end
                };
            }
            else{
                let start = new Date(2020, 0, 1, item.start.hour(), item.start.minute());
                let end = new Date(2020, 0, 1, item.end.hour(), item.end.minute());

                sessions[sessions.length] = {
                    entryType: item.type,
                    day: item.day,
                    start: start,
                    end: end
                };
            }
        });

        let courseObject = {
            courseType: courseType,
            name: name, 
            description: description, 
            price: price,
            meetings: meetings,
            studySessions: sessions,
            enrollmentMax: max
        };
        
        isValid(name)
        .then(res => {
            if(res){
                createCourse(courseObject)
                .then(res => {
                    console.log(res);
                    setSubmitted(true);
                })
                .catch(function (err) {
                    console.log(err.response);
                    if(err.response.data.status === 500){
                        alert(err.response)
                    }
                    else if(err.response.status === 401) {
                        alert('Sorry! Login expired. Sign back in to refresh.');
                        navigate("/portal/signin");
                    }
                    else {
                        initializeFlags(err.response.data.data)
                    }
                })
            }
            else{
                let newErrors = errors.slice();
                newErrors[0] = "No special characters allowed in Name";
                setErrors(newErrors);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // Function to traverse through the array of errors, and passing the appropriate message into the errors state
    const initializeFlags = (errors) => {
        let newErrors = ['', '', '', '', '', ''];
        if(errors)
        {
        for(var i = 0; i < errors.length; i++)
        {
            if(errors[i].param === "name")
            {
                newErrors[0] = errors[i].msg;
            }
            else if(errors[i].param === "description")
            {
                newErrors[1] = errors[i].msg;
            }
            else if(errors[i].param === "price")
            {
                newErrors[2] = errors[i].msg;
            }
            else if(errors[i].param.includes("meeting"))
            {
                newErrors[3] = errors[i].msg;
            }
            else if(errors[i].param.includes("studySessions") && errors[i].param.includes("day"))
            {
                newErrors[4] = errors[i].msg;
            }
            else if(errors[i].param.includes("courseType") && errors[i].msg.includes("course type"))
            {
                newErrors[5] = errors[i].msg;
            }
            
        }
        }
        setErrors(newErrors);
    }

    const isValid = async (str) => {
        return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(str);
    }

    const handleDateChange = (date, i) => {
        let list = entries.slice();
        list[i].date = date
        setEntries(list);
    };

    const handleDay = (e, i) => {
        let list = entries.slice();
        list[i].day = e.target.value
        setEntries(list);
    };

    const handleType = (e, i) => {
        let list = entries.slice();
        if(e.target.value === 'study'){
            list[i] = {
                type: 'study', 
                day: '', 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            }
        }
        else{
            list[i] = {
                type: e.target.value, 
                date: new Date(), 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            }
        }
        setEntries(list);
    };

    // handles changes in start time
    const handleStart = (time, i) => {
        let list = entries.slice();
        list[i].start = time;
        setEntries(list);
    }

    // handles changes in end time
    const handleEnd = (time, i) => {
        let list = entries.slice();
        list[i].end = time;
        setEntries(list);
    }

    const handleDelete = (i) => {
        let list = entries.slice();
        list.splice(i, 1);
        setEntries(list);
    }

    const addEntry = () => {
        let entryList = entries.slice();
        let newDate = new Date();
        if(entries.length > 0){
            newDate = new Date(entries[entries.length-1].date);
            newDate.setDate(newDate.getDate() + 1);
        }

        if(courseType === 'psatbootcamp') {
            entryList[entryList.length] = {
                type: 'psatbootcamp', 
                date: newDate, 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            };
        }
        else if(courseType === 'actbootcamp') {
            entryList[entryList.length] = {
                type: 'actbootcamp', 
                date: newDate, 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            };
        }
        else if(courseType === 'satbootcamp') {
            entryList[entryList.length] = {
                type: 'satbootcamp', 
                date: newDate, 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            };
        } 
        else if(courseType === 'generalcourse') {
            entryList[entryList.length] = {
                type: 'generalcourse', 
                date: newDate, 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            };
        }
        else {
            entryList[entryList.length] = {
                type: '', 
                date: newDate, 
                start: moment({ hour:15, minute:0 }), 
                end: moment({ hour:16, minute:0 })
            };
        }
        setEntries(entryList);
    }

    // clear ONE error tooltip
    const clearError = (index) => {
        let copy = errors.slice();
        copy[index] = '';
        setErrors(copy);
    }
    
    return (
        <>
        <Form id='create-course'>
            <div onFocus={()=>clearError(5)} style={{position: 'relative', zIndex: '10'}}>
                {errors[5].length > 0 ? <ErrorTooltip id='desc' errorMsg={errors[5]}/> : null}
                <p style={{marginBottom: '5px'}}>Select Course Type:</p>
                <Form.Check name='bootcamps' type='radio' label='SAT Bootcamp' style={{marginLeft: '5px'}} onChange={() => setCourseType('satbootcamp')}></Form.Check>
                <Form.Check name='bootcamps' type='radio' label='ACT Bootcamp' style={{marginLeft: '5px'}} onChange={() => setCourseType('actbootcamp')}></Form.Check>
                <Form.Check name='bootcamps' type='radio' label='PSAT Bootcamp' style={{marginLeft: '5px'}} onChange={() => setCourseType('psatbootcamp')}></Form.Check>
                <Form.Check name='bootcamps' type='radio' label='General Course' style={{marginLeft: '5px', marginBottom: '25px'}} onChange={() => setCourseType('generalcourse')}></Form.Check>
            </div>
            <Form.Group>
            <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                {errors[0].length > 0 ? <ErrorTooltip id='name' errorMsg={errors[0]}/> : null}
                <Form.Control className = {``} id='name' placeholder="Name" required/>
            </div>
            </Form.Group>
            <Form.Group>
            <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
                {errors[1].length > 0 ? <ErrorTooltip id='desc' errorMsg={errors[1]}/> : null}
                <Form.Control className = {``} id='desc' placeholder="Description" required/>
            </div>
            </Form.Group>
            <Form.Group>
            <div onFocus={()=>clearError(2)} style={{position: 'relative'}}>
                {errors[2].length > 0 ? <ErrorTooltip id='price' errorMsg={errors[2]}/> : null}
                <Form.Control className = {``} id='price' placeholder="Price" required/>
            </div>
            </Form.Group>
            <Form.Group>
                <Form.Control className = {``} id='max' placeholder="Max Enrolled Students (default 20)"/>
            </Form.Group>
            {courseType? 
            <>
                <div onFocus={()=>clearError(3)} style={{position: 'relative'}}>
                    {errors[3].length > 0 ? <ErrorTooltip id='meetings' errorMsg={errors[3]}/> : null}
                    {errors[4].length > 0 ? <ErrorTooltip id='study' errorMsg={errors[4]}/> : null}
                    <p style={{color: "grey", marginBottom: '5px'}}>Dates:</p>
                    {entries.map((item, i) => { return (
                        <div key = {"formEntry" + i}>
                        {item.type==='study' ? 
                            <>
                            <Form.Group className='d-flex'>
                                <p style={{marginBottom: 'auto', marginTop: 'auto', width: '15%'}}>Entry {i+1}: </p>
                                <Form.Control 
                                    style={{marginRight: '5px', width: '37%'}} 
                                    onChange={(e) => {handleType(e, i)}} 
                                    value={item.type} 
                                    id={`day${i+1}type`} 
                                    as="select" 
                                    required
                                >
                                    {courseType === 'psatbootcamp' ?
                                        <option value='psatbootcamp'>PSAT Bootcamp Class</option>
                                    :
                                        (courseType === 'actbootcamp'?
                                            <option value='actbootcamp'>ACT Bootcamp Class</option>
                                        :
                                            (courseType === 'satbootcamp'?
                                                <option value='satbootcamp'>SAT Bootcamp Class</option>
                                            :
                                                (courseType === 'generalcourse'?
                                                    <option value='generalcourse'>General Course</option>
                                                :
                                                <>
                                                <option value='' disabled>Type of Day</option>
                                                <option value='class'>Class</option>
                                                <option value='diagnostic'>Diagnostic</option>
                                                <option value='study'>Study Sessions</option>
                                                </>
                                                )
                                            )
                                        ) 
                                    }
                                </Form.Control>
                                <Form.Control 
                                    style={{width: '35%', marginRight: '5px'}} 
                                    onChange={(e) => {handleDay(e, i)}} 
                                    value={item.day} 
                                    id={`day${i+1}day`} 
                                    as="select" 
                                    required
                                >
                                    <option value='' disabled>Day</option>
                                    <option value='Mondays'>Mondays</option>
                                    <option value='Tuesdays'>Tuesdays</option>
                                    <option value='Wednesdays'>Wednesdays</option>
                                    <option value='Thursdays'>Thursdays</option>
                                    <option value='Fridays'>Fridays</option>
                                    <option value='Saturdays'>Saturdays</option>
                                    <option value='Sundays'>Sundays</option>
                                </Form.Control>
                                <TimePicker 
                                    className='timePicker'
                                    use12Hours 
                                    showSecond = {false} 
                                    format = 'h:mm a' 
                                    placeholder = 'Start' 
                                    value = {item.start} 
                                    minuteStep = {15} 
                                    onChange = {(time)=>handleStart(time, i)}
                                />
                                <p style={{margin: 'auto 4px auto 4px', fontWeight: '600'}}> - </p>
                                <TimePicker
                                    className='timePicker'
                                    use12Hours 
                                    showSecond = {false} 
                                    format = 'h:mm a' 
                                    placeholder = 'End' 
                                    value = {item.end} 
                                    minuteStep = {15} 
                                    onChange = {(time)=>handleEnd(time, i)}
                                />
                                <a className='d-flex align-items-center' onClick={()=>{handleDelete(i)}}>
                                    <FaMinusCircle className={styles.delete}/>
                                </a>
                            </Form.Group>
                            </>
                        : 
                            <>
                            <Form.Group className='d-flex'>
                                <p style={{marginBottom: 'auto', marginTop: 'auto', width: '15%'}}>Entry {i+1}: </p>
                                <Form.Control 
                                    style={{marginRight: '5px', width: '50%'}} 
                                    onChange={(e) => {handleType(e, i)}} 
                                    value={item.type} 
                                    id={`day${i+1}type`} 
                                    as="select" 
                                    required
                                >
                                    {courseType === 'psatbootcamp' ?
                                        <option value='psatbootcamp'>PSAT Bootcamp Class</option>
                                    :
                                        (courseType === 'actbootcamp'?
                                            <option value='actbootcamp'>ACT Bootcamp Class</option>
                                        :
                                            (courseType === 'satbootcamp'?
                                                <option value='satbootcamp'>SAT Bootcamp Class</option>
                                            :
                                                <>
                                                <option value='' disabled>Type of Day</option>
                                                <option value='class'>Class</option>
                                                <option value='diagnostic'>Diagnostic</option>
                                                <option value='study'>Study Sessions</option>
                                                </>
                                            )
                                        )
                                    }
                                </Form.Control>
                                <DatePicker
                                    className={styles.dayPicker}
                                    selected={item.date}
                                    onChange={(date) => {handleDateChange(date, i)}}
                                    dateFormat="MM/dd/yyyy"
                                />
                                <TimePicker
                                    className='timePicker'
                                    use12Hours 
                                    showSecond = {false} 
                                    format = 'h:mm a' 
                                    placeholder = 'Start' 
                                    value = {item.start} 
                                    minuteStep = {15} 
                                    onChange = {(time)=>handleStart(time, i)}
                                />
                                <p style={{margin: 'auto 4px auto 4px', fontWeight: '600'}}> - </p>
                                <TimePicker 
                                    className='timePicker'
                                    use12Hours 
                                    showSecond = {false} 
                                    format = 'h:mm a' 
                                    placeholder = 'End' 
                                    value = {item.end} 
                                    minuteStep = {15} 
                                    onChange = {(time)=>handleEnd(time, i)}
                                />
                                <a className={`${styles.delete} d-flex align-items-center`} onClick={()=>{handleDelete(i)}}>
                                    <FaMinusCircle />
                                    </a>
                            </Form.Group>
                            </>
                        }
                        </div>
                    )})}
                </div>
                <div style={{marginBottom: '20px'}}>
                <a onClick={addEntry} className={styles.addEntry}><FaPlus style={{marginBottom: '4px'}}/> New Entry</a>
                </div>
            </>
            :
                null     
            }
        </Form>
        <Button 
            variant="primary" 
            className = {`${styles.newButton} ${styles.submitBtn}`}
            type='submit' 
            onClick={function () {handleCreate()}}
        >
            Create
        </Button>
        </>
    )
}

export default CreateDiagnosticForm;
