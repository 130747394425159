import React, {useState, useEffect} from 'react';
import { navigate } from "gatsby"
import styles from './ForgotPassword.module.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BeckLogoShadow3D from "../../../images/beck-logo-3d-shadow.png";
import { FaArrowLeft } from 'react-icons/fa';
import { forgotPassword } from '../../../api/api';
import ErrorTooltip from '../../ErrorTooltip/ErrorTooltip';


const ForgotPassword = () => {

    const [errors, setErrors] = useState({
        email: ""
    }); //list of errors

    const [email, setEmail] = useState("");

    // Function to traverse through the array of errors, and passing the appropriate message into the errors state
    const initializeFlags = (newErrors) => {
        let flags = { ...errors };
        for(const key in newErrors)
        {
            flags[key] = newErrors[key];
        }

        setErrors(flags);
    }

    const formatErrorResponse = (data) => {
        let obj = {};
        data.forEach((item) => {
            obj[item.param] = item.msg;
        })
        return obj;
    }

    const clearError = (field) => {
        let obj = {...errors};
        obj[field] = '';
        setErrors(obj);
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        let data = {
          email: email
        };
        
        forgotPassword(data)
            .then(res => { 
                alert("An email was sent to your account to reset your password.")
                navigate("/portal/signin")
            })
            .catch((err) => {
                const errs = formatErrorResponse(err.response.data.data);
                initializeFlags(errs);
             
            });
    }

    return (
        <div className={`${styles.page} d-flex justify-content-center align-items-center`}>
            <img alt="Beck College Prep Logo" className={`${styles.logo}`} src={BeckLogoShadow3D}></img>
            <div className={`${styles.contact}`}>
                <h1 className={`${styles.heading}`}>Forgot Password?</h1>
                <p className="m-3">No worries! We'll send you an email with a link to reset your password.</p>
                <div className = 'm-3'>
                    <Form id='forgotpassword' className="" onSubmit={handleSubmit} noValidate>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                <div onFocus={()=> clearError("email")} style={{position: 'relative'}}>
                                    {errors?.email && errors.email !== "" ? <ErrorTooltip errorMsg={errors.email}/> : null}   
                                    <Form.Control 
                                        className={`${styles.control} ${styles.box}`}
                                        id='email'
                                        type="email"
                                        placeholder="Email"
                                        required
                                        onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Row>
                            <Col>
                                <Button type='submit' id='resetPasswordBtn' className={`${styles.bcpButton}`}>
                                    Send Recovery Email
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div className="mt-3">
                        <a className={styles.backLink} href="/portal/signin"><FaArrowLeft width={18} height={18}/>&emsp;Back to Sign In</a>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default ForgotPassword;