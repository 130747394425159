import React from 'react'
import ReactPaginate from 'react-paginate';
import Form from 'react-bootstrap/Form'
import styles from './Pagination.module.css'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

const LIMIT_OPTIONS = [20, 40, 60, 80, 100]

const CustomPagination = ({ active, setActive, limit, setLimit, total }) => {
    return (
        <div className='d-flex align-items-center justify-content-around w-100' style={{ margin: '12px 22px' }}>
            <Form className='d-flex align-items-center' style={{ width: '20%' }}>
                <Form.Label className={styles.label}>Entries Per Page: </Form.Label>
                <Form.Control
                    as="select" 
                    required
                    value={limit}
                    onChange={(event) => setLimit(event.target.value)}
                    className={`${styles.dropDown} dropDownArrow`}
                >
                    {LIMIT_OPTIONS.map((limit, i) => {
                        if (i == 0 || limit <= total) {
                            return (
                                <option key={'limitOption' + i} value={limit}>{limit}</option>
                            )
                        }
                    })}
                </Form.Control>
            </Form>
            <div className={`d-flex justify-content-center`} style={{ width: '60%' }}>
                <ReactPaginate
                    initialPage={0}
                    forcePage={active}
                    pageCount={Math.ceil(total / limit)}
                    onPageChange={(page) => setActive(page.selected)}
                    previousLabel={<FaAngleLeft/>}
                    nextLabel={<FaAngleRight/>}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    containerClassName={styles.container}
                    pageClassName={styles.generalBox}
                    pageLinkClassName={styles.generalLink}
                    breakClassName={styles.generalBox}
                    breakLinkClassName={styles.generalLink}
                    activeClassName={styles.activeBox}
                    nextClassName={styles.generalBox + " " + styles.nextBox}
                    nextLinkClassName={styles.generalLink}
                    previousClassName={styles.generalBox + " " + styles.previousBox}
                    previousLinkClassName={styles.generalLink}
                />
            </div>
            <div style={{ width: '20%' }}/>
        </div>
    )
}

export default CustomPagination