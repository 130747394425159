import { saveAs } from 'file-saver';
import { getCourses, getDiagnostics } from '../api/api'

/*
    Valid download types:
    - singleDiagnostic
    - allDiagnostic
    - singleCourse
    - allCourse

    If items parameter is not provided for allDiagnostic/allCourse option, the data will be fetched from database
*/
export const handleDownload = function(items, type, approvedOnly) {
    if (type == 'singleDiagnostic') {
        saveAs(new File([singleDiagnosticToCSV(items)], "diagnostic.csv", {type: "text/csv"}));
    } else if (type == 'allDiagnostic') {
        if (items == null) { // If items parameter was not provided, fetch data from database
            getDiagnostics()
                .then(res => {
                    let data = null

                    if (approvedOnly) {
                        data = allDiagnosticsToCSV(res.data, true)
                    } else {
                        data = allDiagnosticsToCSV(res.data)
                    }

                    saveAs(new File([data], "allDiagnostics.csv", {type: "text/csv"}))
                })
                .catch(err => {
                    console.log('Download failed with error: ', err)
                    return
                })
        } else {
            let data = null

            if (approvedOnly) {
                data = allDiagnosticsToCSV(items, true)
            } else {
                data = allDiagnosticsToCSV(items)
            }

            saveAs(new File([data], "allDiagnostics.csv", {type: "text/csv"}));
        }
    } else if (type == 'singleCourse') {
        saveAs(new File([singleCourseToCSV(items)], "course.csv", {type: "text/csv"}));
    } else if (type == 'allCourse') {
        if (items == null) { // If items parameter was not provided, fetch data from database
            getCourses()
                .then(res => saveAs(new File([allCoursesToCSV(res.data)], "allCourses.csv", {type: "text/csv"})))
                .catch(err => {
                    console.log('Download failed with error: ', err)
                    return
                })
        } else {
            saveAs(new File([allCoursesToCSV(items)], "allCourses.csv", {type: "text/csv"}));
        }
    } else {
        console.log('CSV Downloader: A type was not provided. Refer to this function\'s declaration for details.')
        return
    }
}


const removeCommasFromFields = (object) => {
    let objectCopy = { ...object }

    for (const property in objectCopy) {
        if (typeof objectCopy[property] == 'string') {
            objectCopy[property] = objectCopy[property].replace(/,/g, '')
        }
    }

    return objectCopy
}


// Converts an array into a string formatted as a csv
const arrayToCSV = (csvArray) => {
    let csvString = ''

    // Convert 2D array to csv
    for (let i = 0; i < csvArray.length; i++) {
        for (let j = 0; j < csvArray[i].length; j++) {
            csvString = csvString + csvArray[i][j] + ','
        }
        csvString = csvString + '\r\n'
    }

    return csvString;
}


function singleDiagnosticToCSV(items) {
    // Build date string
    const date = (new Date(items.date.start).getMonth() + 1).toString() + '/' + (new Date(items.date.start).getDate()).toString() + '/' + (new Date(items.date.start).getFullYear()).toString() + ' from ' + 
        new Date(items.date.start).toLocaleString(undefined, {
            timeZone: 'America/New_York',
            hour: '2-digit',
            minute: '2-digit',
        }) + ' to ' + 
        new Date(items.date.end).toLocaleString(undefined, {
            timeZone: 'America/New_York',
            hour: '2-digit',
            minute: '2-digit',
        })

    // Setup up first two rows of csv
    let csv = [
        ['Name', 'Description', 'Date'],
        [items.name, items.description, date],
        [],
        ['Students'],
        [
            'Test Type', 
            'Student Name', 
            'Grade Level', 
            'Extended Time', 
            'SAT Form', 
            'SR', 
            'SG', 
            'SM', 
            'ST', 
            'ACT Form', 
            'AG', 
            'AM', 
            'AR', 
            'AS', 
            'AT', 
            'Previous Scores', 
            'School', 
            'Math Class', 
            'Student Phone', 
            'Student Email', 
            'Parent Name', 
            'Parent Phone', 
            'Parent Email', 
            'Parent Name 2', 
            'Parent Phone 2', 
            'Parent Email 2', 
            'Address'
        ]
    ]

    let students = items.studentRequests
    for (let i = 0; i < students.length; i++) {
        // Only add not hidden students to csv
        if (students[i].hidden == false) {
            let student = removeCommasFromFields(students[i])

            csv.push([
                student.testType || '-',
                student.studentName || '-',
                student.gradeLevel || '-',
                student.extendedTime == true ? 'true' : 'false',
                student.satForm || '-',
                student.sr || '-',
                student.sg || '-',
                student.sm || '-',
                student.st || '-',
                student.actForm || '-',
                student.ag || '-',
                student.am || '-',
                student.ar || '-',
                student.as || '-',
                student.at || '-',
                student.prevScores || '-', 
                student.school || '-', 
                student.mathClass || '-',
                student.studentPhone || '-', 
                student.studentEmail || '-',
                student.parentName || '-',
                student.parentPhone || '-',
                student.parentEmail || '-',
                student.parentName2 || '-',
                student.parentPhone2 || '-', 
                student.parentEmail2 || '-',
                student.address || '-'
            ])
        }
    }

    return arrayToCSV(csv)
}


function singleCourseToCSV(items) {
    // Setup up general headings and data, setup meeting dates headings
    let csv = [
        ['Name', 'Description', 'Price'],
        [items.name, items.description, items.price],
        [],
        ['Meeting Dates'],
        ['Type', 'Date', 'Start Time', 'End Time']
    ]

    let meetings = items.meetings
    for (let i = 0; i < meetings.length; i++) {
        // Build time strings
        const date = (new Date(meetings[i].start).getMonth() + 1).toString() + '/' + (new Date(meetings[i].start).getDate()).toString() + '/' + (new Date(meetings[i].start).getFullYear()).toString()
        const startTime = new Date(meetings[i].start).toLocaleString(undefined, { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit' })
        const endTime = new Date(meetings[i].end).toLocaleString(undefined, { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit' })

        csv.push([
            meetings[i].entryType || '-',
            date || '-',
            startTime || '-',
            endTime || '-',
        ])
    }

    // Set up enrolled student headings
    csv.push(
        [], 
        ['Enrolled Students'],
        [
            'Student Name',
            'Enrollment Status',
            'Grade Level', 
            'School', 
            'Student Phone', 
            'Student Email', 
            'Parent Name', 
            'Parent Phone',
            'Parent Email',  
            'Parent Name 2', 
            'Parent Phone 2', 
            'Parent Email 2', 
            'Location', 
            'Notes'
        ]
    )

    let students = items.enrolledStudents
    for (let i = 0; i < students.length; i++) {
        let student = removeCommasFromFields(students[i])

        csv.push([
            student.studentName || '-',
            'Enrolled',
            student.gradeLevel || '-', 
            student.school || '-', 
            student.studentPhone || '-',
            student.studentEmail || '-',
            student.parentName || '-',
            student.parentPhone || '-',
            student.parentEmail || '-', 
            student.parentName2 || '-',
            student.parentPhone2 || '-',
            student.parentEmail2 || '-', 
            student.location || '-',
            student.notes || '-'
        ])
    }

    students = items.requests
    for (let i = 0; i < students.length; i++) {
        let student = removeCommasFromFields(students[i])

        csv.push([
            student.studentName || '-',
            'Requested',
            student.gradeLevel || '-', 
            student.school || '-', 
            student.studentPhone || '-',
            student.studentEmail || '-',
            student.parentName || '-',
            student.parentPhone || '-',
            student.parentEmail || '-', 
            student.parentName2 || '-',
            student.parentPhone2 || '-',
            student.parentEmail2 || '-', 
            student.location || '-',
            student.notes || '-'
        ])
    }

    return arrayToCSV(csv)
}


/*
    Items is an array of diagnostics
*/
function allDiagnosticsToCSV(items, approvedOnly) {
    // Setup up first two rows of csv
    let csv = [
        [
            'Diagnostic Name', 
            'Diagnostic Date', 
            'Test Type', 
            'Student Name', 
            'Grade Level', 
            'Extended Time', 
            'SAT Form', 
            'SR', 
            'SG', 
            'SM', 
            'ST', 
            'ACT Form', 
            'AG', 
            'AM', 
            'AR', 
            'AS', 
            'AT', 
            'Previous Scores', 
            'School', 
            'Math Class', 
            'Student Phone', 
            'Student Email', 
            'Parent Name', 
            'Parent Phone', 
            'Parent Email', 
            'Parent Name 2', 
            'Parent Phone 2', 
            'Parent Email 2', 
            'Address'
        ]
    ]

    for (let j = 0; j < items.length; j++) {
        let students = items[j].studentRequests

        // Build date string
        const date = (new Date(items[j].date.start).toLocaleDateString('en-US', {timeZone: 'America/New_York'}) + ' from ' + 
            new Date(items[j].date.start).toLocaleString(undefined, {
                timeZone: 'America/New_York',
                hour: '2-digit',
                minute: '2-digit',
            }) + ' to ' + 
            new Date(items[j].date.end).toLocaleString(undefined, {
                timeZone: 'America/New_York',
                hour: '2-digit',
                minute: '2-digit',
            })
        )

        for (let i = 0; i < students.length; i++) {
            let student = removeCommasFromFields(students[i])

            // Only add not hidden students to csv
            if (students[i].hidden == false ) {
                const temp = [
                    items[j].name || '-',
                    date || '-',
                    student.testType || '-',
                    student.studentName || '-',
                    student.gradeLevel || '-',
                    student.extendedTime == true ? 'true' : 'false',
                    student.satForm || '-',
                    student.sr || '-',
                    student.sg || '-',
                    student.sm || '-',
                    student.st || '-',
                    student.actForm || '-',
                    student.ag || '-',
                    student.am || '-',
                    student.ar || '-',
                    student.as || '-',
                    student.at || '-',
                    student.prevScores || '-', 
                    student.school || '-', 
                    student.mathClass || '-',
                    student.studentPhone || '-', 
                    student.studentEmail || '-',
                    student.parentName || '-',
                    student.parentPhone || '-',
                    student.parentEmail || '-',
                    student.parentName2 || '-',
                    student.parentPhone2 || '-', 
                    student.parentEmail2 || '-',
                    student.address || '-'
                ]

                if (approvedOnly) {
                    if (students[i].approved == true) {
                        csv.push(temp)
                    }
                } else {
                    csv.push(temp)
                }
            }
        }
    }

    return arrayToCSV(csv)
}


/*
    Items is an array of courses
*/
function allCoursesToCSV(items) {
    // Setup up first two rows of csv
    let csv = [
        [
            'Course Name',
            'Course Type',
            'Course Start Date',
            'Student Name',
            'Enrollment Status',
            'Grade Level', 
            'School', 
            'Student Phone', 
            'Student Email', 
            'Parent Name', 
            'Parent Phone',
            'Parent Email',  
            'Parent Name 2', 
            'Parent Phone 2', 
            'Parent Email 2', 
            'Location', 
            'Notes'
        ]
    ]

    for (let j = 0; j < items.length; j++) {
        // Build date string
        const date = new Date(items[j].meetings[0].start).toLocaleDateString('en-US', {timeZone: 'America/New_York'})

        let students = items[j].enrolledStudents

        for (let i = 0; i < students.length; i++) {
            let student = removeCommasFromFields(students[i])

            csv.push([
                items[j].name || '-',
                items[j].courseType || '-',
                date || '-',
                student.studentName || '-',
                'Enrolled',
                student.gradeLevel || '-', 
                student.school || '-', 
                student.studentPhone || '-',
                student.studentEmail || '-',
                student.parentName || '-',
                student.parentPhone || '-',
                student.parentEmail || '-', 
                student.parentName2 || '-',
                student.parentPhone2 || '-',
                student.parentEmail2 || '-', 
                student.location || '-',
                student.notes || '-'
            ])
        }

        students = items[j].requests

        for (let i = 0; i < students.length; i++) {
            let student = removeCommasFromFields(students[i])

            if (students[i]?.hidden == false) {
                csv.push([
                    items[j].name || '-',
                    items[j].courseType || '-',
                    date || '-',
                    student.studentName || '-',
                    'Requested',
                    student.gradeLevel || '-', 
                    student.school || '-', 
                    student.studentPhone || '-',
                    student.studentEmail || '-',
                    student.parentName || '-',
                    student.parentPhone || '-',
                    student.parentEmail || '-', 
                    student.parentName2 || '-',
                    student.parentPhone2 || '-',
                    student.parentEmail2 || '-', 
                    student.location || '-',
                    student.notes || '-'
                ])
            }
        }
    }

    return arrayToCSV(csv)
}