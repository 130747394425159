import React, { useEffect, useState } from 'react'
import styles from "./Home.module.css"
import { navigate } from "gatsby"
import PortalCard from '../PortalCard/PortalCard'
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import pencils from '../../../images/pencil2.png'
import globe from '../../../images/bigglobe.png'
import books from '../../../images/books.png'
import enrolled from '../../../images/beck-3d-logo-png.png'
import { deleteAuth, connectToQuickbooks, getQBToken } from '../../../api/api'
import { MdAccountCircle, MdSettings } from 'react-icons/md';
import portalStyles from '../Portal.module.css';

import Settings from '../Settings/Settings';

const handleSignOut = () => {
    deleteAuth()
    navigate('/portal/signin');
}

const authorizeQBO = () => {
    
    connectToQuickbooks().then(res => {
        console.log(res);
        let win = window.open(res, 'oauth', 'width=972,height=660,modal=yes,alwaysRaised=yes');

        let checkConnect = setInterval(function() {
            if (!win || !win.closed) return;
            clearInterval(checkConnect);
            window.location.reload();
        }, 100);
    })
    .catch(err => {
        console.log(err);
    })
}

const Home = ({ user }) => {
    const [validQBOToken, setValidQBOToken] = useState(false);

    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        getQBToken().then(res => {
            if(res)
                setValidQBOToken(true);
        })
        .catch(err => {
            setValidQBOToken(false);
        })
    }, [])

    // function checkRole(role) {
    //     if(role === 'ADMIN') {
    //         return ['/portal/events','/portal/students'];
    //     } else if(role === 'TUTOR') {
    //         return ['/portal/tutorevents','/portal/register']
    //     }
    // }

    return(
        <div className={`${styles.page} d-flex justify-content-center align-items-center`}>
            
            {
                !validQBOToken ? 
                <button type="button" className={styles.quickbooksButton} onClick={() => authorizeQBO()}>Connect to Quickbooks</button>
                : <div disabled className={styles.quickbooksButton}>QBO connected!</div>
            }
            
            <div className={`${styles.cornerNav} ${portalStyles.buttonOptionsContainer}`}>
                    <div onClick={() => setShowSettings(true)}>
                        <MdSettings style={{ color: "var(--bcp-dark-gray)"}} size={32} />
                    </div>
                    <Dropdown >
                        <Dropdown.Toggle as={CustomDropdown}></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header style={{cursor: 'default'}}>{user?.email}</Dropdown.Header>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => {handleSignOut()}}>Sign Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
            </div>

            <PortalCard color='#FFFFFF' heading='Events' pic={books} onRouteChange= {'/portal/events'}></PortalCard>
            <PortalCard color='#FFFFFF' heading='Diagnostic Score History' pic={pencils} onRouteChange= {'/portal/students'}></PortalCard>
            {(user.role == 'ADMIN')? (
                <>
                    <PortalCard color='#FFFFFF' heading='Consultation Reports' pic={enrolled} onRouteChange= {'/portal/send-recap'}></PortalCard>
                </>
            ):( null)}

            <Settings show={showSettings} setShow={setShowSettings} user={user}></Settings>
        </div>
    )
}

export default Home;


const CustomDropdown = React.forwardRef(({onClick}, ref) => (
    <div
        href=''
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e)
        }}
    >
        <MdAccountCircle style={{ color: "var(--bcp-dark-gray)"}} size={32} />
    </div>
));
