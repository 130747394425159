import React, {useState, useEffect} from 'react'
import 'react-resizable/css/styles.css';
import styles from './Students.module.css';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { Link } from 'gatsby'
import { FaSortUp, FaSortDown, FaCloudDownloadAlt, FaCog, FaArrowLeft } from 'react-icons/fa';
import { HiOutlineRefresh } from 'react-icons/hi'
import { BiExpand, BiCollapse } from 'react-icons/bi'
import { 
    createReportEntry,
    deleteReportEntry,
    editReportEntry,
    generateDiagnosticReport,
    editDiagnosticRequest,
} from '../../../api/api'
import { handleDownload } from '../../../utility/downloadCSV'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl';
import Filter from '../Events/Filter'
import { sanitizeFieldHeader } from '../../../utility/general'
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper'
import Pagination from '../Pagination/Pagination';

const MOBILE_SIZE = 1200; // Width in which screen is too small to display portal
const DIAG_SEARCH_FIELDS = ['studentName', 'testType', 'school', 'parentName', 'parentEmail'] // For filter
const DIAG_SCORE_HEADINGS = ['sr' ,'sg', 'sm','st','ag','am', 'ar','as','at']


// Component that deals with manipulating and viewing student data from a single event
const Students = ({allEvents, user}) => {

    const [listType, setListType] = useState('approved') // Enumerations: approved, hidden
    const [studentList, setList] = useState(null); // List of student object requests
    const [eventInfo, setEventInfo] = useState(null); // Info related to the chosen event
    const [sortFlag, setSortFlag] = useState([{[`sortField`]: 'studentName', [`sortOrder`]: 'asc'}]); // Array containing how the students are sorted
    const [showExpandedInfo, setShowExpandedInfo] = useState(false) // Expand/collapse info
    const [editingIndex, setEditingIndex] = useState(-1); // Index of the student entry being edited
    const [filterField, setFilterField] = useState('') // Which heading the search bar is using to search
    const [filterValue, setFilterValue] = useState('') // The search value
    const [filtersChanged, setFiltersChanged] = useState(false) // The search value
    const [mobileDisplay, setMobileDisplay] = useState(false); // stores whether screen is too small or not
    const [totalEntries, setTotalEntries] = useState(0)
    const [pageLimit, setPageLimit] = useState(20)
    const [currentPage, setCurrentPage] = useState(0) // Should default to 1 in order to match initial page of pagination component
    const [delayedPage, setDelayedPage] = useState(0)

    // OnComponentMount
    useEffect(() => {
        getEventInfo();

        window.addEventListener('resize', setDisplayModes, { passive: true })

        return function cleanup() {
            window.removeEventListener('resize', setDisplayModes, { passive: true })
        }
    }, [])

    // Refresh event info when event changes, sorting is changed, or expanding info
    useEffect(() => {
        getEventInfo();
    }, [sortFlag])

    useEffect(() => {
        setEditingIndex(-1)
        filterStudentList()
    }, [showExpandedInfo])
    
    useEffect(() => {
        setEditingIndex(-1)
        if(eventInfo){
            filterStudentList()
        }

    }, [eventInfo])

    // Used to refresh list only after filter fields have been cleared
    useEffect(() => {
        if (filterValue == '' || filterField == '' && filtersChanged) {
            getEventInfo()
        } else {
            setFiltersChanged(true)
        }
    }, [filterField, filterValue])

    // Reset currrent page back the first whenever the pagination limits are updated. If current page is already 0 then refresh data
    useEffect(() => {
        if (currentPage == 0) {
            getEventInfo()
        } else {
            setCurrentPage(0)
        }
    }, [pageLimit, listType])

    useEffect(() => {
        if(editingIndex != -1)
            document.addEventListener('keydown', enterToSubmitEdit, { passive: true })

        return function cleanup() {
            document.removeEventListener('keydown', enterToSubmitEdit, { passive: true })
        }
    }, [editingIndex])

    // Refresh list when the current page changes
    useEffect(() => {
        getEventInfo()
    }, [currentPage])

    // Check the window width vs the mobile size limit
    function setDisplayModes(event) {
        if (window.innerWidth <= MOBILE_SIZE) {
            setMobileDisplay(true)
        } else {
            setMobileDisplay(false)
        }
    }

    // Function to submit edits when enter is pressed
    const enterToSubmitEdit = (event) => {
        if (event.code == 'Enter') {
            if (editingIndex != -1) {
                handleSubmitEdit()
            }
        }
    }
    
    // Function to refresh the student list
    const handleRefresh = () => {
        // Clear fields only if there are values to be cleared (there is a useEffect that will call getEventInfo if filter options are clear)
        if (filterValue && filterValue.length > 0 || filterField && filterField.length > 0) {
            setFilterField('')
            setFilterValue('')
            setFiltersChanged(true)
        } else {
            getEventInfo()
        }
    }

    const getFilterableFields = () => {
        return DIAG_SEARCH_FIELDS
    }

    // Function to set the sort flag with the appropriate keys
    const handleSort = (key) => {
        let newSortFlag = sortFlag.slice();
        let sortKeyIndex = newSortFlag.findIndex(obj => obj[`sortField`] === key)

        if(sortKeyIndex === -1) {
            let newSortObj = {
                [`sortField`]: key,
                [`sortOrder`]: 'asc'
            }
            newSortFlag.push(newSortObj)
        }
        else{
            if(newSortFlag[sortKeyIndex].sortOrder === 'asc') {
                newSortFlag[sortKeyIndex].sortOrder = 'desc'
            }
            else if(newSortFlag[sortKeyIndex].sortOrder === 'desc') {
                newSortFlag.splice(sortKeyIndex, 1)
            }
        }

        setSortFlag(newSortFlag);
    }

    // Function to retrieve the event info with the proper filters and sorts 
    const getEventInfo = () => {
        // Build query object
        let queries = {}

        // Determines if the query retrieves approved vs hidden entries
        queries['reportType'] = listType

        if (sortFlag) {
            sortFlag.map((sortObj, i) => {
                queries[`sortField${i}`] = sortObj.sortField
                queries[`sortOrder${i}`] = sortObj.sortOrder
            })
        }

        if (filterField && filterValue && filterValue.length > 0) {
            queries.filterField = filterField
            queries.filterValue = filterValue
        }

        queries.paginationLimit = pageLimit;
        queries.paginationPage = currentPage + 1;

        generateDiagnosticReport(queries)
            .then( res => {
                setEventInfo(res.data.students)
                setTotalEntries(res.data.totalEntries)
                
                if (filtersChanged) { // Reset current page back to 1 (0 indexed)
                    setCurrentPage(0)
                    setDelayedPage(0)
                    setFiltersChanged(false)
                } else {
                    setDelayedPage(currentPage)
                }
            })
            .catch(err => console.log(err))  
    }

    // Function to establish what student info gets shown/hidden
    const filterStudentList = () => {
        let info = [];
        if (eventInfo){
            info = eventInfo.slice();
        }

        let arr = []
        if(info.length > 0){
            arr = info.map(request => {
                // Anything that doesn't need to be displayed in a specific position is placed in ...extras
                let {
                    _id, 
                    parents,
                    diagnosticId,
                    reportEntry,
                    reportHidden,
                    testForm,
                    approved,
                    confirmed,
                    hidden, 
                    testType, 
                    studentName, 
                    studentPhone, 
                    gradeLevel, 
                    parentEmail, 
                    parentName, 
                    parentPhone, 
                    ...extras
                } = request;

                // New object with sorted headings
                let sortedDiagObj = null
                if(showExpandedInfo) { // Headings for expanded diagnostics
                    sortedDiagObj = {
                        _id: request._id,
                        diagnosticId: request.diagnosticId,
                        reportEntry,
                        reportHidden,
                        '#': '-',
                        studentName: request.studentName,
                        tutorName: request.tutorName,
                        gradeLevel: request.gradeLevel,
                        testType: request.testType,
                        diagnosticDate: request.diagnosticDate,
                        location: request.location,
                        satForm: request.satForm,
                        sr: request.sr,
                        sg: request.sg, 
                        sm: request.sm,
                        st: request.st,
                        actForm: request.actForm,
                        ag: request.ag,
                        am: request.am, 
                        ar: request.ar,
                        as: request.as,
                        at: request.at,
                        extendedTime: request.extendedTime,
                        prevScores: request.prevScores,
                        school: request.school,
                        attended: request.attended,
                        mathClass: request.mathClass,
                        studentPhone: request.studentPhone,
                        studentEmail: request.studentEmail,
                        parentName: request.parentName,
                        parentPhone: request.parentPhone,
                        parentEmail: request.parentEmail,
                        parentPhone: request.parentPhone,
                        parentName2: request.parentName2,
                        parentPhone2: request.parentPhone2,
                        parentEmail2: request.parentEmail2,
                        address: request.address,
                        timeApproved: request.timeApproved,
                        ...extras
                    }
                }
                else { // Headings for collapsed diagnostics
                    sortedDiagObj = {
                        _id: request._id,
                        diagnosticId: request.diagnosticId,
                        reportEntry: request.reportEntry,
                        reportHidden: request.reportHidden,
                        '#': '-',
                        studentName: request.studentName,
                        tutorName: request.tutorName,
                        testType: request.testType,
                        diagnosticDate: request.diagnosticDate,
                        satForm: (request.satForm? request.satForm : null),
                        sr: request.sr,
                        sg: request.sg, 
                        sm: request.sm,
                        st: request.st,
                        actForm: (request.actForm? request.actForm : null),
                        ag: request.ag,
                        am: request.am, 
                        ar: request.ar,
                        as: request.as,
                        at: request.at,
                        studentPhone: request.studentPhone,
                    }
                }
                return sortedDiagObj;
            })
        }
        setList(arr);
    }

    const handleShowExpandedInfo = () => {
        if(showExpandedInfo)
            setShowExpandedInfo(false)
        else    
            setShowExpandedInfo(true)
    }

    function checkRole(role) {
        if(role === 'ADMIN') {
            return false
        } else if(role === 'TUTOR') {
            return true
        }
    }

    // Sub-component for student options dropdown
    const renderStudentOptions = (student, index) => {
        return (
            <Dropdown>
                <Dropdown.Toggle as={CustomDropdown}></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={CustomItem} onClick={() => {setEditingIndex(index); console.log(studentList[index])}}>Edit</Dropdown.Item>
                    <Dropdown.Item as={CustomItem} onClick={() => {
                        let thisStudent = studentList[index]
                        
                        createReportEntry({ studentName: thisStudent.studentName, studentPhone: thisStudent.studentPhone})
                            .then(() => getEventInfo()) // Refresh list on successful entry creation
                            .catch(() => alert("Error: Unable to create new entry. Please try again later."))
                    }}>
                        Add Entry
                    </Dropdown.Item>
                    {!studentList[index].reportHidden || studentList[index].reportHidden == false ? (
                        <Dropdown.Item as={CustomItem} onClick={() => {
                            let promises = []

                            if (studentList[index].reportEntry == true) {
                                promises.push(editReportEntry(studentList[index]._id, { reportHidden: true }))
                            } else {
                                promises.push(editDiagnosticRequest(studentList[index].diagnosticId, studentList[index]._id, { reportHidden: true }))
                            }

                            Promise.all(promises)
                                .then(() => getEventInfo())
                                .catch(() => alert("Error: Unable to hide entry. Please try again later."))
                        }}>
                            Hide Entry
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item as={CustomItem} onClick={() => {
                            let promises = []

                            if (studentList[index].reportEntry == true) {
                                promises.push(editReportEntry(studentList[index]._id, { reportHidden: false }))
                            } else {
                                promises.push(editDiagnosticRequest(studentList[index].diagnosticId, studentList[index]._id, { reportHidden: false }))
                            }

                            Promise.all(promises)
                                .then(() => getEventInfo())
                                .catch(() => alert("Error: Unable to un-hide entry. Please try again later."))
                        }}>
                            Un-Hide Entry
                        </Dropdown.Item>
                    )}
                    {studentList[index]?.reportEntry == true && (
                        <Dropdown.Item as={CustomItem} onClick={() => {
                            deleteReportEntry(studentList[index]._id)
                                .then(() => getEventInfo())
                                .catch(() => alert("Error: Unable to delete entry. Please try again later."))
                        }}>
                            Delete Entry
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    // Submit student entry edit
    const handleSubmitEdit = () => {
        let edits = Array.from(document.getElementsByClassName('edit-student-input'))
        let editObject = {}
        let count = 0;
        Object.entries(handleHeadings()).map((key, i) => {
            if(key[0] !== 'approved' 
            && key[0] !== 'confirmed' 
            && key[0] !== '_id' 
            && key[0] !== 'diagnosticId' 
            && key[0] !== 'diagnosticDate' 
            && key[0] !== 'reportEntry' 
            && key[0] !== 'reportHidden'
            && key[0] !== '#' 
            && key[0] !== 'timeRequested' 
            && key[0] !== 'studentPhone'
            && key[0] !== 'timeApproved') {
                if(key[0] === 'extendedTime'){
                    editObject[key[0]] = edits[count].value
                    count++
                }
                else if(key[0] === 'gradeLevel'){
                    editObject[key[0]] = (edits[count].value === "None" ? null : edits[count].value)
                    count++
                }
                else {
                    editObject[key[0]] = (edits[count].value === '' ? null : edits[count].value)
                    count++
                }
            }
        })

        let promises = []

        if (studentList[editingIndex].reportEntry == true) {
            promises.push(editReportEntry(studentList[editingIndex]._id, editObject))
        } else {
            promises.push(editDiagnosticRequest(studentList[editingIndex].diagnosticId, studentList[editingIndex]._id, editObject))
        }

        Promise.all(promises)
            .then(res => {
                setEditingIndex(-1);
                getEventInfo();
            })
            .catch(err => {
                if(err.response.status === 500){
                    if(err.response.data.message.message.includes('N/A')){
                        alert("Error: This student entry uses an old data format, and cannot be updated")
                    }
                    else{
                        alert('Error, Please confirm that information uses appropriate format')
                    }
                }
                else if(err.response.status === 400) {
                    if(err.response.data.data[0].value === 'N/A'){
                        alert("Error: This student entry uses an old data format, and cannot be updated")
                    }
                    else{
                        alert('Error, Please confirm that information uses appropriate format')
                    }
                }
                else{
                    alert(err.response.status)
                }
                console.log(err.response)
                
                setEditingIndex(-1);
            })
    }

    // Custom dropdown
    const CustomDropdown = React.forwardRef(({onClick}, ref) => (
        <div
            href=''
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e)
            }}
            className={styles.settingsIcon}
        >
            <FaCog/>
        </div>
    ));
    
    // Custom dropdown item
    const CustomItem = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy, onClick }, ref) => {
      
          return (
            <div
              ref={ref}
              style={style}
              className={className}
              aria-labelledby={labeledBy}
              onClick = {onClick}
            >
              {children}
            </div>
          ); 
        },
    );

    // Function to find the object with the most keys to use those as list headings 
    const handleHeadings = () => {
        return studentList.slice().sort((a, b) => a.hasOwnProperty("location") ? -1 : b.hasOwnProperty("location") ? 1 : 0)[0]
    }

    // Function to handle the list headings format
    const renderTableHeadings = () => {
        return (
            <>
            {Object.entries(handleHeadings()).map((key, i) => {
                if(key[0] !== '_id' && key[0] !== 'diagnosticId' && key[0] !== 'reportEntry' && key[0] !== 'reportHidden') { // skip _id
                    // if key is sortable
                    if(key[0] === 'studentName'
                        || key[0] === 'studentPhone'
                        || key[0] === 'parentName' 
                        || key[0] === 'testType' 
                        || key[0] === 'school' 
                        || key[0] === 'gradeLevel'
                        || key[0] === 'attended'
                        || key[0] === 'extendedTime'
                        || key[0] === 'mathClass'
                        || key[0] === 'codeUsed'
                        || key[0] === 'location'
                        || key[0] === 'diagnosticDate'
                        || key[0] === 'satForm'
                        || key[0] === 'actForm'
                        || DIAG_SCORE_HEADINGS.find(element => element === key[0])
                    ) { 
                        let foundField = sortFlag.find(obj => obj[`sortField`] === key[0])
                        let foundIndex = sortFlag.findIndex(obj => obj[`sortField`] === key[0]) + 1
                        // if no sort selected or current key is not being sorted
                        if(foundField === undefined){ 
                            return (
                                <th key={i + 'th'} onClick={() => handleSort(key[0])} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                    {key[0] === 'gradeLevel' 
                                    ?   'Grade'
                                    :   (key[0] === 'extendedTime' 
                                        ?   'ET'
                                        :   (key[0] === 'satForm' 
                                            ?   'SAT Form'
                                            :   (key[0] === 'actForm' 
                                                ?   'ACT Form'
                                                :   sanitizeFieldHeader(key[0])
                                                )
                                            )
                                        )
                                    }
                                </th>
                            )
                        }
                        // if ascending sort
                        else if(foundField.sortOrder === 'asc') {
                            return (
                                <th key={i + 'th'} onClick={() => handleSort(key[0])} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                    <div className='d-flex'>
                                        {key[0] === 'gradeLevel' 
                                        ?   'Grade'
                                        :   (key[0] === 'extendedTime' 
                                            ?   'ET'
                                            :   (key[0] === 'satForm' 
                                                ?   'SAT Form'
                                                :   (key[0] === 'actForm' 
                                                    ?   'ACT Form'
                                                    :   sanitizeFieldHeader(key[0])
                                                    )
                                                )
                                            )
                                            
                                        }
                                        <FaSortUp style={{width: '10px', color: 'grey'}}></FaSortUp>
                                        <p style={{fontSize: '12px', marginBottom: '0px'}}>({foundIndex})</p>
                                    </div>
                                </th>
                            )
                        }  
                        // if descending sort
                        else if(foundField.sortOrder === 'desc') {
                            return (
                                <th key={i + 'th'} onClick={() => handleSort(key[0])} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                    <div className='d-flex'>
                                        {key[0] === 'gradeLevel' 
                                        ?   'Grade'
                                        :   (key[0] === 'extendedTime' 
                                            ?   'ET'
                                            :   (key[0] === 'satForm' 
                                                    ?   'SAT Form'
                                                    :   (key[0] === 'actForm' 
                                                        ?   'ACT Form'
                                                        :   sanitizeFieldHeader(key[0])
                                                        )
                                                    )
                                            )
                                            
                                        }
                                        <FaSortDown style={{width: '10px', color: 'grey'}}></FaSortDown>
                                        <p style={{fontSize: '12px', marginBottom: '0px'}}>({foundIndex})</p>
                                    </div>
                                </th>
                            )
                        }                                               
                    }
                    // if key is not sortable
                    else{
                        return (
                            <th key={i + 'th'} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                {key[0] === 'gradeLevel' 
                                ?   'Grade'
                                :   (key[0] === 'extendedTime' 
                                    ?   'ET'
                                    :   (key[0] === 'satForm' 
                                        ?   'SAT Form'
                                        :   (key[0] === 'actForm' 
                                            ?   'ACT Form'
                                            :   sanitizeFieldHeader(key[0])
                                            )
                                        )
                                    )
                                    
                                }
                            </th>
                        )
                    }
                }
            })}
            <th className={`${styles.headings} student-table-heading options`}>
                Options
            </th>
            </>
        )
    }

    // Function to handle format of list data
    const renderTableData = (request, i) => {
        let headings = handleHeadings()
        return (
            <>
            {Object.entries(headings).map((key, j) => { // Data entries
                                                            
            if(key[0] !== '_id' && key[0] !== 'diagnosticId' && key[0] !== 'reportEntry' && key[0] !== 'reportHidden') { //Don't include _id
                if(j === 4) // First column is the #
                    return (
                        <td className={`student-table-data`} style={{paddingLeft: '2px'}} key={j + request._id}>
                            {i + 1 + (pageLimit * (delayedPage))}
                        </td>
                    )
                else if(i === editingIndex){ // if this request is being edited
                    if(key[0] === 'studentName')
                        return (
                            <th scope='row' className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                                <FormControl
                                    className = 'edit-student-input'
                                    defaultValue = {request[key[0]] ? request[key[0]]: ''}
                                />
                            </th>
                        )
                    else if( // If column cannot be edited
                        key[0] === 'studentPhone'
                    )
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                {request[key[0]] ? request[key[0]]: "-"}
                            </td>
                        )
                    else if(key[0] === 'diagnosticDate' || key[0] === 'timeApproved' )
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                            {!request[key[0]] ? 
                                '-'
                            :
                                `${new Date(request[key[0]]).toLocaleDateString(undefined, {timeZone: 'America/New_York'})} - ${new Date(request[key[0]]).toLocaleTimeString(undefined, {timeZone: 'America/New_York'})}`
                            }
                            </td>
                        )
                    else if(key[0] === 'extendedTime' || key[0] === 'attended') // if editing yes/no column
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                <Form.Control as="select" className = 'edit-student-input' defaultValue = {request[key[0]]}>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Form.Control>
                            </td>
                        )
                    else if(key[0] === 'gradeLevel') // if editing grade level
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                <Form.Control as="select" className = 'edit-student-input' defaultValue = {request[key[0]]}>
                                    <option value={null}>None</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                </Form.Control>
                            </td>
                        )
                    else if(key[0] === 'testType') {// if editing test type
                        console.log(key[1])
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                <Form.Control as="select" className = 'edit-student-input' defaultValue = {request[key[0]]}>
                                    <option value={'Completed'}>Completed</option>
                                    <option value={'SAT'}>SAT</option>
                                    <option value={'ACT'}>ACT</option>
                                </Form.Control>
                            </td>
                        )
                    }
                    else // if editing anything else
                        return (
                            <td className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                                <FormControl
                                    className = 'edit-student-input'
                                    defaultValue = {request[key[0]] ? request[key[0]]: ''}
                                />
                            </td>
                        );
                }
                // no longer editing
                else if(key[0] === 'extendedTime' || key[0] === 'attended') // if yes/no column
                    return (
                        <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                            {request[key[0]] === true ? 'Yes' : 'No'}
                        </td>
                    )
                else if(key[0] === 'timeRequested' || key[0] === 'timeApproved' || key[0] === 'timeEnrolled' || key[0] === 'diagnosticDate') // if time column
                    return (
                        <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                            {!request[key[0]] ? 
                                '-'
                            :
                                `${new Date(request[key[0]]).toLocaleDateString(undefined, {timeZone: 'America/New_York'})} - ${new Date(request[key[0]]).toLocaleTimeString(undefined, {timeZone: 'America/New_York'})}`
                            }
                            
                        </td>
                    )
                else if(key[0] === 'studentName')
                    return (
                        <th scope='row' className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} style={{paddingLeft: '5px'}} key={j + request._id}>
                            {request[key[0]] ? request[key[0]]: "-"}
                        </th>
                    )
                else // all other columns
                    return (
                        <td className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                            {request[key[0]] ? request[key[0]]: "-"}
                        </td>
                    );
            }
        })}
        <td className={`student-table-data ${styles.optionsHeading}`} hidden={checkRole(user.role)}>
            {i === editingIndex
            ?   <Button className={styles.submitBtn} onClick={() => handleSubmitEdit()}>Submit</Button>
            :   renderStudentOptions(request, i)
            }
        </td>
        </>
        )
    }

    return(
        mobileDisplay? 
            <div style={{width: '100%', height: '100vh', color: 'grey', fontSize: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Please Enlarge the Screen to View Report
            </div>
        :
        <>
        <div style={{height: '100vh'}} className={`d-flex flex-column align-items-center justify-content-center`}>
            <div style={{width: '100%', height: '90%'}}>
                <div className={`${styles.listHeading}`}>
                    <div className={styles.listNav}>
                        <div className={styles.homeLink}>
                            <Link to="/portal/home"><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Home</Link>
                        </div>
                        <TooltipWrapper text={'Export ALL Diagnostics'}>
                            <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} style={{}} onClick={() => handleDownload(null, 'allDiagnostic', true)}><FaCloudDownloadAlt/></Button>
                        </TooltipWrapper>
                        <Form.Control
                            as="select" 
                            required
                            value={!listType ? '' : listType}
                            onChange={(event) => setListType(event.target.value)}
                            className={`${styles.listDropDown} dropDownArrow`}
                        >
                            <option value={'approved'}>Approved</option>
                            <option value={'hidden'}>Hidden</option>
                        </Form.Control>
                    </div>
                    <div className='d-flex align-items-center'>
                        <h4 
                            style={{marginBottom: '0px', fontSize: '30px'}}
                        >
                            Student Records
                        </h4>
                    </div>
                    <div className={styles.listNav}>
                        <Filter getEventInfo={getEventInfo} setFilterField={setFilterField} filterField={filterField} setFilterValue={setFilterValue} filterValue={filterValue} fields={getFilterableFields()} topOfScreen={true}/>
                        <TooltipWrapper text={'Refresh List'}>
                            <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} onClick={handleRefresh}><HiOutlineRefresh/></Button>
                        </TooltipWrapper>
                        {showExpandedInfo ? (
                            <TooltipWrapper text={'Collapse'}>
                                <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} style={{marginRight: '10px'}} onClick={handleShowExpandedInfo}><BiCollapse/></Button>
                            </TooltipWrapper>
                        ) : (
                            <TooltipWrapper text={'Expand'}>
                                <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} style={{marginRight: '10px'}} onClick={handleShowExpandedInfo}><BiExpand/></Button>
                            </TooltipWrapper>
                        )}
                    </div>
                </div>
                {studentList
                ?
                    (studentList.length > 0?
                        <div className={styles.studentList}>
                            <Table hover size="sm" className={styles.portalStudentList} id='list-table'>
                                
                                <thead className={styles.stickyListHeading} id='list-heading'>
                                    <tr className={styles.filler}></tr>
                                    <tr id='table-headings-container'>
                                        {renderTableHeadings()}
                                    </tr>
                                </thead>
                                <tbody className={styles.tableBody}>
                                    {(() => {
                                        return studentList.slice().map((request, i) => {
                                            if(i === 0 || request.studentName === studentList[i-1].studentName){
                                                return (
                                                    <tr key={`data-table-row-${i}`} className={styles.dataRow} onDoubleClick={(event) => setEditingIndex(i)}>
                                                        {renderTableData(request, i)}
                                                    </tr>
                                                )
                                            }
                                            else{
                                                return (
                                                    <tr key={`data-table-row-${i}`} style={{borderTop: 'rgb(240, 240, 240) solid 25px'}} className={styles.dataRow} onDoubleClick={(event) => setEditingIndex(i)}>
                                                        {renderTableData(request, i)}
                                                    </tr>
                                                )
                                            }
                                            
                                        })}
                                    )()}
                                </tbody>
                            </Table>
                        </div>
                    :
                        <div className={styles.emptyList}>
                            No Entries Found
                        </div>            
                    )
                    
                :
                    <div className={styles.emptyList}>
                        Loading Entries...
                    </div>
                }
            </div>
            <div className='d-flex align-items-center' style={{height: '10%', width: '100%' }}>
                {(eventInfo && totalEntries) && (
                    <Pagination active={currentPage} setActive={setCurrentPage} limit={pageLimit} setLimit={setPageLimit} total={totalEntries}/>
                )}
            </div>
        </div>
        </>
        )
        
}

export default Students;