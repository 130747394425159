import React,{useState, useEffect} from 'react'
import styles from './AddEventButton.module.css'
import './AddEventButton.css'
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import 'rc-time-picker/assets/index.css';
import { FaRegCalendarPlus } from 'react-icons/fa';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import CreateDiagnosticForm from './CreateDiagnosticForm'
import CreateCourseForm from './CreateCourseForm'
import CreateGeneralEventForm from './CreateGeneralEventForm'

// Component
const AddEventButton = ({getAllEvents}) => {
    const [show, setShow] = useState(false); // modal status
    const [submitted, setSubmitted] = useState(false); // modal status
    const [eventType, setEventType] = useState(null);

    useEffect(() => {
        getAllEvents()
    }, [submitted])

    //closes modal and resets data
    const handleClose = () => {
        setShow(false);
        setTimeout(()=> {
            setSubmitted(false)
            setEventType(null)
        }, 500); // stops info from changing before closing animation finishes
    }

    const handleShow = () => {
        setShow(true);
    }

    const renderForm = () => {
        if(eventType==='Diagnostic') {
            return (
                <CreateDiagnosticForm setSubmitted={setSubmitted}></CreateDiagnosticForm>
            )
        }
        else if(eventType==='Course/Bootcamp') {
            return (
                <CreateCourseForm setSubmitted={setSubmitted}></CreateCourseForm>
            )
        }
        else if(eventType==='General Event') {
            return (
                <CreateGeneralEventForm setSubmitted={setSubmitted}></CreateGeneralEventForm>
            )
        }
        else {
            return null;
        }
    }
    
    return (
        <>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            id='add-event-modal'
        >
            <Modal.Header closeButton>
            <Modal.Title>Create a New Event</Modal.Title>
            </Modal.Header>
            {submitted 
            ?(
                <>
                <Modal.Body>
                <p style={{fontWeight:'500'}}>{eventType} has been created</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Okay
                </Button>
                </Modal.Footer>
                </>
            )
            :(
                <>
                <Modal.Body>
                <Form id='requestForm'>
                    <Form.Group>
                        <p style={{marginBottom: '0px'}}>Event Type: </p>
                        <div>
                            <Form.Check name='events' type='radio' label='Diagnostic' style={{marginLeft: '5px'}} onChange={() => setEventType('Diagnostic')}></Form.Check>
                            <Form.Check name='events' type='radio' label='Course/Bootcamp' style={{marginLeft: '5px'}} onChange={() => setEventType('Course/Bootcamp')}></Form.Check>
                            <Form.Check name='events' type='radio' label='General Event' style={{marginLeft: '5px', marginBottom: '25px'}} onChange={() => setEventType('General Event')}></Form.Check>
                        </div>
                    </Form.Group>
                </Form>
                {eventType
                ? <p style={{fontSize: '24px'}}>Create {eventType}</p>
                : null
                }
                {renderForm()}   
                
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                </Modal.Body>
                </>
            )
            }
        </Modal>
        <Button className={styles.newButton} onClick={handleShow}><FaRegCalendarPlus style={{fontSize: '18px'}}></FaRegCalendarPlus></Button>
        </>
    )
}

export default AddEventButton;