import React, {useEffect} from 'react'
import styles from"../styles/portal.module.css"
import { Router, Redirect  } from "@reach/router"
import { Link } from 'gatsby'
import SignIn from '../components/portal_components/SignIn/SignIn'
import Home from '../components/portal_components/Home/Home'
import Students from '../components/portal_components/Students/Students'
import SendRecap from '../components/portal_components/SendRecap/SendRecap'
import 'animate.css/animate.css' 
import Helmet from 'react-helmet';
import Events from '../components/portal_components/Events/Events/Events';
import PrivateRoute from '../components/PrivateRoute';
import AdminRoute from '../components/AdminRoute'
import ResetPassword from '../components/portal_components/ResetPassword/ResetPassword';
import ForgotPassword from '../components/portal_components/ForgotPassword/ForgotPassword';
import { isLoggedIn } from '../utility/general'
import Register from '../components/portal_components/Register/Register';

const PortalPage = ({location}) => {
    useEffect(() => {

        // if(location.pathname === "/portal") {
        //     isLoggedIn().then(res => {
        //         if(res) {
        //             redirectTo("/home");
        //         }
        //         else if(res === false) {
        //             redirectTo("/signin");
        //         }
        //     })
        // }
    }, [])
    return (
        <div>
            <Helmet >
                <meta charSet="utf-8" />
                <title>Portal | Beck College Prep</title>
                <link rel="canonical" href="https://beckcollegeprep.com" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"></link>
            </Helmet>
            <Router basepath="/portal">
                <SignIn path="/signin"/>
                <PrivateRoute path='/home' component={Home}/>
                {/* <PrivateRoute path='/tutorevents' component={TutorEvents}/> */}
                <PrivateRoute path='/events' component={Events}/>
                <PrivateRoute path='/students' component={Students}/>
                <PrivateRoute path='/send-recap' component={SendRecap}/>

                <CloseWindow path="/redirect-window-close"/>
                <ResetPassword path="/reset-password/:requestId"/>
                <ForgotPassword path="/forgot-password/"/>
                {/* <Redirect from="/" to="/signin" /> */}
                <Register path="/register/:id"/>          
                <Redirect path="/" from="/" to="/portal/home" noThrow/>
                <NotFound default />
            </Router>
        </div>
    )
}

export default PortalPage

const NotFound = () => {
    return (
      <div className={`${styles.page} d-flex justify-content-center align-items-center`}>
        <p className={styles.generalText}>It seems you've lost your way. <Link to="/portal" className={styles.link}>Go to the portal</Link>.</p>
      </div>
    )
}

const CloseWindow = () => {
    window.close();
    return (<></>)
}