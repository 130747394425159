import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styles from "./Events.module.css"
import "../../../calendar_components/ModalCalendar/calendar.css"
import "./portalcalendar.css"
import { ResizableBox } from 'react-resizable';
import { FaSortUp, FaArrowLeft } from 'react-icons/fa';
import { getDiagnostics, getGeneralEvents, getCourses, deleteGeneralEvent } from '../../../../api/api'
import bigB from '../../../../images/calendarB.png'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Form from 'react-bootstrap/Form'
import EventList from '../EventList/EventList'
import Button from 'react-bootstrap/Button'
import AddEventButton from '../AddEventButton/AddEventButton'
import SearchAllButton from '../SearchAllButton/SearchAllButton'
import userEvent from '@testing-library/user-event'

const localizer = momentLocalizer(moment) //Defines localizer for the calendar
const CALENDAR_HEIGHT = 550; // Height at which the calendar is initialized
const MOBILE_SIZE = 1200; // Width in which screen is too small to display portal

// Portal component for the events page. Contains the the calendar component and renders the EventList component with the correct info.
const Events = ({ user }) => {
    const [eventsList, setEvents] = useState([]); // list of events
    const [allDiagnostics, setAllDiagnostics] = useState([]); // list of all diagnostics
    const [allGeneralEvents, setAllGeneralEvents] = useState([]); // list of all general events
    const [allBootcamps, setAllBootcamps] = useState([]); // list of all bootcamps/courses
    const [filters, setFilters] = useState({}); // state holding the flags for filtering
    const [currentEvent, setCurrentEvent] = useState(null); // currently selected event
    const [mobileDisplay, setMobileDisplay] = useState(false); // stores whether screen is too small or not
    const [calendarWidth, setCalendarWidth] = useState(window.innerWidth); // used to cleanly resize ReactResizable component
    const [calendarHeight, setCalendarHeight] = useState(CALENDAR_HEIGHT);
    
    
    // OnComponentMount
    useEffect(() => {
        getAllEvents()

        window.addEventListener('resize', setDisplayModes, { passive: true })
        window.addEventListener('resize', (()=>{setCalendarWidth(window.innerWidth)}), { passive: true })

        return function cleanup() {
            window.removeEventListener('resize', setDisplayModes, { passive: true })
        }
    }, [])

    // On filter change, repopulate calendar events
    useEffect(() => {
        if(Object.entries(filters).length > 0)
            populateCalendarEvents();
    }, [filters]);

    // Check the window width vs the mobile size limit
    function setDisplayModes(event) {
        if (window.innerWidth <= MOBILE_SIZE) {
            setMobileDisplay(true)
        } else {
            setMobileDisplay(false)
        }
    }

    // Retrieve all the events for the calendar from the database
    const getAllEvents = async () => {
        let newFilters = {...filters};

        // Initialize general events
        getGeneralEvents()
        .then(generalRes => {
            if(generalRes.data?.length > 0) 
                setAllGeneralEvents(generalRes.data)
            else 
                setAllGeneralEvents([])
                newFilters['General Events'] = (filters["General Events"] === false ? false : true);

            // Initialize diagnostics
            getDiagnostics()
            .then(diagRes => {
                if(diagRes.data?.length > 0) 
                    setAllDiagnostics(diagRes.data)
                else 
                    setAllDiagnostics([])
                newFilters.Diagnostics = (filters["Diagnostics"] === false ? false : true);

                // Initialize courses/bootcamps
                return getCourses()
                .then(courseRes => {
                    if(courseRes.data.length > 0) 
                        setAllBootcamps(courseRes.data)
                    else 
                        setAllBootcamps([])

                    newFilters["SAT Bootcamps"] = (filters["SAT Bootcamps"] === false ? false : true);
                    newFilters["ACT Bootcamps"] = (filters["ACT Bootcamps"] === false ? false : true);
                    newFilters["Courses"] = (filters["Courses"] === false ? false : true);
                    setFilters(newFilters);
                })
               
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    //Function to conditionally render the event's color based on the event type
    const eventStyleGetter = (event, start, end, isSelected) => {
        var borderColor;
        var color;
        if(event.type === 'diagnostic') {
            borderColor = '#DC3F3A'
            color = '#DC3F3A'
        }
        else if(event.type === 'general'){
            borderColor = '#4b4b4b'
            color = '#4b4b4b'
        }
        else if(event.type === 'psatbootcamp' || event.type === 'satbootcamp'){
            borderColor = '#0313A0'
            color = '#0313A0'
        }
        else if(event.type === 'actbootcamp'){
            borderColor = '#f07400'
            color = '#f07400'
        } 

        var style = {
            borderColor: borderColor,
            color: color
        };
        
        return {
            className: event.type + ' portal-event',
            style: style
        };
    }

    // Correctly formats the lists of events to show on the calendar
    const populateCalendarEvents = () => {
        let events = [];
        for(const [key, value] of Object.entries(filters)) {
            //Diagnostics
            if(key === "Diagnostics" && value ){
                let diags = allDiagnostics.map((d, i) => {
                    //let approvedNum = d.studentRequests.filter(request => request.approved && !request.hidden).length;
                    let requestNum = d.studentRequests.filter(request => !request.approved && !request.hidden).length;
                    let startTime = new Date(d.date.start).toLocaleTimeString()
                    return {
                        id: d._id,
                        title: `(Requests: ${requestNum} | ${startTime}) ${d.name}`,
                        desc: d.description,
                        type: 'diagnostic',
                        start: new Date(d.date.start),
                        end: new Date(d.date.end)
                    }
                })
                events = [...diags, ...events];
            }
            if(key === "General Events" && value ){
                let generals = allGeneralEvents.map((g, i) => {
                    return {
                        id: g._id,
                        title: g.name,
                        type: 'general',
                        start: new Date(g.date.start),
                        end: new Date(g.date.end)
                    }
                })
                events = [...generals, ...events];
            }
            let bootcamps = allBootcamps.map((bootcamp, i) => {
                let meetings = [];
                meetings = bootcamp.meetings.map((meeting, j) => {
                    let startTime = new Date(meeting.start).toLocaleTimeString()
                    if(meeting.entryType === 'psatbootcamp' && key === "SAT Bootcamps" && value) { // if event is a psatbootcamp (they use the same filter as satbootcamps)
                        return {
                            id: bootcamp._id,
                            info: bootcamp,
                            title: `(Requests: ${bootcamp.requests.slice().filter(student => (!student.hidden)).length} | ${startTime}) ${bootcamp.name} Day ${j+1}`,
                            type: 'psatbootcamp',
                            start: new Date(meeting.start),
                            end: new Date(meeting.end)
                        }
                    }
                    else if(meeting.entryType === 'actbootcamp' && key === "ACT Bootcamps" && value) { // event is an actbootcamp
                        return {
                            id: bootcamp._id,
                            info: bootcamp,
                            title: `(Requests: ${bootcamp.requests.slice().filter(student => (!student.hidden)).length} | ${startTime}) ${bootcamp.name} Day ${j+1}`,
                            type: 'actbootcamp',
                            start: new Date(meeting.start),
                            end: new Date(meeting.end)
                        }
                    }
                    else if( // event is an satbootcamp (these ifs are separated for the sake of filtering)
                        meeting.entryType === 'satbootcamp' 
                        && key === "SAT Bootcamps" 
                        && value 
                    )
                    {
                        return {
                            id: bootcamp._id,
                            info: bootcamp,
                            title: `(Requests: ${bootcamp.requests.slice().filter(student => (!student.hidden)).length} | ${startTime}) ${bootcamp.name} Day ${j+1}`,
                            type: 'satbootcamp',
                            start: new Date(meeting.start),
                            end: new Date(meeting.end)
                        }
                    } 
                })
                events = [...meetings, ...events];
            })
        }
        setEvents(events)
    }

    // Function to set the currentEvent state to whichever event is clicked
    const handleEventClick = (event) => {
        setCurrentEvent(event);
    }

    // Function to deal with hiding the calendar or list when the view becomes too small
    // Also handles spacing issues with the calendar vertical scroll bar
    const handleResize = (data) => {
        document.getElementById("portal-bottom").style.height = `${window.innerHeight - data.size.height - 51}px`;
            
        if(parseFloat(document.getElementById("portal-top").offsetHeight) < (window.innerHeight * 0.055) + 1){
            setCalendarHeight(0)
            document.getElementById("collapse-calendar").style.display = "none";
        }
        else{
            setCalendarHeight(data.size.height)
            document.getElementById("collapse-calendar").style.display = "block";
        }

        if(parseFloat(document.getElementById("portal-top").offsetHeight) > (window.innerHeight * 0.87)){
            setCalendarHeight(window.innerHeight * 0.93)
            document.getElementById("portal-bottom").style.display = "none";
        }
        else{
            document.getElementById("portal-bottom").style.display = "block";
        }

        if((parseFloat(document.getElementById("portal-top").offsetHeight) >= CALENDAR_HEIGHT)) {
            document.getElementsByClassName("rbc-month-header")[0].style.paddingRight = '0px';
        }
        else {
            document.getElementsByClassName("rbc-month-header")[0].style.paddingRight = '15px';
        }
    }

    // method to update filterList when a filter switch is pressed
    const handleFilter = (e, key) => {
        let f = {...filters};
        f[key] = e.target.checked;
        setFilters(f);
    }
    
    // Filter switches component
    const renderFilters = () => {
        let rendered = [];
        let i = 1;
        for(const key in filters) {
            rendered.push(
                <Form.Check 
                    key={key} 
                    className={`filter-switch ${styles.filters}`}
                    label={key} type='switch' 
                    id={`check-${i}`} 
                    onChange={(event) => handleFilter(event, key)} 
                    checked={filters[key]}
                />
            )
            i++;
        }
        return rendered;
    }

    // Function to render EventList with correct information 
    const renderEvent = () => {
        if(currentEvent.type === 'diagnostic') {
            return (
                <EventList 
                    eventId={currentEvent.id} 
                    refreshCalendar={getAllEvents}
                    eventType={currentEvent.type}
                    allEvents={allDiagnostics}
                    user={user}
                />
            )
        }
        else if(currentEvent.type === 'general'){
            return (
                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                    <p style={{fontSize: '30px', fontWeight: '400'}}>{currentEvent.title}</p>
                    <p style={{fontSize: '24px'}}>{`${new Date(currentEvent.start).toLocaleDateString()} ${currentEvent.start.toLocaleTimeString()} - ${currentEvent.end.toLocaleTimeString()}`}</p>
                    <Button 
                        onClick={() => {
                            deleteGeneralEvent(currentEvent.id)
                            .then(res=>{
                                console.log(res)
                                setCurrentEvent(null)
                                getAllEvents()
                            })
                            .catch(err=>console.log(err))
                        }}
                        className='bcp-general-button'
                    >Delete Event</Button>
                </div>
            )
        }
        else if(currentEvent.type === 'satbootcamp' || currentEvent.type === 'psatbootcamp' || currentEvent.type === 'actbootcamp'){
            return (
                <EventList 
                eventId={currentEvent.id} 
                refreshCalendar={getAllEvents}
                eventType={currentEvent.type}
                allEvents={allBootcamps}
                user = {user}
            />
            )
        }
    }

    // Allows sticky heading to scroll horizontally alongside the table data 
    const scrollHeading = () => {
        let header = document.getElementById('list-heading');
        let table = document.getElementById('portal-bottom');

        if(header && table){
            header.style.left = `${-table.scrollLeft}px`;
        }
    }



    const doubleClickToSnap = (event) => {
        if(document.getElementById("resizeable").clientHeight < 1){
            setCalendarHeight(window.innerHeight * 0.93);
            document.getElementById("portal-bottom").style.height = `${window.innerHeight - (window.innerHeight * 0.93) - 51}px`;
            document.getElementById("collapse-calendar").style.display = "block";
            document.getElementById("portal-bottom").style.display = "none";
        }
        else{
            setCalendarHeight(0);
            document.getElementById("portal-bottom").style.height = `${window.innerHeight - 51}px`;
            document.getElementById("collapse-calendar").style.display = "none";
            document.getElementById("portal-bottom").style.display = "block";
        }
    }

    return(
        mobileDisplay? 
            <div style={{width: '100%', height: '100vh', color: 'grey', fontSize: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Please Enlarge the Screen to View Portal
            </div>
        :
        <>
            <div className={styles.page}>
            <div className={styles.header}>
                <div className={styles.homeLink}>
                    <Link to="/portal/home"><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Home</Link>
                </div>
            </div>
            <ResizableBox 
            width={calendarWidth} 
            height={calendarHeight}
            minConstraints={[Infinity, 0]}
            maxConstraints={[Infinity, window.innerHeight * 0.93]}
            handle= {<span className={styles.resizer} id='resize-handle' onDoubleClick={(event) => doubleClickToSnap(event)}>
                        {<FaSortUp style={{color: 'grey', paddingTop: '5px', transform: 'rotate(180deg)', fontSize: '20px', width: '20px'}}/>}
                    </span>}
            resizeHandles={['s']}
            axis={'y'}
            onResize= {(e, data) => handleResize(data)}
            id='resizeable'
            > 
                <>
                    <div className={styles.portalTop} id='portal-top'>
                        <MyCalendar 
                            filters={filters} 
                            renderFilters={renderFilters} 
                            eventsList={eventsList} 
                            eventStyleGetter={eventStyleGetter} 
                            handleEventClick={handleEventClick}
                            getAllEvents={getAllEvents}
                            setCurrentEvent={setCurrentEvent}
                            user={user}
                        />
                    </div>
                </>
            </ResizableBox>
        
            <div className={styles.resizeSpacer}></div>
            <div onScroll={scrollHeading} className={styles.portalBottom} id="portal-bottom" style={{height: window.innerHeight - (CALENDAR_HEIGHT) - 51}}> 

                    {currentEvent
                    ?   renderEvent()
                    :   <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{fontSize: '24px'}}>Click on an Event to get Started</p>
                        </div>
                    }
            
            </div>
            </div>
        </>
        
        
    )
}

export default Events;

// sub-component for the react calendar (For whatever reason, this sub-component needs to be outside of ModalCalendar because of rerendering issues)
const MyCalendar = ({renderFilters, eventsList, eventStyleGetter, handleEventClick, getAllEvents, setCurrentEvent, user}) => {

    function checkRole(role) {
        if(role === 'ADMIN') {
            return false
        } else if(role === 'TUTOR') {
            return true
        }
    }
    
    let formats = {
        weekdayFormat: 'dddd'
    }

    return(
        <>
            <div className={styles.filterBoxSpacer}>
                <div className={styles.filterBox}>
                    <p style={{fontSize: '24px'}}>Filters:</p>
                    {renderFilters()}
                    <div id='show-calendar-btn' className='d-flex flex-column' style={{ marginRight: '16px' }}>
                    </div>
                </div>
            </div>
            <div className={styles.calendarBox} id='collapse-calendar'>
                <div className={styles.searchBtnContainer}>
                    <SearchAllButton setCurrentEvent={setCurrentEvent}></SearchAllButton>
                </div>
                <div className={styles.addBtnContainer} hidden={checkRole(user.role)}>
                    <AddEventButton getAllEvents={getAllEvents}></AddEventButton>
                </div>
                <div className={`${styles.bigB} d-flex justify-content-center align-items-center`} style={{position:'absolute'}}>
                    <img src={bigB} alt="Beck's Big B"  style={{height:'70%'}}></img>
                </div>
                <Calendar
                    className='portal-calendar'
                    localizer={localizer}
                    formats={formats}
                    events={eventsList}
                    tooltipAccessor= 'title'
                    startAccessor="start"
                    endAccessor="end"
                    popup = {true}
                    eventPropGetter = {eventStyleGetter}
                    onSelectEvent = {(event, e) => {handleEventClick(event, e)}}
                    views={{
                        month: true,
                    }}
                />
            </div>
        </>
    )
}
        