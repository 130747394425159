import React, { useState, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styles from '../Settings.module.css'
import { getLessonInventory, addLessonPlan, updateLesson } from '../../../../api/api'
import { FaArrowDown, FaArrowUp, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { forEach } from 'jszip';

const LessonInventory = () => {

    const [queryLesson, setQueryLesson] = useState(null);

    const [lessons, setLessons] = useState([])
    const [show, setShow] = useState(false)
    const [clicked, setClicked] = useState(null)
    const [page, setPage] = useState(null)

    useEffect(() => {
        getLessonInventory().then(res => {
            setLessons(res.data)
        })
    },[])

    function showTest(i, show) {
        if (show == false) {
            setShow(true)
            setClicked(i)
            return false
        } else {
            setShow(false)
            setClicked(i)
            return true
        }
    }

    function renderPage(page) {
        switch(page) {
            case null:
                return (
                    <>
            
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <Button className={styles.bcpBtn} style={{width:'100%', marginBottom:'20px'}} onClick={() => {
                                setPage('addLessonPlan')
                            }}>Create Lesson Plan</Button>
                            {
                                lessons.map((item, i) => {
                                    return (<>
                                            <div 
                                            key={i} 
                                            style={{display:'flex', flexDirection:'row', width:'100%'}}
                                            onClick = {() => {
                                                setQueryLesson(item)
                                                setTimeout(()=> {
                                                    setPage('viewLesson')
                                                }, 100)
                                            }}>
                                                <p style={{width:'34%'}}>{item.lessonName}</p>
                                                <p style={{width:'33%', textAlign:'center'}}>{item.lessonType.toUpperCase()}</p>
                                                
                                                <div style={{width:'33%'}}><FaArrowRight style={{float:'right'}}/></div>
                                                
                                        </div>
                                            {
                                                (show == true && clicked == i)? (
                                                    <table style={{width:'98%', marginTop: '10px', backgroundColor:'white'}}>
                                        <colgroup>
                                            
                                            <col width='10%'/>
                                            <col width='45%'/>
                                            <col width='45%'/>
                                        </colgroup>
                                    <tbody>
                                        <tr>
                                            <th style={{textAlign:'center'}}>#</th>
                                            <th style={{textAlign:'center'}}>Lessons</th>
                                            <th style={{textAlign:'center'}}>Homeworks</th>
                                        </tr>
                                        {item.lesson.map((teaching, i) => 
                                            <tr key={i} 
                                                style={{borderTop:'1px solid grey', borderBottom:'3px solid grey', transform:'translateY(2px)'}}
                                            >
                                                    <td style={{width:'5%'}}>
                                                        {i+1}
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre', border:'1px solid gray'}} valign='top'>
                                                        <table style={{width:'100%', height:'inherit'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                    return  <tr key={'lesson '+ i} style={{height:'150px'}}>
                                                                    <td style={{ borderBottom:'1px solid gray'}}>
                                                                        <div style={{width:'320px'}}>
                                                                        {
                                                                                    learning.lessonsList.map((lesson, i) => {
                                                                                        return <div key={lesson + i} style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                                                            {
                                                                                            lesson.split(' ').map((word, i)=> {
                                                                                                return <p key={word + i} style={{margin:'0px', fontSize:'15px', color:'black'}}> {word}</p>
                                                                                                })
                                                                                            }  
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre', border:'1px solid gray'}} valign='top'>
                                                    <table style={{width:'100%'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                    return  <tr key={'lesson '+ i} style={{height:'150px'}}>
                                                                        <td style={{ borderBottom:'1px solid gray'}}>
                                                                            <div style={{width:'320px'}}>
                                                                            {
                                                                                        learning.homeworkList.map((lesson, i) => {
                                                                                            return <div key={lesson + i} style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                                                                {
                                                                                                lesson.split(' ').map((word, i)=> {
                                                                                                    return <p key={word + i} style={{margin:'0px', fontSize:'15px', color:'black'}}> {word}</p>
                                                                                                    })
                                                                                                }  
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                                ):(null)
                                            }
                                        </>)
                                })
                            }
                        </div>
            
                    </>
                )
            case 'addLessonPlan':
                return <AddLesson setPage={setPage}/>
            case 'viewLesson':
                return <ViewLesson setPage={setPage} queryLesson={queryLesson}/>
        }
    }

    return (
        renderPage(page)
    )
}

export default LessonInventory

const AddLesson = ({setPage}) => {

    const [lessonTitle, setLessonTitle] = useState('');
    const [lessonType, setLessonType] = useState('');

    const [lessonNumber, setLessonNumber] = useState('')
    const [multipleLessons, setMultipleLessons] = useState([]);
    const [addedLessons, setAddedLessons] = useState(0);
    const [lessonPlan, setLessonPlan] = useState([]);
    const [lessonsList, setLessonsList] = useState([])
    const [addedLessonsList, setAddedLessonsList] = useState(0)
    const [lessonName, setLessonName] = useState('');
    const [homeworkList, setHomeworkList] = useState([]);
    const [homeworkAdded, setHomeworkAdded] = useState(0);
    const [homeworkName, setHomeworkName] = useState('');

    const dragItem = useRef();
    const dragOverItem = useRef();

    const [confirmation, setConfirmation] = useState(false);

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const drop = (e) => {
        const copyListItems = [...lessonPlan];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        copyListItems.forEach((item, i) => {
            item.lessonNumber = i+1
            console.log(lessonPlan)
        })
        setLessonPlan(copyListItems);   
      };

      function remove(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i, 1)
                console.log(list)
                countFunction(list.length)
            } else {
                console.log('nope')
            }
        })
    }

    function add(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i,1)
                countFunction(list.length);
                return false
            } else {
                return true
            }
        })
        list.push(item);
        console.log(list)
        countFunction(list.length)
    }

    var listOfRows = []

    return (
        <>
        <div className={styles.backLink}>
                    <span onClick={()=>{setPage(null)}}><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Lesson Inventory</span>
                    </div>
        <table style={{width:'100%'}}>
            <tbody>
                <tr>
                    <td>
                        <p>Lesson Type:</p>                            
                    </td>
                    <td style={{display:'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                        <Form.Check type='radio' value='act' name='test' label='ACT' onClick={()=>{
                        setLessonType(event.target.value)
                        listOfRows.forEach(index => {
                            var inputVal = document.getElementById(index.toString());
                            inputVal.value = ''
                        })
                    }}></Form.Check>
                        <Form.Check type='radio' value='sat' name='test' label='SAT' onClick={()=>{
                        setLessonType(event.target.value)
                        listOfRows.forEach(index => {
                            var inputVal = document.getElementById(index.toString());
                            inputVal.value = ''
                        })
                    }}></Form.Check>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Lesson Plan Title:</p>
                    </td>
                    <td>
                        <Form.Control required type='name' placeholder='Lesson Plan Title' value={lessonTitle} style={{width:'100%'}} onChange={() => {
                            setLessonTitle(event.target.value)
                        }}></Form.Control>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style={{marginTop:'20px'}}>
                        <h4>Lessons</h4>
                            <div>
                            <table style={{width:"100%"}}>
                                <colgroup>
                                    <col width='25%'/>
                                    <col width='75%'/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Lesson Number</p>
                                        </td>
                                        <td>
                                        <Form.Control
                                                type="text"
                                                name="lessonNumber"
                                                value={lessonNumber}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setLessonNumber(event.target.value)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>
                                                Lesson Name:
                                            </p>
                                        </td>
                                        <td style={{display:"flex", flexDirection:"row"}}>
                                            <Form.Control
                                                type="text"
                                                name="lessonName"
                                                value={lessonName}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setLessonName(event.target.value)
                                                }}
                                            />
                                            <Button
                                                className={styles.bcpBtn}
                                                onClick={()=>{
                                                    add(lessonName, lessonsList, setAddedLessonsList)
                                                    setLessonName('')
                                                }}
                                                style={{height:'40px', width: '40px', border:'none'}}>+</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            {(addedLessonsList == lessonsList.length)?(
                                    <>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {lessonsList.map((tutorial, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.bcpBtn} style={{border:'none'}}  onClick={()=>{remove(lessonsList[i], lessonsList, setAddedLessonsList)}}><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {tutorial}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                        </div>
                        <div>
                            <table style={{width:"100%", marginTop:'10px'}}>
                                <colgroup>
                                    <col width='25%'/>
                                    <col width='75%'/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>
                                                Homework Title:
                                            </p>
                                        </td>
                                        <td style={{display:"flex", flexDirection:"row"}}>
                                            <Form.Control
                                                type="text"
                                                name="homeworkName"
                                                value={homeworkName}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setHomeworkName(event.target.value)
                                                }}
                                            />
                                            <Button
                                                className={styles.bcpBtn}
                                                onClick={()=>{
                                                    add(homeworkName, homeworkList, setHomeworkAdded)
                                                    setHomeworkName('')
                                                }}
                                                style={{height:'40px', minWidth:'40', width: '40px', border:'none'}}>+</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            {(homeworkAdded == homeworkList.length)?(
                                    <>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {homeworkList.map((hw, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.bcpBtn} style={{border:'none'}}  onClick={()=>{remove(homeworkList[i], homeworkList, setHomeworkAdded)}}><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {hw}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                        </div>
                        <Button
                            className={styles.bcpBtn}
                            style={{width:'100%', marginTop:'10px'}}
                            onClick={()=>{
                                var listedLessons = lessonsList
                                var listedHomework = homeworkList
                                var lessonComposite ={
                                    lessonsList: listedLessons,
                                    homeworkList: listedHomework
                                }
                                var counter = 0

                                if(lessonPlan.length == 0){
                                    lessonPlan.push({
                                        lessonNumber: lessonNumber,
                                        multipleLessons: [lessonComposite]
                                    })
                                    setAddedLessons(lessonPlan.length)
                                } else{
                                    lessonPlan.forEach(inquery => {
                                            if(inquery.lessonNumber == lessonNumber) {
                                                inquery.multipleLessons.push(lessonComposite)
                                                console.log(inquery)
                                            } else {
                                                counter++
                                            }
                                        })
                                        if (counter == lessonPlan.length) {
                                            lessonPlan.push({
                                                lessonNumber: lessonNumber,
                                                multipleLessons: [lessonComposite]
                                            })
                                            setAddedLessons(lessonPlan.length)
                                        }
                                }
                                //add(lessonComposite, multipleLessons, setAddedLessons)
                                setAddedLessons(lessonPlan.length)
                                console.log(lessonPlan)
                                setLessonsList([])
                                setAddedLessonsList(0)
                                setLessonName('')
                                setHomeworkList([])
                                setHomeworkAdded(0)
                                setHomeworkName('')
                            }}
                             style={{height:'40px', width:'98%', marginTop:'10px'}}>Add Lesson</Button>
                        <div>
                        {(addedLessons == lessonPlan.length)?(
                                <>
                                <table style={{width:'100%', marginTop: '10px'}}>
                                        <colgroup>
                                            <col width='5%'/>
                                            <col width='5%'/>
                                            <col width='45%'/>
                                            <col width='45%'/>
                                        </colgroup>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>Lessons</th>
                                            <th>Homeworks</th>
                                        </tr>
                                        {lessonPlan.map((teaching, i) => 
                                            <tr key={i} 
                                                style={{borderTop:'1px solid grey'}}
                                                onDragStart={(e) => dragStart(e, i)}
                                                onDragEnter={(e) => dragEnter(e, i)}
                                                onDragEnd={drop}
                                                draggable
                                            >
                            
                                                    <td style={{width:'5%', borderBottom:'1px solid gray'}}>
                                                        <Button className={styles.bcpBtn} style={{border:'none'}}  onClick={()=>{remove(lessonPlan[i], lessonPlan, setAddedLessons)}}><b>x</b></Button>
                                                    </td >
                                                    <td style={{width:'5%', borderRight:'1px solid gray', borderBottom:'1px solid gray'}}>
                                                        {i+1}
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre'}} valign='top'>
                                                        <table style={{width:'100%', height:'inherit'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                  return  <tr key={'lesson '+ i} style={{height:'100px'}}>
                                                                        <td style={{width:'100%', borderBottom:'1px solid gray'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {
                                                                                    learning.lessonsList.map((lesson, i) => {
                                                                                      return  <tr key={'miniLesson '+i}>
                                                                                            <td>{lesson}</td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre'}} valign='top'>
                                                    <table style={{width:'100%'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                    return <tr key={'homework '+i} style={{height:'100px'}}>
                                                                        <td style={{width:'100%', borderBottom:'1px solid gray'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {
                                                                                    learning.homeworkList.map((hw, i) => {
                                                                                        return <tr key={'miniHW '+i}>
                                                                                            <td>{hw}</td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                    </div>
                <div>
                </div>
                {(lessonPlan.length >= 1)? (
                            <Button 
                            className={styles.bcpBtn}
                            style={{width:'100%', marginTop:'20px'}} onClick={() => {

                                var data ={
                                    lessonName: lessonTitle,
                                    lessonType: lessonType,
                                    lesson: lessonPlan
                                }

                                addLessonPlan(data).then(() => {
                                    setConfirmation(true)
                                })
                            }}>
                                        Add Lesson to Inventory
                            </Button>
                        ):(null)                        
                        }
                        {confirmation? (
                            <p style={{margin:'10px 0px 0px 0px', textAlign:'center', color:'#D13B31'}}>Lesson Successfully Added to Inventory</p>
                        ):(null)}
                </>
    ) 
}

const ViewLesson = ({setPage, queryLesson}) => {

    const [lessonTitle, setLessonTitle] = useState(queryLesson.lessonName);
    const [lessonType, setLessonType] = useState(queryLesson.lessonType);

    const [lessonNumber, setLessonNumber] = useState('')
    const [multipleLessons, setMultipleLessons] = useState([]);
    const [lessonPlan, setLessonPlan] = useState(queryLesson.lesson);
    const [addedLessons, setAddedLessons] = useState(lessonPlan.length);
    const [lessonsList, setLessonsList] = useState([])
    const [addedLessonsList, setAddedLessonsList] = useState(0)
    const [lessonName, setLessonName] = useState('');
    const [homeworkList, setHomeworkList] = useState([]);
    const [homeworkAdded, setHomeworkAdded] = useState(0);
    const [homeworkName, setHomeworkName] = useState('');

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const drop = (e) => {
        const copyListItems = [...lessonPlan];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        copyListItems.forEach((item, i) => {
            item.lessonNumber = i+1
            console.log(lessonPlan)
        })
        setLessonPlan(copyListItems);   
      };

      function remove(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i, 1)
                console.log(list)
                countFunction(list.length)
            } else {
                console.log('nope')
            }
        })
    }

    function add(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i,1)
                countFunction(list.length);
                return false
            } else {
                return true
            }
        })
        list.push(item);
        console.log(list)
        countFunction(list.length)
    }

    var listOfRows = []


                return (
                    <>
        <div className={styles.backLink}>
                    <span onClick={()=>{setPage(null)}}><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Lesson Inventory</span>
                    </div>
        <table style={{width:'100%'}}>
            <tbody>
                <tr>
                    <td>
                        <p>Lesson Type:</p>                            
                    </td>
                    <td style={{display:'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                        <Form.Check type='radio' value='act' name='test' label='ACT' onClick={()=>{
                        setLessonType(event.target.value)
                        listOfRows.forEach(index => {
                            var inputVal = document.getElementById(index.toString());
                            inputVal.value = ''
                        })
                    }}></Form.Check>
                        <Form.Check type='radio' value='sat' name='test' label='SAT' onClick={()=>{
                        setLessonType(event.target.value)
                        listOfRows.forEach(index => {
                            var inputVal = document.getElementById(index.toString());
                            inputVal.value = ''
                        })
                    }}></Form.Check>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Lesson Plan Title:</p>
                    </td>
                    <td>
                        <Form.Control required type='name' placeholder='Lesson Plan Title' value={lessonTitle} style={{width:'100%'}} disabled></Form.Control>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style={{marginTop:'20px'}}>
                        <h4>Lessons</h4>
                            <div>
                            <table style={{width:"100%"}}>
                                <colgroup>
                                    <col width='25%'/>
                                    <col width='75%'/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Lesson Number</p>
                                        </td>
                                        <td>
                                        <Form.Control
                                                type="text"
                                                name="lessonNumber"
                                                value={lessonNumber}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setLessonNumber(event.target.value)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>
                                                Lesson Name:
                                            </p>
                                        </td>
                                        <td style={{display:"flex", flexDirection:"row"}}>
                                            <Form.Control
                                                type="text"
                                                name="lessonName"
                                                value={lessonName}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setLessonName(event.target.value)
                                                }}
                                            />
                                            <Button
                                                className={styles.bcpBtn}
                                                onClick={()=>{
                                                    add(lessonName, lessonsList, setAddedLessonsList)
                                                    setLessonName('')
                                                }}
                                                style={{height:'40px', width: '40px', border:'none'}}>+</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            {(addedLessonsList == lessonsList.length)?(
                                    <>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {lessonsList.map((tutorial, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.bcpBtn} style={{border:'none'}} onClick={()=>{remove(lessonsList[i], lessonsList, setAddedLessonsList)}}><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {tutorial}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                        </div>
                        <div>
                            <table style={{width:"100%", marginTop:'10px'}}>
                                <colgroup>
                                    <col width='25%'/>
                                    <col width='75%'/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>
                                                Homework Title:
                                            </p>
                                        </td>
                                        <td style={{display:"flex", flexDirection:"row"}}>
                                            <Form.Control
                                                type="text"
                                                name="homeworkName"
                                                value={homeworkName}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setHomeworkName(event.target.value)
                                                }}
                                            />
                                            <Button
                                                className={styles.bcpBtn}
                                                onClick={()=>{
                                                    add(homeworkName, homeworkList, setHomeworkAdded)
                                                    setHomeworkName('')
                                                }}
                                                style={{height:'40px', width: '40px', border:'none'}}>+</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            {(homeworkAdded == homeworkList.length)?(
                                    <>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {homeworkList.map((hw, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.bcpBtn} style={{border:'none'}}  onClick={()=>{remove(homeworkList[i], homeworkList, setHomeworkAdded)}}><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {hw}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                        </div>
                        <Button
                            className={styles.bcpBtn}
                            style={{width:'100%', marginTop:'10px'}}
                            onClick={()=>{
                                var listedLessons = lessonsList
                                var listedHomework = homeworkList
                                var lessonComposite ={
                                    lessonsList: listedLessons,
                                    homeworkList: listedHomework
                                }
                                var counter = 0

                                if(lessonPlan.length == 0){
                                    lessonPlan.push({
                                        lessonNumber: lessonNumber,
                                        multipleLessons: [lessonComposite]
                                    })
                                    setAddedLessons(lessonPlan.length)
                                } else{
                                    lessonPlan.forEach(inquery => {
                                            if(inquery.lessonNumber == lessonNumber) {
                                                inquery.multipleLessons.push(lessonComposite)
                                                console.log(inquery)
                                            } else {
                                                counter++
                                            }
                                        })
                                        if (counter == lessonPlan.length) {
                                            lessonPlan.push({
                                                lessonNumber: lessonNumber,
                                                multipleLessons: [lessonComposite]
                                            })
                                            setAddedLessons(lessonPlan.length)
                                        }
                                }
                                //add(lessonComposite, multipleLessons, setAddedLessons)
                                setAddedLessons(lessonPlan.length)
                                console.log(lessonPlan)
                                setLessonsList([])
                                setAddedLessonsList(0)
                                setLessonName('')
                                setHomeworkList([])
                                setHomeworkAdded(0)
                                setHomeworkName('')
                            }}
                            style={{height:'40px', width:'98%', marginTop:'10px'}}>Add Lesson</Button>
                        <div>
                        {(addedLessons == lessonPlan.length)?(
                                <>
                                <table style={{width:'100%', marginTop: '10px'}}>
                                        <colgroup>
                                            <col width='5%'/>
                                            <col width='5%'/>
                                            <col width='45%'/>
                                            <col width='45%'/>
                                        </colgroup>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>Lessons</th>
                                            <th>Homeworks</th>
                                        </tr>
                                        {lessonPlan.map((teaching, i) => 
                                            <tr key={i} 
                                                style={{borderTop:'1px solid grey', borderBottom:'2px solid grey'}}
                                                onDragStart={(e) => dragStart(e, i)}
                                                onDragEnter={(e) => dragEnter(e, i)}
                                                onDragEnd={drop}
                                                draggable
                                            >
                            
                                                    <td style={{width:'5%', borderBottom:'1px solid gray'}}>
                                                        <Button className={styles.bcpBtn} style={{border:'none'}}  onClick={()=>{remove(lessonPlan[i], lessonPlan, setAddedLessons)}}><b>x</b></Button>
                                                    </td >
                                                    <td style={{width:'5%', borderRight:'1px solid gray', borderBottom:'1px solid gray'}}>
                                                        {i+1}
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre', borderRight:'2px solid gray'}} valign='top'>
                                                        <table style={{width:'100%', height:'inherit'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                  return  <tr key={'lesson '+ i} style={{height:'100px'}}>
                                                                        <td style={{width:'100%', borderBottom:'1px solid gray'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {
                                                                                    learning.lessonsList.map((lesson, i) => {
                                                                                      return  <tr key={'miniLesson '+i}>
                                                                                            <td>{lesson}</td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre'}} valign='top'>
                                                    <table style={{width:'100%'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                    return <tr key={'homework '+i} style={{height:'100px'}}>
                                                                        <td style={{width:'100%', borderBottom:'1px solid gray'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {
                                                                                    learning.homeworkList.map((hw, i) => {
                                                                                        return <tr key={'miniHW '+i}>
                                                                                            <td>{hw}</td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                    </div>
                <div>
                </div>
                {lessonType? (
                            <Button 
                            className={styles.bcpBtn}
                            style={{width:'100%', marginTop:'20px'}} onClick={() => {

                                var data ={
                                    lessonName: lessonTitle,
                                    lessonType: lessonType,
                                    lesson: lessonPlan
                                }

                                updateLesson(queryLesson._id, data)
                            }}>
                                        Finish Edit
                            </Button>
                        ):(null)                        
                        }
                </>
                ) 
        }