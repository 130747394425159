import React, {useState, useEffect, useRef} from 'react'
import 'react-resizable/css/styles.css';
import styles from './Students.module.css';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'gatsby'
import {FaArrowLeft, FaTrash } from 'react-icons/fa';
import { HiArrowNarrowRight } from 'react-icons/hi'

import page1 from '../../../images/RecapPDFImages-01.png'
import page2 from '../../../images/RecapPDFImages-02.png'
import page4 from '../../../images/RecapPDFImages-04.png'
import page5 from '../../../images/RecapPDFImages-05.png'
import page6 from '../../../images/RecapPDFImages-06.png'
import page8 from '../../../images/RecapPDFImages-08.png'
import page10 from '../../../images/RecapPDFImages-10.png'
import page12 from '../../../images/RecapPDFImages-12.png'
import page13 from '../../../images/RecapPDFImages-13.png'
import page19 from '../../../images/RecapPDFImages-19.png'
import yellowBubble from '../../../images/Bckground-09.png'
import billingPolicy from '../../../images/Bckground-18.png'

import { 
    sendRecap,
    saveRecapDraft,
    getAllDrafts,
    getDiagnostics,
    getTestInventory,
    getLessonInventory,
    deleteDraft,
    updateDraft
} from '../../../api/api'
import moment from 'moment'
import Form from 'react-bootstrap/Form'

const VERTICAL = 750;
const MOBILE_SIZE = 1200; // Width in which screen is too small to display portal
const DIAG_SEARCH_FIELDS = ['studentName', 'testType', 'school', 'parentName', 'parentEmail'] // For filter
const DIAG_SCORE_HEADINGS = ['sr' ,'sg', 'sm','st','ag','am', 'ar','as','at']

const SendRecap = () => {
    const [mobileDisplay, setMobileDisplay] = useState(false);
    const [studentName, setStudentName] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [testType, setTestType] = useState('');

    const [ag, setAg] = useState('');
    const [am, setAm] = useState('');
    const [ar, setAr] = useState('');
    const [as, setAs] = useState('');
    const [at, setAt] = useState('');

    const [satR, setSatR] = useState('');
    const [satG, setSatG] = useState('');
    const [satM, setSatM] = useState('');
    const [satT, setSatT] = useState('');

    const [examsToRegister, setExams] = useState([]);
    const [addedExams, setAddedExams] = useState(0);
    const [examName, setExamName] = useState('');
    const [scoreRange, setScoreRange] = useState('');

    const [tutorName, setTutorName] = useState('Name of Tutor');
    const [tutoringType, setTutoringType] = useState('Select Tutoring Type')
    const [tutoringHours, setTutoringHours] = useState('');
    const [studySessionHours, setStudySessionHours] = useState('');

    const [topicsDiscussed, setTopicsDiscussed] = useState([]);
    const [addedTopics, setAddedTopics] = useState(0)
    const [topic, setTopic] = useState('')

    const [collegesOfInterest, setCollegesOfInterest] = useState([]);
    const [addedColleges, setAddedColleges] = useState(0)
    const [college, setCollege] = useState('');

    const [multipleDeficiencies, setMultipleDeficiencies] = useState([]);
    const [addedDeficiencies, setAddedDeficiencies] = useState(0);
    const [deficiency, setDeficiency] = useState('');

    const [multipleStrengths, setMultipleStrengths] = useState([]);
    const [addedStrengths, setAddedStrengths] = useState(0);
    const [strength, setStrength] = useState('');

    const [lessonInventory, setLessonInventory] = useState(null)
    const [selectedLessonId, setSelectedLessonId] = useState(null)
    const [lessonNumber, setLessonNumber] = useState('');
    const [addedLessons, setAddedLessons] = useState(0);
    const [lesson, setLesson] = useState([]);
    const [lessonsList, setLessonsList] = useState([])
    const [addedLessonsList, setAddedLessonsList] = useState(0)
    const [lessonName, setLessonName] = useState('');
    const [homeworkList, setHomeworkList] = useState([]);
    const [homeworkAdded, setHomeworkAdded] = useState(0);
    const [homeworkName, setHomeworkName] = useState('');

    const [notes, setNotes] = useState('');

    const [drafts, setDrafts] = useState([]);

    const [selectedId, setSelectedId] = useState(null);

    const [diagnostics, setDiagnostics] = useState([]);

    const [isVertical, setVertical] = useState(false);

    const [examInventory, setExamInventory] = useState(null)
    const [selectedExamId, setSelectedExamId] = useState(null)
    const [prefilledDeficiencies, setPrefilledDeficiencies] = useState([])

    const dragItem = useRef();
    const dragOverItem = useRef();

    const [hidden, setHidden] = useState(true)
    
    const [defAndStrFontSize, setDefAndStrFontSize] = useState(12)
    const [lPlanFontSize, setLPlanFontSize] = useState(12)

    const [tpcFontSize, setTpcFontSize] = useState(12)
    const [coiFontSize, setCoiFontSize] = useState(12)
    const [eisFontSize, setEisFontSize] = useState(12)
    const [tInfoFontSize, setTInfoFontSize] = useState(12)

    const [tpcFontSizeT, setTpcFontSizeT] = useState(25)
    const [coiFontSizeT, setCoiFontSizeT] = useState(25)
    const [eisFontSizeT, setEisFontSizeT] = useState(25)
    const [tInfoFontSizeT, setTInfoFontSizeT] = useState(25)

    const [tpcWidth, setTpcWidth] = useState(75)
    const [coiWidth, setCoiWidth] = useState(50)
    const [eisWidth, setEisWidth] = useState(50)
    const [tInfoWidth, setTInfoWidth] = useState(70)

    const [tab1Name, setTab1Name] = useState('')
    const [tab2Name, setTab2Name] = useState('')
    const [tab3Name, setTab3Name] = useState('')

    const [confirmation, setConfirmation] = useState(null)

    const [deleteQuery, setDeleteQuery] = useState(null)

    const [draftId, setDraftId] = useState(null)

    const [totalCharCount, setTotalCharCount] = useState(0)
    var charCount = 0;
    const[pageOverflow, setPageOverflow] = useState(false)

    useEffect(() => {
        setDisplayVertical();
        // Get users from API
        getAllDrafts().then(res => {
            setDrafts(res.data);
        })
    }, []);

    useEffect(() => {
        getTestInventory().then(res => {
            setExamInventory(res.data)
            console.log(res.data)
        })
    }, [])

    useEffect(() => {
        getLessonInventory().then(res => {
            setLessonInventory(res.data)
            console.log(res.data)
        })
    }, [])

    useEffect(()=> {
        getAllTests()
    }, [])

    function hideTab(hidden) {
        if(hidden) {
            return 'none'
        } else {
            return 'flex'
        }
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const drop = (e) => {
        const copyListItems = [...lesson];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        copyListItems.forEach((item, i) => {
            item.lessonNumber = i+1
            console.log(lesson)
        })
        setLesson(copyListItems);   
      };

    const getDateFromID = (id) => {
        let foundDiagnostic = diagnostics.find(item => item._id === id)
        if(foundDiagnostic){
            console.log(foundDiagnostic.date.start)
            return foundDiagnostic.date.start
        }
        else{
            return null
        }
    }

    function alternateColors(i) {
        if((i+1)%2 == 0) {
            return '#F6E8E1'
        } else {
            return '#FFFFFF'
        }
    }

    function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    function getAllTests() {
        getDiagnostics().then(diagRes => {
            if(diagRes.data?.length > 0) setDiagnostics(diagRes.data)
            else setDiagnostics([])
            //newFilters.Diagnostics = true;
        })
    }

    function displayDrafts(sentState) {
        function getCharCount (list) {
            var letterCounter = 0
            list.forEach((topic) => {
                if (topic.length < 124) {
                    letterCounter = letterCounter+124
                } else {
                    letterCounter = letterCounter+topic.length
                }
            })
            setTotalCharCount(letterCounter)
        }
        return drafts ? drafts.map((draft, index) => {
            if(sentState == draft.sent) {
                return (
                    <div key={index} style={{display:'flex', flexDirection:'row'}}>
                    <div style={{width:'95%'}} key={index} className={styles.historyItem} onClick={() => {
                        setDraftId(draft._id)
                        setStudentName(draft.studentName)
                        setRecipientEmail(draft.recipientEmail)
                        setTestType(draft.testType)
    
                        setTopicsDiscussed(draft.topicsDiscussed)
                        setAddedTopics(draft.topicsDiscussed.length)
                        getCharCount(draft.topicsDiscussed)
    
                        setCollegesOfInterest(draft.collegesOfInterest)
                        setAddedColleges(draft.collegesOfInterest.length)
    
                        setAg(draft.ag)
                        setAm(draft.am)
                        setAr(draft.ar)
                        setAs(draft.as)
                        setAt(draft.at)
    
                        setSatG(draft.satG)
                        setSatM(draft.satM)
                        setSatR(draft.satR)
                        setSatT(draft.satT)
    
                        setExams(draft.examsToRegister)
                        setAddedExams(draft.examsToRegister.length)
    
                        setTutorName(draft.tutorName)
                        setTutoringHours(draft.tutoringHours)
                        setStudySessionHours(draft.studySessionHours)
    
                        setMultipleDeficiencies(draft.multipleDeficiencies)
                        setAddedDeficiencies(draft.multipleDeficiencies.length)
    
                        setMultipleStrengths(draft.multipleStrengths)
                        setAddedStrengths(draft.multipleStrengths.length)
    
                        setLesson(draft.lesson)
                        setAddedLessons(draft.lesson.length)
                    }}>
                         <Row style={{width:'99%'}}>
                             {sentState? (
                                 <>
                                 <Col lg={8}>{draft.studentName}</Col>
                                 <Col as={"b"}>{draft.date}</Col>
                                 </>
                             ): (
                                 <>
                                <Col lg={8}>{draft.studentName}</Col>
                                <Col as={"b"}>{draft.testType.toUpperCase()}</Col>
                                </>
                             )}
                        </Row>
                        </div>
                        {!sentState?(
                        <div style={{marginLeft:'10px', transform:'translateY(-4px)'}} className={styles.historyItem} onClick={()=> {
                            setDeleteQuery(draft)
                            setTimeout(()=>{
                                setConfirmation('delete')
                            }, 100)
                            console.log('clicked')
                        }}>
                            <FaTrash style={{marginTop:'3px'}}/>
                        </div>)
                        :(null)
                        }
                    </div>
                   
                )
            }
        }) : [];
    }

    function textFill () {
        if (testType == 'act') {
            return 'SAT'
        } else {
            return 'ACT'
        }
    }

    function remove(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i, 1)
                console.log(list)
                countFunction(list.length)
            }
        })
    }

    function add(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i,1)
                countFunction(list.length);
                return false
            } else {
                return true
            }
        })
        list.push(item);
        console.log(list)
        countFunction(list.length)
    }

    function findStudent(fullName) {
        diagnostics.forEach((diagnostic) => {
            if (diagnostic._id == selectedId) {
                console.log(diagnostic)
                diagnostic.studentRequests.forEach((student) => {
                    if(student.studentName == fullName) {
                        setRecipientEmail(student.parentEmail)
                        setTestType(student.testType.toUpperCase())
                        setAg(student.ag)
                        setAm(student.am)
                        setAr(student.ar)
                        setAs(student.as)
                        setAt(student.at)
                        setSatR(student.sr)
                        setSatG(student.sg)
                        setSatM(student.sm)
                        setSatT(student.st)
                        console.log(student)
                        //Set value to all the variables
                    }
                })
            }
        })
    }

    function prefillExamAttributes() {
        examInventory.forEach((exam) => {
            if (exam._id == selectedExamId) {
                setMultipleStrengths(exam.multipleDeficiencies)
                setPrefilledDeficiencies(exam.multipleDeficiencies.join('|'))
                setAddedStrengths(multipleStrengths.length)
            }
        })
    }

    function prefillLessonAttributes() {
        lessonInventory.forEach((lesson) => {
            if (lesson._id == selectedLessonId) {
                setLesson(lesson.lesson)
                setAddedLessons(lesson.lesson.length)
            }
        })
    }

    var listOfRows = []
    function generateDeficiencyInputs(test) {
        if(test == 'act') {
            listOfRows=[]
            for(var i=0; i<60; i++) {
                listOfRows.push(
                    i+1
                )
            }
        }else if(test=='sat') {
            listOfRows=[]
            for(var o=0; o<58; o++) {
                listOfRows.push(
                    o+1
                )
            }
        }
    }

    function greaterLength(l1, l2) {
        if(l1.length >= l2.length) {
            return l1
        } else {
            return l2
        }
    }

    function totalRowSpan(i) {
        var counter = 0
        lesson[i].multipleLessons.forEach(item => {
            if(item.lessonsList.length > item.homeworkList.length) {
                item.lessonsList.forEach(count => {
                    counter++
                })
            } else {
                item.homeworkList.forEach(count => {
                    counter++
                })
            }
        })
        return counter
    }

    function checkOverflow() {
        var consultationPage = document.getElementById('consultationPage');
        console.log(consultationPage.scrollHeight)
        console.log(consultationPage.clientHeight)
        if (consultationPage.scrollHeight > consultationPage.clientHeight) {
            setPageOverflow(true);
        } else {
            setPageOverflow(false);
        }
        console.log(totalCharCount)
    }

    // function sendRecapEmail() {
    //     var data = {}
    //         data = {
    //             studentName: studentName,
    //             recipientEmail: recipientEmail,
    //             testType: testType,
    //             ag: ag,
    //             am: am,
    //             ar: ar,
    //             as: as,
    //             at: at,
    //             satR: satR,
    //             satG: satG,
    //             satM: satM,
    //             satT: satT,
    //             examsToRegister: examsToRegister,
    //             tutorName: tutorName,
    //             tutoringHours: tutoringHours,
    //             studySessionHours: studySessionHours,
    //             topicsDiscussed: topicsDiscussed,
    //             collegesOfInterest: collegesOfInterest,
    //             multipleStrengths: multipleStrengths,
    //             multipleDeficiencies: multipleDeficiencies,
    //             lesson: lesson
    //         }
        
    //     sendRecap(data)
    // }

    function logRecapData() {
        var data = {}
            data = {
                date: new Date().toLocaleString('en-US', { timeZone: 'EST' }),
                sent: true,
                studentName: studentName,
                recipientEmail: recipientEmail,
                testType: testType,
                ag: ag,
                am: am,
                ar: ar,
                as: as,
                at: at,
                satR: satR,
                satG: satG,
                satM: satM,
                satT: satT,
                examsToRegister: examsToRegister,
                tutorName: tutorName,
                tutoringHours: tutoringHours,
                studySessionHours: studySessionHours,
                topicsDiscussed: topicsDiscussed,
                collegesOfInterest: collegesOfInterest,
                multipleStrengths: multipleStrengths,
                multipleDeficiencies: multipleDeficiencies,
                lesson: lesson
            }
        
        if (draftId) {
            updateDraft(draftId, data).then(()=>{
                getAllDrafts().then(res => {
                    setDrafts(res.data);
                })
            })
        } else {
            saveRecapDraft(data).then(()=>{
                getAllDrafts().then(res => {
                    setDrafts(res.data);
                })
            })
        }
    }

    function saveDraft() {
        var data = {}
            data = {
                date: new Date().toLocaleString('en-US', { timeZone: 'EST' }),
                sent: false,
                studentName: studentName,
                recipientEmail: recipientEmail,
                testType: testType,
                ag: ag,
                am: am,
                ar: ar,
                as: as,
                at: at,
                satR: satR,
                satG: satG,
                satM: satM,
                satT: satT,
                examsToRegister: examsToRegister,
                tutorName: tutorName,
                tutoringHours: tutoringHours,
                studySessionHours: studySessionHours,
                topicsDiscussed: topicsDiscussed,
                collegesOfInterest: collegesOfInterest,
                multipleStrengths: multipleStrengths,
                multipleDeficiencies: multipleDeficiencies,
                lesson: lesson
            }
        
            if (draftId) {
                updateDraft(draftId, data).then(()=>{
                    getAllDrafts().then(res => {
                        setDrafts(res.data);
                    })
                })
            } else {
                saveRecapDraft(data).then(()=>{
                    getAllDrafts().then(res => {
                        setDrafts(res.data);
                    })
                })
            }
    }

    function satCount(index, testType) {
        if(testType == 'sat') {
            if (index > 20) {
                return (index-20)
            } else {
                return index
            }
        } else {
            return index
        } 
    }

    function addScript(url) {
        var script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = url;
        document.head.appendChild(script);
    }

    addScript('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js');

    return(
        mobileDisplay? 
            <div style={{width: '100%', height: '100vh', color: 'grey', fontSize: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Please Enlarge the Screen to View Report
            </div>
        :
        <>
        {confirmation? (
            <>
                <div style={{position:'absolute', backgroundColor:'black', opacity:'0.2', width:'100%', height:'100%',zIndex:'150'}}>
                    
                </div>
                <div style={{position:'absolute', backgroundColor:'white', width:'500px', height:'400px', zIndex:'200', opacity:'1', top:'25vh', left:'37vw', borderRadius:'5%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', boxShadow:'5px 10px'}}>
                    { (confirmation == 'draft')?(
                        <>
                            <h2 style={{color:'black', textAlign:'center'}}><b>{studentName}</b><br></br><br></br> has been saved to your Drafts</h2>
                            <br></br>
                            <br></br>
                            <Button className={styles.xBtn} style={{border:'2px solid #D13B31', borderRadius:'5px'}}
                                onClick ={()=> {
                                    setConfirmation(null)
                                }}
                            >OK</Button>
                        </>
                    ):(confirmation == 'generatingPDF')?(
                        <>
                            <h2 style={{color:'black', textAlign:'center'}}>Creating PDF...</h2>
                        </>
                    ):(confirmation == 'generatedPDF')?(
                        <>
                            <h2 style={{color:'black', textAlign:'center'}}>PDF Successfully Created</h2>
                            <br></br>
                            <br></br>
                            <Button className={styles.xBtn} style={{border:'2px solid #D13B31', borderRadius:'5px'}}
                                onClick ={()=> {
                                    setConfirmation(null)
                                }}
                            >OK</Button>
                        </>
                    ):(confirmation == 'delete')?(
                        <>
                            <h2 style={{color:'black', textAlign:'center'}}>Are you sure you want to delete<br></br><br></br> <b>{deleteQuery.studentName}</b></h2><br></br><br></br>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <Button className={styles.xBtn} style={{border:'2px solid #D13B31', borderRadius:'5px'}}
                                    onClick ={()=> {
                                        setConfirmation(null)
                                    }}
                                >No</Button>
                                <Button className={styles.xBtn} style={{border:'2px solid #D13B31', borderRadius:'5px'}}
                                    onClick ={()=> {
                                        console.log(deleteQuery._id)
                                        deleteDraft(deleteQuery._id).then(()=>{
                                            getAllDrafts().then(res => {
                                                setDrafts(res.data);
                                                setConfirmation(null);
                                            })
                                        })
                                    }}
                                >Yes</Button>
                            </div>
                        </>
                    ):(
                        <>
                        <h2 style={{color:'black', textAlign:'center'}}>Unexpected Error Encountered</h2>
                        <br></br>
                            <br></br>
                            <Button className={styles.xBtn} style={{border:'2px solid #D13B31', borderRadius:'5px'}}
                                onClick ={()=> {
                                    setConfirmation(null)
                                }}
                            >OK</Button>
                        </>
                    )
                    }
                </div>
            </>
            ):(null)
        }
        <div style={{height: '100vh', overflow: 'hidden'}} className={`d-flex flex-column align-items-center justify-content-center`}>
            <div style={{width: '100%'}}>
                <div className={`${styles.listHeading}`}>
                    <div className={styles.listNav}>
                        <div className={styles.homeLink}>
                            <Link to="/portal/home"><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Home</Link>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <h4 
                            style={{marginBottom: '0px', fontSize: '30px'}}
                        >
                            Consultation Reports
                        </h4>
                    </div>
                    <div className={styles.listNav}>
                    </div>
                </div>
            </div>
            <section style={{width:'100%',height:'95%', display:'flex',flexDirection:'row', margin:'0'}}>
            <div style={{width:'15%', backgroundColor:'white', display:'block'}}>
                            <Form.Control 
                                onChange={(e) => {setSelectedId(e.target.value)}} 
                                defaultValue='' 
                                id={`type`} 
                                as="select" 
                                required
                                style={{width:'90%', marginLeft:'5%'}}
                            >
                                <option value='' disabled>Select Diagnostic</option>
                                {diagnostics?.slice().map((item, i) => {
                                    let start = new Date(item.date.start);
                                    let end = new Date(item.date.end);
                                        return(
                                        <option value={item._id} key={i}>
                                            {isVertical?
                                                    `${item.name}
                                                    
                                                    ${start.toString().slice(0,4)} ${end.toLocaleDateString()} 
                                                    ${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`
                                                :
                                                    `Exam: ${item.name}\xa0\xa0 
                                                    Day: ${moment(start).format('dddd')}\xa0\xa0 
                                                    Date: ${end.toLocaleDateString()}\xa0\xa0 
                                                    Time: ${moment(start).format('h:mm A')} - ${moment(end).format('h:mm A')}`
                                                }
                                        </option>
                                        )
                                })}
                            </Form.Control>
                <div style={{width:'90%', marginTop:'20px', marginLeft:'5%', display:'flex', flexDirection:'row'}}>
                <Form.Control
                    type="text"
                    placeholder="Search for Student"
                    name='filter'
                    value= {studentName}
                    onChange={() => setStudentName(event.target.value)}
                    className={styles.searchBar}
                    disabled={false}  
                    style={{marginRight:'5px'}}
                />
                {selectedId? (
                    <Button className={styles.submitBtn} style={{height:'40px', backgroundColor:'#df2019', borderColor:'#df2019'}} onClick={()=>{findStudent(studentName)}}><HiArrowNarrowRight/></Button>
                ):(
                    <Button className={styles.submitBtn} style={{height:'40px', backgroundColor:'#df2019', borderColor:'#df2019'}} disabled><HiArrowNarrowRight/></Button>
                )}
                </div>
                <h3 style={{marginLeft:'5%', marginTop:'20px'}}>Sent History</h3>
                <div style={{width:'90%', marginLeft:'5%', height:'35%', overflowY:'auto'}}>
                    {displayDrafts(true)}
                </div>
                <h3 style={{marginLeft:'5%', marginTop:'20px'}}>Draft History</h3>
                <div style={{width:'90%', marginLeft:'5%', height:'40%', overflowY:'auto'}}>
                    {displayDrafts(false)}
                </div>
            </div>
            <div style={{width:'39.5%',backgroundColor:'white', overflow:'auto', minWidth:'33%'}}>
            <Form style={{marginRight:'10px',paddingBottom:'50px', height:'100%'}}>
                <h3>Fill Out Consultation Information</h3>
                <table style={{width:'100%'}}>
                    <tbody>
                        {draftId? (
                            <>
                            <tr>
                                <td colSpan='2'><p>Refresh Page to Clear Selection</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Draft Id:</p>
                                </td>
                                <td>
                                    <p>{draftId}</p>
                                </td>
                            </tr>
                            </>
                        ):(null)
                        }
                    <tr>
                        <td>
                            <p>Student's Name:</p>
                        </td>
                        <td style={{width:'70%'}}>
                            <Form.Control
                                type="text"
                                name='studentName'
                                value={studentName}
                                onChange ={()=>{
                                    setStudentName(event.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Recipient's Email:</p>
                        </td>
                        <td style={{width:'70%'}}>
                            <Form.Control
                                type="text"
                                name='email'
                                value={recipientEmail}
                                onChange ={()=>{
                                    setRecipientEmail(event.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr style={{height:'30px', marginBottom:'10px'}}>
                        <td>
                            <p style={{marginBottom:'0px'}}>Exam Type:</p>
                        </td>
                        <td style={{width:'70%', display:'flex', flexDirection:'row', justifyContent:'space-evenly', margin:'0'}}>
                            <Form.Check
                            type='radio'
                            name='testType'
                            value='act'
                            label='ACT'
                            onClick={()=>{
                                setTestType(event.target.value)
                            }}
                            style={{width:'50%', flexGrow:'1'}}
                            ></Form.Check>
                            <Form.Check
                            type='radio'
                            name='testType'
                            value='sat'
                            label='SAT'
                            onClick={()=>{
                                setTestType(event.target.value)
                            }}
                            style={{width:'50%', flexGrow:'1'}}
                            ></Form.Check>
                        </td>
                    </tr>
                    <tr>
                        <td>
                           <p>Exam Name: </p>
                        </td>
                        <td style={{display:'flex', flexDirection:'row'}}>
                        <Form.Control 
                                onChange={(e) => {setSelectedExamId(e.target.value)}} 
                                defaultValue='' 
                                id={`type`} 
                                as="select" 
                                required
                                style={{width:'90%', marginRight:'10px'}}
                            >
                                <option value='' disabled>Search Exam Inventory</option>
                                {examInventory?.slice().map((item, i) => {
                                        if (item.testType == testType) {
                                            return(
                                                <option value={item._id} key={i}>
                                                    {isVertical?
                                                            `${item.testName}`
                                                        :
                                                            `Exam: ${item.testName}\xa0\xa0`
                                                        }
                                                </option>
                                                )
                                        }
                                })}
                            </Form.Control>
                            <Button className={styles.submitBtn} style={{height:'40px'}} onClick={()=> prefillExamAttributes()}>
                               <b>Prefill</b> 
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {testType? (
                    <>
                    <div style={{marginTop:'20px'}}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>Topics Discussed in Meeting</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Width:</p>
                            <Form.Control
                                type='text'
                                value={tpcWidth}
                                style={{height:'60px', width:'30px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setTpcWidth(event.target.value)
                                }}
                                >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px', color:'black'}}>%</p>
                            <Form.Control
                                type='range'
                                min="1" 
                                max="100"
                                value={tpcWidth}
                                style={{width:'100px', marginRight:'10px'}}
                                onChange={()=>{
                                    setTpcWidth(event.target.value)
                                }}
                                >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Title:</p>
                            <Form.Control
                                type='text'
                                value={tpcFontSizeT}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setTpcFontSizeT(event.target.value)
                                }}
                            ></Form.Control>
                            <div style={{display:'flex', flexDirection:'column', marginRight:'10px'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTpcFontSizeT(tpcFontSizeT+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTpcFontSizeT(tpcFontSizeT-1)
                                }}>\/</Button>
                            </div>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Content:</p>
                            <Form.Control
                                type='text'
                                value={tpcFontSize}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setTpcFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTpcFontSize(tpcFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTpcFontSize(tpcFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                        <table style={{width:"100%"}}>
                            <colgroup>
                                <col width='25%'/>
                                <col width='75%'/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            Topic:
                                        </p>
                                    </td>
                                    <td style={{display:"flex", flexDirection:"row"}}>
                                        <Form.Control
                                            type="text"
                                            name="topic"
                                            value={topic}
                                            style={{marginRight:"5px"}}
                                            onChange={()=>{
                                                setTopic(event.target.value)
                                            }}
                                        />
                                        <Button
                                            onClick={()=>{
                                                add(topic, topicsDiscussed, setAddedTopics)
                                                if (topic.length < 124) {
                                                    setTotalCharCount(totalCharCount+124)
                                                } else {
                                                    setTotalCharCount(totalCharCount+topic.length)
                                                }
                                                setTimeout(()=>{
                                                    checkOverflow()
                                                },100)
                                                setTopic('')
                                            }}
                                            className={styles.submitBtn} style={{height:'40px', width: '40px'}} >+</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                        {(addedTopics == topicsDiscussed.length)?(
                                <>
                                <table style={{width:'100%'}}>
                                    <tbody>
                                        {topicsDiscussed.map((subject, i) => 
                                            <tr key={i}>
                                                    <td style={{width:'10%'}}>
                                                        <Button className={styles.xBtn} 
                                                        onClick={()=>{
                                                            if (topicsDiscussed[i].length < 124) {
                                                                setTotalCharCount(totalCharCount-124)
                                                            } else {
                                                                setTotalCharCount(totalCharCount-topicsDiscussed[i].length)
                                                            }
                                                            remove(topicsDiscussed[i], topicsDiscussed, setAddedTopics)
                                                            setTimeout(()=>{
                                                                checkOverflow()
                                                            },100)
                                                            }}><b>x</b></Button>
                                                    </td >
                                                    <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                    {subject}
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                    </div>

                    <div style={{marginTop:'20px'}}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>Colleges Of Interest</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Width:</p>
                            <Form.Control
                                type='text'
                                value={coiWidth}
                                style={{height:'60px', width:'30px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setCoiWidth(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px', color:'black'}}>%</p>
                            <Form.Control
                                type='range'
                                min="1" 
                                max="100" 
                                value={coiWidth}
                                style={{width:'100px', marginRight:'10px'}}
                                onChange={()=>{
                                    setCoiWidth(event.target.value)
                                }}
                                ></Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Title:</p>
                            <Form.Control
                                type='text'
                                value={coiFontSizeT}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChnage={()=>{
                                    setCoiFontSizeT(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column', marginRight:'10px'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setCoiFontSizeT(coiFontSizeT+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setCoiFontSizeT(coiFontSizeT-1)
                                }}>\/</Button>
                            </div>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Content:</p>
                            <Form.Control
                                type='text'
                                value={coiFontSize}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setCoiFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setCoiFontSize(coiFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setCoiFontSize(coiFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                        <table style={{width:"100%"}}>
                            <colgroup>
                                <col width='25%'/>
                                <col width='75%'/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            College Name:
                                        </p>
                                    </td>
                                    <td style={{display:"flex", flexDirection:"row"}}>
                                        <Form.Control
                                            type="text"
                                            name="college"
                                            value={college}
                                            style={{marginRight:"5px"}}
                                            onChange={()=>{
                                                setCollege(event.target.value)
                                            }}
                                        />
                                        <Button
                                            onClick={()=>{
                                                add(college, collegesOfInterest, setAddedColleges)
                                                setCollege('')
                                            }}

                                            className={styles.submitBtn} style={{height:'40px', width: '40px'}}
                                        >+</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                        {(addedColleges == collegesOfInterest.length)?(
                                <>
                                <table style={{width:'100%'}}>
                                    <tbody>
                                        {collegesOfInterest.map((college, i) => 
                                            <tr key={i}>
                                                    <td style={{width:'10%'}}>
                                                        <Button className={styles.xBtn} onClick={()=>{remove(collegesOfInterest[i], collegesOfInterest, setAddedColleges)}}><b>x</b></Button>
                                                    </td >
                                                    <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                    {college}
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                    </div>

                    {(testType == 'act')? (
                        <>
                        <div style={{marginTop:'20px'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>ACT Initial Score</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Width:</p>
                            <Form.Control
                                type='text'
                                value={eisWidth}
                                style={{height:'60px', width:'30px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setEisWidth(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px', color:'black'}}>%</p>
                            <Form.Control
                                type='range'
                                min="1" 
                                max="100" 
                                value={eisWidth}
                                style={{width:'100px', marginRight:'10px'}}
                                onChange={()=>{
                                    setEisWidth(event.target.value)
                                }}
                                ></Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Title:</p>
                            <Form.Control
                                type='text'
                                value={eisFontSizeT}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setEisFontSizeT(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column', marginRight:'10px'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSizeT(eisFontSizeT+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSizeT(eisFontSizeT-1)
                                }}>\/</Button>
                            </div>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Content:</p>
                            <Form.Control
                                type='text'
                                value={eisFontSize}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setEisFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSize(eisFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSize(eisFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                            <table style={{width:"100%"}}>
                                <colgroup>
                                 <col width='25%'/>
                                 <col width='75%'/>
                                </colgroup>
                                <tbody>
                            <tr>
                                 <td><p>ACT Grammar:</p></td>
                                 <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                     type="text"
                                     placeholder=""
                                     name='ag'
                                     value= {ag}
                                     className={styles.searchBar}
                                     onChange={()=> {
                                         setAg(event.target.value)
                                     }} 
                                     style={{width:'45px', marginRight:'10px'}}
                                 /><p>/36</p></td>
                            </tr>
                            <tr>
                                 <td><p>ACT Math:</p></td>
                                 <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                     type="text"
                                     placeholder=""
                                     name='am'
                                     value= {am}
                                     className={styles.searchBar}
                                     onChange={()=> {
                                         setAm(event.target.value)
                                     }}
                                     style={{width:'45px', marginRight:'10px'}}
                                 /><p>/36</p></td>
                            </tr>
                            <tr>
                                 <td><p>ACT Reading:</p></td>
                                 <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                     type="text"
                                     placeholder=""
                                     name='ar'
                                     value= {ar}
                                     className={styles.searchBar}
                                     onChange={()=> {
                                         setAr(event.target.value)
                                     }}
                                     style={{width:'45px', marginRight:'10px'}}
                                 /><p>/36</p></td>
                            </tr>
                            <tr>
                                 <td><p>ACT Science:</p></td>
                                 <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                     type="text"
                                     placeholder=""
                                     name='as'
                                     value= {as}
                                     className={styles.searchBar}
                                     onChange={()=> {
                                         setAs(event.target.value)
                                     }}
                                     style={{width:'45px', marginRight:'10px'}}
                                 /><p>/36</p></td>
                            </tr>
                            <tr>
                             <td><p>ACT Total:</p></td>
                             <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                     type="text"
                                     placeholder=""
                                     name='at'
                                     value= {at}
                                     className={styles.searchBar}
                                     onChange={()=> {
                                         setAt(event.target.value)
                                     }}  
                                     style={{width:'45px', marginRight:'10px'}}
                                 /><p>/36</p></td>
                           </tr>
                           </tbody>
                            </table>
                         </div>
                         </>
                    ):(testType == 'sat')? (
                        <>
                        <div style={{marginTop:'20px'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>SAT Initial Score</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Width:</p>
                            <Form.Control
                                type='text'
                                value={eisWidth}
                                style={{height:'60px', width:'30px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setEisWidth(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px', color:'black'}}>%</p>
                            <Form.Control
                                type='range'
                                min="1" 
                                max="100" 
                                value={eisWidth}
                                style={{width:'100px', marginRight:'10px'}}
                                onChange={()=>{
                                    setEisWidth(event.target.value)
                                }}
                                ></Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Title:</p>
                            <Form.Control
                                type='text'
                                value={eisFontSizeT}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setEisFontSizeT(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column', marginRight:'10px'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSizeT(eisFontSizeT+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSizeT(eisFontSizeT-1)
                                }}>\/</Button>
                            </div>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Content:</p>
                            <Form.Control
                                type='text'
                                value={eisFontSize}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setEisFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSize(eisFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setEisFontSize(eisFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                        <table style={{width:"100%"}}>
                            <colgroup>
                             <col width='25%'/>
                             <col width='75%'/>
                            </colgroup>
                            <tbody>
                        <tr>
                             <td><p>SAT Reading:</p></td>
                             <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                 type="text"
                                 placeholder=""
                                 name='satR'
                                 value= {satR}
                                 className={styles.searchBar}
                                 onChange={()=> {
                                     setSatR(event.target.value)
                                 }} 
                                 style={{width:'90px', marginRight:'10px'}}
                             /><p>/400</p></td>
                        </tr>
                        <tr>
                             <td><p>SAT Grammar:</p></td>
                             <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                 type="text"
                                 placeholder=""
                                 name='satG'
                                 value= {satG}
                                 className={styles.searchBar}
                                 onChange={()=> {
                                     setSatG(event.target.value)
                                 }}
                                 style={{width:'90px', marginRight:'10px'}}
                             /><p>/400</p></td>
                        </tr>
                        <tr>
                             <td><p>SAT Math:</p></td>
                             <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                 type="text"
                                 placeholder=""
                                 name='satM'
                                 value= {satM}
                                 className={styles.searchBar}
                                 onChange={()=> {
                                     setSatM(event.target.value)
                                 }}
                                 style={{width:'90px', marginRight:'10px'}}
                             /><p>/800</p></td>
                        </tr>
                        <tr>
                         <td><p>SAT Total:</p></td>
                         <td style={{display:'flex', flexDirection:'row'}}><Form.Control
                                 type="text"
                                 placeholder=""
                                 name='satT'
                                 value= {satT}
                                 className={styles.searchBar}
                                 onChange={()=> {
                                     setSatT(event.target.value)
                                 }}  
                                 style={{width:'90px', marginRight:'10px'}}
                             /><p>/1600</p></td>
                       </tr>
                       </tbody>
                        </table>
                     </div>
                     </>
                    ):(null)
                    }
                    <div style={{marginTop:'20px'}}>
                        <h4>Official Exams To Register For</h4>
                        <table style={{width:"100%"}}>
                            <colgroup>
                                <col width='25%'/>
                                <col width='75%'/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            Exam Name:
                                        </p>
                                    </td>
                                    <td style={{display:"flex", flexDirection:"row"}}>
                                        <Form.Control
                                            type="text"
                                            name="examName"
                                            value={examName}
                                            onChange={()=>{
                                                setExamName(event.target.value)
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>
                                            Score Range:
                                        </p>
                                    </td>
                                    <td style={{display:"flex", flexDirection:"row"}}>
                                        <Form.Control
                                            type="text"
                                            name="scoreRange"
                                            value={scoreRange}
                                            onChange={()=>{
                                                setScoreRange(event.target.value)
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Button
                            style={{width:'100%'}}
                            onClick={()=>{
                                var registration ={
                                    name: examName,
                                    range: scoreRange
                                }
                                add(registration, examsToRegister, setAddedExams)
                                setScoreRange('')
                                setExamName('')
                            }}
                            className={styles.submitBtn} style={{height:'40px', width:'98%'}}>Add Official ACT Exam</Button>
                        <div>
                        {(addedExams == examsToRegister.length)?(
                                <>
                                <table style={{width:'100%', marginTop: '10px'}}>
                                        <colgroup>
                                            <col width='10%'/>
                                            <col width='45%'/>
                                            <col width='45%'/>
                                        </colgroup>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>Exam Name</th>
                                            <th>Score Range</th>
                                        </tr>
                                        {examsToRegister.map((test, i) => 
                                            <tr key={i}>
                                                    <td style={{width:'10%'}}>
                                                        <Button className={styles.xBtn} onClick={()=>{remove(examsToRegister[i], examsToRegister, setAddedExams)}}><b>x</b></Button>
                                                    </td >
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%'}}>
                                                    {test.name}
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%'}}>
                                                    {test.range}
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                    </div>
                    <div style={{marginTop:'20px'}}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>Tutor Information</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Width:</p>
                            <Form.Control
                                type='text'
                                value={tInfoWidth}
                                style={{height:'60px', width:'30px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setTInfoWidth(event.target.value)
                                }}
                                >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px', color:'black'}}>%</p>
                            <Form.Control
                                type='range'
                                min="1" 
                                max="100"
                                value={tInfoWidth}
                                style={{width:'100px', marginRight:'10px'}}
                                onChange={()=>{
                                    setTInfoWidth(event.target.value)
                                }}
                                >
                            </Form.Control>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Title:</p>
                            <Form.Control
                                type='text'
                                value={tInfoFontSizeT}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setTInfoFontSizeT(event.target.value)
                                }}
                            ></Form.Control>
                            <div style={{display:'flex', flexDirection:'column', marginRight:'10px'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTInfoFontSizeT(tInfoFontSizeT+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTInfoFontSizeT(tInfoFontSizeT-1)
                                }}>\/</Button>
                            </div>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Content:</p>
                            <Form.Control
                                type='text'
                                value={tInfoFontSize}
                                style={{height:'60px', width:'22px', border:'hidden', padding:'0px'}}
                                onChange={()=>{
                                    setTInfoFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTInfoFontSize(tInfoFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setTInfoFontSize(tInfoFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                        <table style={{width:'100%'}}>
                            <colgroup>
                                <col width='25%'/>
                                <col width='75%'/>
                            </colgroup>
                            <tbody>
                                 <tr>
                                     <td>
                                        <p>Name of Tutor:</p>
                                     </td>
                                     <td>
                                         <Form.Control
                                            type='text'
                                            name='tutorName'
                                            value={tutorName}
                                            onChange={()=>{
                                                setTutorName(event.target.value)
                                            }}
                                         />
                                     </td>
                                </tr> 
                                <tr>
                                     <td>
                                        <p>Type of Tutoring:</p>
                                     </td>
                                     <td>
                                     <Form.Control 
                                        onChange={(e) => {setTutoringType(e.target.value)
                                        console.log(tutoringType)}} 
                                        defaultValue='' 
                                        id={`type`} 
                                        as="select"
                                    >
                                        <option value='' disabled>Select Tutoring Type</option>
                                        <option value='One-on-One' >One-on-One</option>
                                        <option value='2-person-group' >2-Person Group</option>
                                        <option value='3-5-person-group' >Courses 3–6 Students </option>
                                        <option value='3-5-person-group' >Courses 7–10 Students </option>
                                    </Form.Control>
                                     </td>
                                </tr> 
                                 <tr>
                                 <td>
                                        <p>Tutoring Hours:</p>
                                     </td>
                                     <td>
                                         <Form.Control
                                            type='text'
                                            name='tutorHours'
                                            value={tutoringHours}
                                            style={{width:'80px'}}
                                            onChange={()=>{
                                                setTutoringHours(event.target.value)
                                            }}
                                         />
                                     </td>
                                </tr> 
                                 <tr>
                                 <td>
                                        <p>Study Session Hours:</p>
                                     </td>
                                     <td>
                                         <Form.Control
                                            type='text'
                                            name='sessionHours'
                                            value={studySessionHours}
                                            style={{width:'80px'}}
                                            onChange={()=>{
                                                setStudySessionHours(event.target.value)
                                            }}
                                         />
                                     </td>
                                </tr>          
                            </tbody>
                        </table>
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                        <h4 style={{flexGrow:'1'}}>Reasons for Taking Listed Exams Page*</h4>
                        <Form.Check
                            type='checkbox'
                            label='Show This Page'
                            onChange={()=>{
                                if(event.target.checked) {
                                    setHidden(false)
                                } else {
                                    setHidden(true)
                                }
                                
                            }}    
                        >
                        
                        </Form.Check>
                        </div>
                        {!hidden? (
                            <>
                               <Form.Control
                            type='text'
                            placeholder='Reasons for Beck Suggesting to Take the...<Test Name>'
                            value={tab1Name}
                            onChange={()=>{
                                setTab1Name(event.target.value)
                            }} 
                        >
                        </Form.Control>
                        <Form.Control
                            type='text'
                            placeholder='Reasons for Beck Stressing the Importance of the...<Test Name>'
                            value={tab2Name}
                            onChange={()=>{
                                setTab2Name(event.target.value)
                            }}  
                        >
                        </Form.Control>
                        <Form.Control
                            type='text'
                            placeholder='Reasons for Beck Suggesting to Take the...<Test Name>'
                            value={tab3Name}
                            onChange={()=>{
                                setTab3Name(event.target.value)
                            }}    
                        >
                        </Form.Control> 
                            </>
                        ):(null)
                        }
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>Deficiencies and Strengths</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Font Size:</p>
                            <Form.Control
                                type='text'
                                value={defAndStrFontSize}
                                style={{height:'60px', width:'50px', border:'hidden'}}
                                onChange={()=>{
                                    setDefAndStrFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setDefAndStrFontSize(defAndStrFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setDefAndStrFontSize(defAndStrFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                        <div style={{display:'flex', flexWrap:'wrap'}}>
                            {generateDeficiencyInputs(testType)}
                            {selectedExamId? (
                                listOfRows.map((index) => {
                                    return (<>
                                        {(index == 1 && testType =='sat')?(
                                            <div key ={'divider'+index} style={{height:'10px', width:'100%', marginBottom:'10px'}}>
                                                
                                                <p style={{textAlign:'center', margin:'0'}}>Non - Calc Questions</p>
            
                                            </div>
                                            ):(index == 21 && testType =='sat')?(
                                                <div key ={'divider'+index} style={{height:'10px', width:'100%', marginBottom:'10px'}}>
                                                
                                                <p style={{textAlign:'center', margin:'0'}}>Calculator Questions</p>
                                                
                                            </div>
                                            ):(null)}
                                    <div id={'Q' + index} key={'Q' +index} style={{display:'flex', flexDirection:'row', flexBasis:'10%'}}>
                                        <Form.Check
                                            id={'Check' + index}
                                            type='checkbox'
                                            name={'Q' + index}
                                            value={index}
                                            label={'Q'+ satCount(index, testType)}
                                            onChange={()=>{
                                                var prefilledArray = prefilledDeficiencies.split('|')
                                                if(event.target.checked) {
                                                    console.log(prefilledDeficiencies.split('|'))
                                                    multipleDeficiencies.push(prefilledArray[event.target.value-1])
                                                    multipleStrengths.forEach((strength, i) => {
                                                        if (strength == prefilledArray[event.target.value-1]) {
                                                            multipleStrengths.splice(i,1)
                                                        }
                                                    })
                                                    setAddedStrengths(multipleStrengths.length)
                                                    setAddedDeficiencies(multipleDeficiencies.length)
                                                    console.log(index)
                                                } else {
                                                    console.log(prefilledDeficiencies.split('|'))
                                                    console.log('Unchecked')
                                                    multipleDeficiencies.forEach((deficiency, i) => {
                                                        if(deficiency == prefilledArray[event.target.value-1]) {
                                                            multipleStrengths.push(prefilledArray[event.target.value-1])
                                                            multipleDeficiencies.splice(i, 1)
                                                            setAddedStrengths(multipleStrengths.length)
                                                            setAddedDeficiencies(multipleDeficiencies.length)
                                                            console.log(deficiency)
                                                        }
                                                    })
                                                }
                                            }}
                                            >          
                                        </Form.Check>
                                        {(index%15 == 0)? (
                                            <br></br>
                                        ):(null)}
                                    </div>
                                    </>)
                                }) 
                            ): (null)}
                        </div>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{width:'50%', paddingRight:'10px', borderRight:'1px solid grey'}}>
                                <p style={{marginBottom:'10px'}}>Deficiencies:</p>
                                <table style={{width:"100%"}}>
                            <colgroup>
                                <col width='100%'/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td style={{display:"flex", flexDirection:"row"}}>
                                        <Form.Control
                                            type="text"
                                            name="deficiency"
                                            value={deficiency}
                                            style={{marginRight:"5px"}}
                                            onChange={()=>{
                                                setDeficiency(event.target.value)
                                            }}
                                        />
                                        <Button
                                            onClick={()=>{
                                                add(deficiency, multipleDeficiencies, setAddedDeficiencies)
                                                setDeficiency('')
                                            }}
                                            className={styles.submitBtn} style={{height:'40px', width: '40px'}}>+</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                        {(addedDeficiencies == multipleDeficiencies.length || selectedExamId)?(
                                <>
                                <table style={{width:'100%', marginTop:'10px'}}>
                                    <tbody>
                                        {multipleDeficiencies.map((weak, i) => 
                                            <tr key={i}>
                                                    <td style={{width:'10%'}}
                                                        >
                                                        <Button className={styles.xBtn} onClick={()=>{remove(multipleDeficiencies[i], multipleDeficiencies, setAddedDeficiencies)}} ><b>x</b></Button>
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                    {weak}
                                                    </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                            </div>
                            <div style={{width:'50%', paddingLeft:'10px'}}>
                                <p style={{marginBottom:'10px'}}>Strengths:</p>
                                <table style={{width:"100%"}}>
                            <colgroup>
                                <col width='100%'/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td style={{display:"flex", flexDirection:"row"}}>
                                        <Form.Control
                                            type="text"
                                            name="strength"
                                            value={strength}
                                            style={{marginRight:"5px"}}
                                            onChange={()=>{
                                                setStrength(event.target.value)
                                            }}
                                        />
                                        <Button
                                            onClick={()=>{
                                                add(strength, multipleStrengths, setAddedStrengths)
                                                setStrength('')
                                            }}
                                            className={styles.submitBtn} style={{height:'40px', width: '40px'}}>+</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                        {(addedStrengths == multipleStrengths.length || selectedExamId)?(
                                <>
                                <table style={{width:'100%', marginTop:'10px'}}>
                                    <tbody>
                                        {multipleStrengths.map((strong, i) => 
                                            <tr key={i}>
                                                    <td style={{width:'10%'}}>
                                                        <Button className={styles.xBtn} onClick={()=>{remove(multipleStrengths[i], multipleStrengths, setAddedStrengths)}}><b>x</b></Button>
                                                    </td >
                                                    <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                    {strong}
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div style={{marginTop:'20px'}}>
                        
                    <div style={{display:'flex', flexDirection:'row'}}>
                            <h4 style={{flexGrow:'1'}}>Lesson Plan</h4>
                            <p style={{marginBottom:'0', marginTop:'17px', marginRight:'5px'}}>Font Size:</p>
                            <Form.Control
                                type='text'
                                value={lPlanFontSize}
                                style={{height:'60px', width:'50px', border:'hidden'}}
                                onChange={()=>{
                                    setLPlanFontSize(event.target.value)
                                }}
                            >
                            </Form.Control>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setLPlanFontSize(lPlanFontSize+1)
                                }}>/\</Button>
                                <Button style={{width:'20px', color:'#D13B31', backgroundColor:'white'}} className={styles.submitBtn} onClick={()=>{
                                    setLPlanFontSize(lPlanFontSize-1)
                                }}>\/</Button>
                            </div>
                        </div>
                            <div>
                            <table style={{width:"100%"}}>
                                <colgroup>
                                    <col width='25%'/>
                                    <col width='75%'/>
                                </colgroup>
                                <tbody>
                                <tr>
                        <td>
                           <p>Lesson Name: </p>
                        </td>
                        <td style={{display:'flex', flexDirection:'row'}}>
                        <Form.Control 
                                onChange={(e) => {setSelectedLessonId(e.target.value)}} 
                                defaultValue='' 
                                id={`type`} 
                                as="select" 
                                required
                                style={{width:'90%', marginRight:'10px'}}
                            >
                                <option value='' disabled>Search Lesson Inventory</option>
                                {lessonInventory?.slice().map((item, i) => {
                                        return(
                                        <option value={item._id} key={i}>
                                            {isVertical?
                                                    `${item.lessonName}`
                                                :
                                                    `Lesson: ${item.lessonName}\xa0\xa0`
                                                }
                                        </option>
                                        )
                                })}
                            </Form.Control>
                            <Button className={styles.submitBtn} style={{height:'40px'}} onClick={()=> prefillLessonAttributes()}>
                               <b>Prefill</b> 
                            </Button>
                        </td>
                    </tr>
                                    <tr>
                                        <td>
                                            <p>Lesson Number</p>
                                        </td>
                                        <td>
                                        <Form.Control
                                                type="text"
                                                name="lessonNumber"
                                                value={lessonNumber}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setLessonNumber(event.target.value)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>
                                                Lesson Name:
                                            </p>
                                        </td>
                                        <td style={{display:"flex", flexDirection:"row"}}>
                                            <Form.Control
                                                type="text"
                                                name="lessonName"
                                                value={lessonName}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setLessonName(event.target.value)
                                                }}
                                            />
                                            <Button
                                                onClick={()=>{
                                                    add(lessonName, lessonsList, setAddedLessonsList)
                                                    setLessonName('')
                                                }}
                                                className={styles.submitBtn} style={{height:'40px', width: '40px'}}>+</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            {(addedLessonsList == lessonsList.length)?(
                                    <>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {lessonsList.map((tutorial, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.xBtn} onClick={()=>{remove(lessonsList[i], lessonsList, setAddedLessonsList)}}><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {tutorial}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                        </div>
                        <div>
                            <table style={{width:"100%", marginTop:'10px'}}>
                                <colgroup>
                                    <col width='25%'/>
                                    <col width='75%'/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>
                                                Homework Title:
                                            </p>
                                        </td>
                                        <td style={{display:"flex", flexDirection:"row"}}>
                                            <Form.Control
                                                type="text"
                                                name="homeworkName"
                                                value={homeworkName}
                                                style={{marginRight:"5px"}}
                                                onChange={()=>{
                                                    setHomeworkName(event.target.value)
                                                }}
                                            />
                                            <Button
                                                onClick={()=>{
                                                    add(homeworkName, homeworkList, setHomeworkAdded)
                                                    setHomeworkName('')
                                                }}
                                                className={styles.submitBtn} style={{height:'40px', width: '40px'}}>+</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            {(homeworkAdded == homeworkList.length)?(
                                    <>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {homeworkList.map((hw, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.xBtn} onClick={()=>{remove(homeworkList[i], homeworkList, setHomeworkAdded)}}><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {hw}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                        </div>
                        <Button
                            style={{width:'100%', marginTop:'10px'}}
                            onClick={()=>{
                                var listedLessons = lessonsList
                                var listedHomework = homeworkList
                                var lessonComposite ={
                                    lessonsList: listedLessons,
                                    homeworkList: listedHomework
                                }
                                var counter = 0

                                if(lesson.length == 0){
                                    lesson.push({
                                        lessonNumber: lessonNumber,
                                        multipleLessons: [lessonComposite]
                                    })
                                    setAddedLessons(lesson.length)
                                } else{
                                    lesson.forEach(inquery => {
                                            if(inquery.lessonNumber == lessonNumber) {
                                                inquery.multipleLessons.push(lessonComposite)
                                                console.log(inquery)
                                            } else {
                                                counter++
                                            }
                                        })
                                        if (counter == lesson.length) {
                                            lesson.push({
                                                lessonNumber: lessonNumber,
                                                multipleLessons: [lessonComposite]
                                            })
                                            setAddedLessons(lesson.length)
                                        }
                                }
                                //add(lessonComposite, multipleLessons, setAddedLessons)
                                setAddedLessons(lesson.length)
                                console.log(lesson)
                                setLessonsList([])
                                setAddedLessonsList(0)
                                setLessonName('')
                                setHomeworkList([])
                                setHomeworkAdded(0)
                                setHomeworkName('')
                            }}
                            className={styles.submitBtn} style={{height:'40px', width:'98%'}}>Add Lesson</Button>
                        <div style={{overflow:'auto'}}>
                        {(addedLessons == lesson.length || selectedExamId)?(
                                <>
                                <table style={{width:'98%', marginTop: '10px', backgroundColor:'white'}}>
                                        <colgroup>
                                            <col width='5%'/>
                                            <col width='5%'/>
                                            <col width='45%'/>
                                            <col width='45%'/>
                                        </colgroup>
                                    <tbody>
                                        <tr>
                                            <th style={{textAlign:'center'}}></th>
                                            <th style={{textAlign:'center'}}>#</th>
                                            <th style={{textAlign:'center'}}>Lessons</th>
                                            <th style={{textAlign:'center'}}>Homeworks</th>
                                        </tr>
                                        {lesson.map((teaching, i) => 
                                            <tr key={i} 
                                                style={{borderTop:'1px solid grey', borderBottom:'3px solid grey', transform:'translateY(2px)', backgroundColor:'white'}}
                                                onDragStart={(e) => dragStart(e, i)}
                                                onDragEnter={(e) => dragEnter(e, i)}
                                                onDragEnd={drop}
                                                draggable
                                            >
                            
                                                    <td style={{width:'5%', borderBottom:'1px solid gray',borderLeft:'1px solid gray'}}>
                                                        <Button className={styles.xBtn} onClick={()=>{remove(lesson[i], lesson, setAddedLessons)}}><b>x</b></Button>
                                                    </td >
                                                    <td style={{width:'5%'}}>
                                                        {i+1}
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre', border:'1px solid gray'}} valign='top'>
                                                        <table style={{width:'100%', height:'inherit'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                    return  <tr key={'lesson '+ i} style={{height:'150px'}}>
                                                                    <td style={{ borderBottom:'1px solid gray'}}>
                                                                        <div style={{width:'300px'}}>
                                                                        {
                                                                                    learning.lessonsList.map((lesson, i) => {
                                                                                        return <div key={lesson + i} style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                                                            {
                                                                                            lesson.split(' ').map((word, i)=> {
                                                                                                return <p key={word + i} style={{margin:'0px', fontSize:'15px', color:'black'}}> {word}</p>
                                                                                                })
                                                                                            }  
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{textAlign: 'left', color: 'black', width:'45%', whiteSpace:'pre', border:'1px solid gray'}} valign='top'>
                                                    <table style={{width:'100%'}}>
                                                            <tbody>
                                                            {
                                                                teaching.multipleLessons.map((learning, i) => {
                                                                    return  <tr key={'lesson '+ i} style={{height:'150px'}}>
                                                                        <td style={{ borderBottom:'1px solid gray'}}>
                                                                            <div style={{width:'300px'}}>
                                                                            {
                                                                                        learning.homeworkList.map((lesson, i) => {
                                                                                            return <div key={lesson + i} style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                                                                {
                                                                                                lesson.split(' ').map((word, i)=> {
                                                                                                    return <p key={word + i} style={{margin:'0px', fontSize:'15px', color:'black'}}> {word}</p>
                                                                                                    })
                                                                                                }  
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                            </tr> 
                                        )}
                                    </tbody>
                                </table>
                                </>
                        ):(null)}
                        </div>
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <h4>Additional Notes</h4>
                        <textarea
                            className={styles.textArea}
                            type="textarea"
                            value={notes}
                            onChange={()=>{setNotes(event.target.value)}}
                            style={{width:"98%", marginLeft:'1%'}}
                            />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        {draftId?(
                            <Button
                            className={styles.xBtn}
                            style={{width:'49%',height:'40px', marginTop:'50px', borderRadius:'5px', border:'2px solid #df2019', flexGrow:'1'}}
                            onClick={()=> {
                                setConfirmation('draft')
                                saveDraft()
                            }}
                            >
                                <b>Finish Edit</b>
                        </Button>
                        ):(
                            <Button
                            className={styles.xBtn}
                            style={{width:'49%',height:'40px', marginTop:'50px', borderRadius:'5px', border:'2px solid #df2019', flexGrow:'1'}}
                            onClick={()=> {
                                setConfirmation('draft')
                                saveDraft()
                            }}
                            >
                                <b>Save as Draft</b>
                        </Button>
                        )
                        }
                        
                        {/* <Button
                            className={styles.submitBtn}
                            style={{width:'49%',height:'40px', marginTop:'50px'}}
                            onClick={()=> {
                                sendRecapEmail();
                            }}>
                                <b>Send a Recap</b>
                        </Button> */}
                    </div>
                    </>
                ):(null)
                     }
            </Form>
            </div>
            <div style={{width:'45.5%', minWidth:'33%',backgroundColor:'white', overflow:'auto'}}>
                {/* <div style={{position:'fixed', width:'100%', backgroundColor:'white', zIndex:'100'}}>
                    <p style={{borderBottom:'1px solid grey', marginBottom: '10px'}}>
                        From: a.beck@mailing.beckcollegeprep.com
                    </p>
                    <p style={{borderBottom:'1px solid grey', marginBottom: '10px'}}>
                        To: {recipientEmail}
                    </p>
                    <p style={{borderBottom:'1px solid grey', marginBottom: '0px'}}>
                        Subject: {studentName}'s {testType.toUpperCase()} Recap
                    </p>
                </div> */}
                <div style={{marginTop:'0px'}}></div>
                <div id="recapPDF" style={{width:'800px'}}>
                <div style={{backgroundImage:`url(${page1})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                        
                    </div>
                    
                    <div style={{backgroundImage:`url(${page2})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column',justifyContent:'center'}}>
                        {
                            studentName.split(' ').map((name) => {
                                return <p key={name} className={styles.headings} style={{color:'white', fontSize:'90px', textAlign:'center', marginBottom:'0',fontFamily: 'Abril Fatface'}}>{name}</p>
                            }
                            )
                        }
                    </div>
                    
                    <div id='consultationPage' style={{backgroundImage:`url(${page4})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column', overflowY:'scroll'}}>
                        <h1 className={styles.headings} style={{color:'#D13B31', marginTop:'100px'}}>CONSULTATION NOTES</h1>
                        <table style={{width:tpcWidth + '%', marginBottom:'30px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize: tpcFontSizeT +'px'}}>Topics Discussed in Meeting</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-word', width:'100%', padding:'5px'}}>
                                        {topicsDiscussed.map((topic, i) => {
                                            if (i == 0) {
                                                charCount = 0
                                            }
                                            if (topic.length < 124) {
                                                charCount = charCount + 124
                                            } else {
                                                charCount = charCount + topic.length
                                            }
                                            if(charCount <= 4556*(12/tpcFontSize)) {
                                                if(topic.length == 0) {
                                                    return <br></br>
                                                } else {
                                                    return <p key={topic} style={{color:'black', marginBottom:'0px', textAlign:'center', fontSize:tpcFontSize+'px'}}>{topic}</p>
                                                }
                                            }
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {(!(totalCharCount > 3556*(12/tpcFontSize)))?(
                            <>
                            <table style={{width: coiWidth+'%', marginBottom:'30px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize: coiFontSizeT+'px'}}>Student's Colleges of Interest</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-all', width:'100%', paddingTop:'20px', paddingBottom:'20px'}}>
                                        
                                        {collegesOfInterest.map(topic => {
                                            return <p key={topic} style={{color:'black', marginBottom:'0px', textAlign:'center', fontSize:coiFontSize+'px'}}>{topic}</p>
                                        })}

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </>
                        ):(null)}
                        {(!(totalCharCount > 2556*(12/tpcFontSize)))?(
                            <>
                                <h2 className={styles.headings} style={{color: 'black', fontSize:'25px'}}>Initial Scores</h2>
                        <table style={{width: eisWidth+'%', border:'5px solid white'}}>
                            <colgroup span="2">
                                <col width='50%'/>
                                <col width='50%'/>
                            </colgroup>
                            {(testType=='act')? (
                                <tbody style={{backgroundColor:'#F6E8E1'}}>
                                <tr style={{height:'40px'}}>
                                    <th colSpan='2' scope="colgroup" className={styles.headings} style={{color:'white', backgroundColor:'#D13B31', textAlign:'center', fontWeight:'lighter', fontSize:eisFontSizeT +'px'}}>Beck {testType.toUpperCase()} Diagnostic Score</th>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Grammar
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        {ag}
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Math
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                      {am}  
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Reading
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        {ar}
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Science
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        {as}
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Total
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {at} 
                                    </td>
                                </tr>
                            </tbody>
                            ):(testType=='sat')? (
                                <tbody style={{backgroundColor:'#F6E8E1'}}>
                                <tr style={{height:'40px'}}>
                                    <th colSpan='2' scope="colgroup" className={styles.headings} style={{color:'white', backgroundColor:'#D13B31', textAlign:'center', fontWeight:'lighter', fontSize:eisFontSizeT +'px'}}>Beck {testType.toUpperCase()} Diagnostic Score</th>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Reading
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satR} 
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Grammar
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satG} 
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Math
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satM} 
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Total
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satT} 
                                    </td>
                                </tr>
                            </tbody>
                            ):(null)}
                        </table>
                            </>
                        ):(null)}
                    </div> 
                    {(totalCharCount > 2556*(12/tpcFontSize))? (
                        <div id='consultationPage' style={{backgroundImage:`url(${page4})`, paddingRight:'0px', paddingTop:'50px', height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column', overflowY:'scroll'}}>
                        {(totalCharCount > 4556*(12/tpcFontSize))?(
                            <>
                                <table style={{width:tpcWidth + '%', marginTop:'30px', marginBottom:'30px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize: tpcFontSizeT +'px'}}>Topics Discussed in Meeting</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-word', width:'100%', padding:'5px'}}>
                                    {topicsDiscussed.map((topic, i) => {
                                            if (i == 0) {
                                                charCount = 0
                                            }
                                            if (topic.length < 124) {
                                                charCount = charCount + 124
                                            } else {
                                                charCount = charCount + topic.length
                                            }
                                            if(charCount > 4556 *(12/tpcFontSize)) {
                                                return <p style={{color:'black', marginBottom:'0px', textAlign:'center', fontSize:tpcFontSize+'px'}}>{topic}</p>
                                            }
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                            </>
                        ):(null)}
                        {(totalCharCount > 3556*(12/tpcFontSize))?(
                            <>
                              <table style={{width: coiWidth+'%', marginBottom:'30px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize: coiFontSizeT+'px'}}>Student's Colleges of Interest</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-all', width:'100%', paddingTop:'20px', paddingBottom:'20px'}}>
                                        
                                        {collegesOfInterest.map(topic => {
                                            return <p style={{color:'black', marginBottom:'0px', textAlign:'center', fontSize:coiFontSize+'px'}}>{topic}</p>
                                        })}

                                    </td>
                                </tr>
                            </tbody>
                        </table>  
                            </>
                        ):(null)}
                        {(totalCharCount > 2556*(12/tpcFontSize))?(
                            <>
                                <h2 className={styles.headings} style={{color: 'black', fontSize:'25px'}}>Initial Scores</h2>
                        <table style={{width: eisWidth+'%', border:'5px solid white'}}>
                            <colgroup span="2">
                                <col width='50%'/>
                                <col width='50%'/>
                            </colgroup>
                            {(testType=='act')? (
                                <tbody style={{backgroundColor:'#F6E8E1'}}>
                                <tr style={{height:'40px'}}>
                                    <th colSpan='2' scope="colgroup" className={styles.headings} style={{color:'white', backgroundColor:'#D13B31', textAlign:'center', fontWeight:'lighter', fontSize:eisFontSizeT +'px'}}>Beck {testType.toUpperCase()} Diagnostic Score</th>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Grammar
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        {ag}
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Math
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                      {am}  
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Reading
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        {ar}
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Science
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        {as}
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Total
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {at} 
                                    </td>
                                </tr>
                            </tbody>
                            ):(testType=='sat')? (
                                <tbody style={{backgroundColor:'#F6E8E1'}}>
                                <tr style={{height:'40px'}}>
                                    <th colSpan='2' scope="colgroup" className={styles.headings} style={{color:'white', backgroundColor:'#D13B31', textAlign:'center', fontWeight:'lighter', fontSize:eisFontSizeT +'px'}}>Beck {testType.toUpperCase()} Diagnostic Score</th>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Reading
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satR} 
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Grammar
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satG} 
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Math
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satM} 
                                    </td>
                                </tr>
                                <tr style={{height:'40px'}}>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                        Total
                                    </td>
                                    <td style={{border:'2px solid white', textAlign:'center', fontSize: eisFontSize+'px'}}>
                                       {satT} 
                                    </td>
                                </tr>
                            </tbody>
                            ):(null)}
                        </table>
                            </>
                        ):(null)}
                    </div> 
                    ):(null)
                    }
                    
                    <div style={{backgroundImage:`url(${page5})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>

                    </div> 
                    
                    <div style={{backgroundImage:`url(${page6})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                        <h1 className={styles.headings} style={{color:'#D13B31', marginTop:'100px'}}>TESTING PLAN DETAILS</h1>
                        <table style={{width:'550px'}}>
                            <colgroup>
                                <col width='50%'/>
                                <col width='50%'/>
                            </colgroup>

                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th style={{color:'white', textAlign:'center', borderRight:'2px solid white', fontWeight:'lighter'}} className={styles.headings}>
                                        Official {testType.toUpperCase()} Exams to Register For
                                    </th>
                                    <th style={{color:'white', textAlign:'center', borderLeft:'2px solid white', fontWeight:'lighter'}} className={styles.headings}>
                                        Beck's Projected Score Range Per Exam Listed
                                    </th>
                                </tr>
                                {examsToRegister.map((test, i) => 
                                            <tr key={i} style={{backgroundColor:'#F6E8E1'}}>
                                                    <td style={{textAlign: 'center', color: 'black', height:'60px', border:'2px solid white'}}>
                                                    {test.name}
                                                    </td>
                                                    <td style={{textAlign: 'center', color: 'black', height:'60px', border:'2px solid white'}}>
                                                    {test.range}
                                                    </td>
                                            </tr> 
                                        )}
                            </tbody>
                        </table>
                        <p style={{textAlign:'center', marginTop:'30px', color:'black', paddingLeft:'20px', paddingRight:'20px'}}>After the {examsToRegister.map((test, i) => {
                            if (i == examsToRegister.length-1) {
                                return test.name
                            }
                        })} exam, we will have you retest the {textFill()} diagnostic to confirm if we will continue to tutor the {testType.toUpperCase()}, switch to the {textFill()}, or pursue preparing for both the SAT and ACT.</p>
                        <div style={{bottom:'0px', width:'100%', height:'900px', backgroundColor:'#F6E8E1', marginTop:'45px', paddingTop:'60px', paddingLeft:'120px', paddingRight:'120px'}}>
                            <h2 className={styles.headings} style={{textAlign:'center', color:'black', fontSize:'25px'}}>Tasks That Need to be Completed For Students to Reach Projected Scores Above</h2>
                            <p className={styles.headings} style={{textAlign:'center', color:'black', fontSize:'15px'}}>If the student:</p>
                            <ul style={{paddingLeft:'8px'}}>
                                <li><p style={{color:'black', marginBottom:'10px'}}>Completes all of the homework under the section-time restraints, not giving herself/himself more time than allowed</p></li>
                                <li><p style={{color:'black', marginBottom:'10px'}}>Using all of the strategies shown to him/her</p></li>
                                <li><p style={{color:'black', marginBottom:'10px'}}>Memorize all of the written explanations that correct his/her past homework mistakes and formulas</p></li>
                                <li><p style={{color:'black', marginBottom:'10px'}}>Put forth maximum effort on diagnostic scores</p></li>
                            </ul>
                            <p style={{color:'black'}}>If he/she does not complete the above-mentioned, or if the lessons progress at a slower pace than initially planned, then the testing and score timeline written below may be delayed.<br>
                                </br><br></br>
                                These score targets do not take into account externalities out of our control on testing day (Bad proctoring, testing anxiety not seen on Beck diagnostics, etc.)</p>
                        </div>
                    </div> 
                    
                    <div style={{backgroundColor:`white`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:hideTab(hidden),alignItems:'center', flexDirection:'column'}}>
                        <h1 className={styles.headings} style={{color:'black', marginTop:'20px', fontSize:'30px'}}>Reasons For Taking the Listed Exams</h1>
                        {!hidden? 
                        (
                            <table style={{width:'80%', marginBottom:'10px'}}>
                                <tr style={{backgroundColor:'#D13B31', border:'3px solid white', color:'white', height:'40px'}}>
                                    <th className={styles.headings} style={{textAlign:'center', fontWeight:'lighter'}}>Reasons for Beck Suggesting to Take the {tab1Name}</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1', border:'2px solid white'}}>
                                    <td style={{fontSize:'12px',padding:'5px'}}>
                                        <p className={styles.headings} style={{color:'black', textAlign:'center', margin:'0px'}}> Question and Answer Service (QAS)</p>
                                    <p style={{textAlign:'center', margin:'0', color:'black'}}>The Question-and-Answer Service is a paid analysis of the student's test. The Question-and-Answer Service releases the SAT test questions and the student's individual response for each question. The Question-and-Answer Service is only provided for exams given in the following months: October, March, and May. If we are able to see the student's real time SAT performance in October, then we are able to correct many of these particular deficiencies for the November SAT. </p><br></br>
                                    <p className={styles.headings} style={{color:'black', textAlign:'center', margin:'0px'}}> Real Testing Assimilation</p>
                                    <p style={{textAlign:'center', margin:'0', color:'black'}}>Even though the students are going to take multiple diagnostic exams before taking their first SAT exam, some students still may get nervous on the first test. If the students get anxious because this is a real test, unlike the diagnostics, which does not appear on their testing record, then we can begin to reduce the feeling of anxiety. Getting anxiety on testing day can be detected through silly mistakes, over analyzation, omission of questions, and anything else that allows us to know that the student was not mindfully present while taking the exam. Due to the fact the students will have the QAS, then we are able to signal this to the students in this group, if needed, hopefully not, so that this can be alleviated by the October PSAT exam.  </p><br></br>
                                    <p className={styles.headings} style={{color:'black', textAlign:'center', margin:'0px'}}> Confirmation of Their Preparation</p>
                                    <p style={{textAlign:'center', margin:'0', color:'black'}}>The Question-and-Answer This allows the student to understand that all the material that we have used to prepare them for the exam with, which are previously officially administered exams, does appear on the exam. It can also be a good reality check for students who stubbornly do not want to use the strategies taught or believe that we as tutors are exaggerating as to the level of memorization or studying needed for success on the exam. </p><br></br>
                                    <p className={styles.headings} style={{color:'black', textAlign:'center', margin:'0px'}}> Boost in Confidence</p>
                                    <p style={{textAlign:'center', margin:'0', color:'black'}}>The Question-and-Answer I believe that tutoring for seven months, then having a student take their first test after seven months, puts a lot of pressure on the student to perform 300 points more than their initial diagnostic, which can cause unnecessary testing anxiety. By making short term testing score goals and having the student achieve those goals on test day confirms to the student that all of their hard work is paying off, that they are able to conquer the exam, and motivates them to continue working hard toward their potential. </p><br></br>
                                    </td>
                                </tr>
                            </table>
                        ):(null)
                        }
                        {!hidden? 
                        (
                            <table style={{width:'80%', marginBottom:'10px'}}>
                                <tr style={{backgroundColor:'#D13B31', border:'3px solid white', color:'white', height:'40px'}}>
                                    <th className={styles.headings} style={{textAlign:'center', fontWeight:'lighter'}}>Reasons for Beck Stressing the Importance of the {tab2Name}</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1', border:'2px solid white'}}>
                                    <td style={{fontSize:'12px', padding:'5px'}}>
                                    <p style={{textAlign:'left', margin:'0', color:'black'}}>The Goal is for the students to earn a 1300+ on the October PSAT, so that they can earn the National Hispanic Scholarship. The National Hispanic Scholarship is not only an attractive academic award to place on their college application, but, historically, Beck students who have earned this award have received full ride tuition scholarship, presented in their college admission offer letter, to the following colleges to name a few:</p><br></br>
                                    <br></br>
                                    <p style={{textAlign:'left', margin:'0', color:'black'}}>
                                    Fordham University<br></br>
                                    University of Miami<br></br>
                                    Boston University<br></br>
                                    Tulane University<br></br>
                                    Villanova University<br></br>
                                    Bentley University<br></br>
                                    Ohio State University <br></br>
                                    </p>
                                    </td>
                                </tr>
                            </table>
                        ):(null)
                        }
                        {!hidden? 
                        (
                            <table style={{width:'80%'}}>
                                <tr style={{backgroundColor:'#D13B31', border:'3px solid white', color:'white', height:'40px'}}>
                                    <th className={styles.headings} style={{textAlign:'center', fontWeight:'lighter'}}>Reasons for Beck Suggesting to Take the {tab3Name}</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1', border:'2px solid white'}}>
                                    <td style={{fontSize:'12px', padding:'5px'}}>
                                    <p style={{textAlign:'left', margin:'0', color:'black'}}>To prepare for the October PSAT exam, Beck College Prep is planning on offering 3-4 intensive bootcamps. In addition to the bootcamps, students attend multiple study sessions, listed under Tutoring Days and Times, to ensure their National Hispanic Merit Scholarship score. Usually by preparing so intensively for the October PSAT, students score improve 50-70 points in a short timeline. For this reason, since the November SAT is 2 weeks after the PSAT, we encourage the students to take the exam because their score could improve another 70-100 points from their Official October SAT score. </p>
                                    </td>
                                </tr>
                            </table>
                        ):(null)
                        }
                    </div>
                    
                    <div style={{backgroundImage:`url(${page8})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    
                    </div> 
                     
                    <div style={{backgroundImage:`url(${yellowBubble})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                        <h2 className={styles.headings} style={{color:'black', fontSize:'30px', marginBottom:'2px', marginTop:'60px'}}>Name of Tutor</h2>
                        <p style={{marginTop:'0px', color:'black', fontSize:'20px', marginBottom:'0px'}}>{tutorName}</p>
                        <h2 className={styles.headings} style={{color:'black', fontSize:'30px', marginBottom:'2px', marginTop:'25px'}}>Type of Tutoring</h2>
                        <p style={{marginTop:'0px', color:'black', fontSize:'20px', marginBottom:'25px'}}>{tutoringType}</p>
                        <table style={{marginTop:'0', width:tInfoWidth+'%', border:'5px solid white'}}>
                            <colgroup span="2">
                                <col width='50%'/>
                                <col width='50%'/>
                            </colgroup>
                            <tbody style={{backgroundColor:'#F6E8E1'}}>
                                <tr style={{backgroundColor:'#D13B31', color:'white'}}>
                                    <th className={styles.headings} colSpan="2" scope="colgroup" style={{textAlign:'center', padding:'10px', fontWeight:'lighter', fontSize: tInfoFontSizeT +'px'}}>Number of Hours of Weekly Tutoring</th>
                                </tr>
                                <tr style={{height:'60px'}}>
                                    <td style={{textAlign:'center', border:'1px solid white', fontSize:tInfoFontSize +'px'}}>
                                        Tutoring Hours
                                    </td>
                                    <td style={{textAlign:'center', border:'1px solid white', fontSize:tInfoFontSize +'px'}}>
                                        {tutoringHours}
                                    </td>
                                </tr>
                                <tr style={{height:'60px'}}>
                                    <td style={{textAlign:'center', border:'1px solid white', fontSize:tInfoFontSize +'px'}}>
                                        Study Session Hours
                                    </td>
                                    <td style={{textAlign:'center', border:'1px solid white', fontSize:tInfoFontSize +'px'}}>
                                        {studySessionHours}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2 className={styles.headings} style={{color:'black', fontSize:'30px', marginBottom:'2px', marginTop:'25px'}}>Free Study Sessions</h2>
                        <div style={{paddingLeft:'100px', paddingRight:'100px', fontSize:'13px', marginTop:'20px'}}>
                        <p style={{textAlign:'left', margin:'0', color:'black'}}>Complete homework in a supervised setting to confirm the use of tutoring strategies and timing of sections, establish time management, avoid procrastination of the homework.</p>
                            <ul style={{padding:'10px'}}>
                                <li><b>Studying:</b> Memorize formulas, and written explanations correct past mistakes, and review past material</li>
                                <li><b>Clarification:</b> When tutors have breaks between sessions, they will help the students with problems that they do not understand, grade, or correct assignments they completed in the Study Sessions</li>
                            </ul>
                            <p style={{textAlign:'left', margin:'0', color:'black'}}><b>Study Sessions are Free and Unlimited.</b> We encourage the students in the group to come and study together so that they can help each other. If students cannot meet consistently in the group, students can still come on their own, without the group, as the assignments will be the same.</p><br></br>
                            <p style={{textAlign:'left', margin:'0', color:'black'}}><b>During the summer:</b> we will want the students to attend 2-3 study sessions. We want them to take advantage of the availability they have without school. I am aware of their intense school year classes; therefore, it will be easier to get the hard work out of the way in the summer rather than during the school year, which could cause them unnecessary stress, by being overwhelmed with their school homework and studying as well as studying for the SAT.</p><br></br>
	                        <p style={{textAlign:'left', margin:'0', color:'black'}}><b>When the school year begins</b>, we will want them to come 1-2 times per week, depending on the week and what their schedule permits. If they miss a study session one week, they can make it up the week before or after.</p><br></br>
                            <p style={{textAlign:'left', margin:'0', color:'black'}}>
                            <b>Please note that study sessions do not preclude students from studying.</b> We encourage them to study and memorize at home. The greater the time commitment they can place the better their performance will be and the quicker they can finish at Beck College Prep because they reached their goal score.</p><br></br>
                            <p style={{textAlign:'left', margin:'0', color:'black'}}>The assigned Tutor will be proposing multiple days and times in which the students can come consistently for study sessions.</p>
                        </div>
                        <div style={{width:'100%', height:'78px', backgroundColor:'#fff1d1'}}></div>
                    </div>
                    
                    <div style={{backgroundImage:`url(${page10})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                        <h1 className={styles.headings} style={{marginTop:'75px', fontSize:'30px', color:'black'}}>Materials Needed</h1>
                        <div style={{marginTop:'20px', width:'460px'}}><p style={{textAlign:'center', color:'black'}}>No need to purchase material. Beck provides all tutoring material. If available, please bring Ipad or Tablet.</p></div>
                        <table style={{width:'90%'}}>
                            <colgroup>
                                <col width='5%'/>
                                <col width='45%'/>
                                <col width='5%'/>
                                <col width='45%'/>
                            </colgroup>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31', color:'white'}}>
                                    <th className={styles.headings} colSpan="4" scope="colgroup" style={{textAlign:'center', fontSize:'16px', padding:'5px', fontWeight:'lighter'}}>Student's Areas of Deficiency and Strength on the Beck {testType.toUpperCase()} Diagnostic</th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <th colSpan='2' style={{textAlign:'center', fontFamily:'Times', fontWeight:'lighter', border:'1px solid white', fontSize:defAndStrFontSize+'px'}}>Deficiencies</th>
                                    <th colSpan='2' style={{textAlign:'center', fontFamily:'Times', fontWeight:'lighter', border:'1px solid white', fontSize:defAndStrFontSize+'px'}}>Strengths</th>
                                </tr>
                                {greaterLength(multipleDeficiencies, multipleStrengths).map((attribute, i) => {
                                        return <tr key={attribute} style={{fontFamily:'Times', fontSize:defAndStrFontSize+'px'}}>
                                            {multipleDeficiencies[i]? (
                                                <>
                                                <td style={{border:'1px solid white', textAlign:'center', backgroundColor:alternateColors(i)}}>{i+1}</td>
                                                <td style={{border:'1px solid white', backgroundColor:alternateColors(i)}}>{multipleDeficiencies[i]}</td>
                                                </>
                                            ):(<>
                                                <td style={{backgroundColor:'white', opacity:'0'}}></td>
                                                <td style={{backgroundColor:'white', opacity:'0'}}></td>
                                                </>
                                            )
                                            }
                                            {multipleStrengths[i]? (
                                                <>
                                                <td style={{border:'1px solid white', textAlign:'center', backgroundColor:alternateColors(i)}}>{i+1}</td>
                                                <td style={{border:'1px solid white', backgroundColor:alternateColors(i)}}>{multipleStrengths[i]}</td>
                                                </>
                                            ):(
                                                <>
                                                <td style={{backgroundColor:'white', opacity:'0'}}></td>
                                                <td style={{backgroundColor:'white', opacity:'0'}}></td>
                                                </>
                                            )
                                            }
                                        </tr>
                                    })

                                }
                            </tbody>
                        </table>
                    </div>
                     
                    <div style={{backgroundColor:'white', height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    <div style={{marginTop:'75px'}}>
                    <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center'}}>Student's Lesson Plan & HW Assignments</h1>
                    <p style={{textAlign:'center', margin:'0px 60px 0px 60px', color:'black', fontSize:'10px'}}>
                    Below are the intended initial lesson plans and the corresponding homework assignments. Please note that these lessons are tentative and are subject to change. If the student has a lot of mistakes on the homework, comprehension of certain topics gets delayed. Similarly, if retention of the information could not be maintained, then this would delay the lessons plans and/or the student's performance progress. Your tutor will inform you if the lesson plans, homework assignments, and/or expectations of progress should be adjusted.
                    </p>
                    </div>
                    <table style={{ marginTop: '20px', fontSize:lPlanFontSize+'px', width:'90%'}} id='bestTable'>
                                    <colgroup>
                                        <col width='10%'/>
                                        <col width='45%'/>
                                        <col width='45%'/>
                                    </colgroup>
                                    <tbody style={{backgroundColor:'#F6E8E1'}}>
                                        <tr>
                                            <th style={{textAlign:'center', fontFamily:'Times',backgroundColor:'#D13B31', color:'white', border:'1px white solid'}}>Lesson Number</th>
                                            <th style={{textAlign:'center', fontFamily:'Times',backgroundColor:'#D13B31', color:'white', border:'1px white solid'}}>Lessons</th>
                                            <th style={{textAlign:'center', fontFamily:'Times',backgroundColor:'#D13B31', color:'white', border:'1px white solid'}}>Homeworks</th>
                                        </tr>
                                        {lesson.map((teaching, index) => {

                                            return (<>{teaching.multipleLessons.map((item, n) => {
                                                if (item.lessonsList.length > item.homeworkList.length) {
                                                    var temp = 0;
                                                   return (item.lessonsList.map((composite, x) => {
                                                        if(n == 0 && temp == 0) {
                                                            temp++
                                                            
                                                            return <tr key={'ls'+x}>
                                                                <td style={{textAlign:'center', border:'1px white solid', zIndex:'100'}} rowSpan={totalRowSpan(index)}>{index+1}</td>
                                                                <td style={{border:'1px white solid'}}>{item.lessonsList[x]}</td>
                                                                <td style={{border:'1px white solid'}} rowSpan={item.lessonsList.length}>{item.homeworkList[x]}</td>
                                                            </tr>
                                                            
                                                        } else {
                                                            
                                                            return <tr key={'ls'+x}>
                                                            <td style={{border:'1px white solid'}}>{item.lessonsList[x]}</td>
                                                            {item.homeworkList[x]? (
                                                                <td style={{border:'1px white solid'}} rowSpan={item.lessonsList.length}>{item.homeworkList[x]}</td>
                                                            ): (null)
                                                            }
                                                        </tr> 
                                                        }
                                                    }))
                                                } else {
                                                    var temp = 0;
                                                    return (item.homeworkList.map((composite, x) => {
                                                        if(n == 0 && temp == 0) {
                                                            temp++
                                                            
                                                            return <tr key={composite}>
                                                                <td style={{textAlign:'center', border:'1px white solid', zIndex:'100'}} rowSpan={totalRowSpan(index)}>{index+1}</td>
                                                                <td style={{border:'1px white solid'}} rowSpan={item.homeworkList.length}>{item.lessonsList[x]}</td>
                                                                <td style={{border:'1px white solid'}}>{item.homeworkList[x]}</td>
                                                            </tr>
                                                            
                                                        } else {
                                                             
                                                                return <tr key={composite}>
                                                                {item.lessonsList[x]? (
                                                                <td style={{border:'1px white solid'}} rowSpan={item.homeworkList.length}>{item.lessonsList[x]}</td>
                                                            ): (null)
                                                            }
                                                            <td style={{border:'1px white solid'}}>{item.homeworkList[x]}</td>
                                                            
                                                        </tr> 
                                                            
                                                        }
                                                    }))
                                                }                  
                                            })}<tr style={{height:'10px', backgroundColor:'white'}}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </>)
                                            }    
                                        )}
                                        <tr>
                                            <td style={{textAlign:'center', fontFamily:'Times',backgroundColor:'#D13B31', color:'white'}} colSpan='3'>Before Proceeding with Further Lessons, Student Must Complete a {textFill()} Grammar, {textFill()} Math Diagnostic Exam. </td>
                                        </tr>
                                    </tbody>
                                </table>
                    </div>
                    
                    <div style={{backgroundImage:`url(${page12})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                        <div style={{height:'560px', width:'100%', backgroundColor:'#F6E8E1'}}>
                        <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center', marginTop:'120px'}}>Post Evaluation</h1>
                        <p style={{textAlign:'left', margin:'30px 60px 0px 60px', color:'black', fontSize:'15px'}}>
                        To know your child's progress, you may schedule a call with your assigned tutor or myself at any time.
                        Your assigned tutor will be sending a biweekly post evaluation report on your child's performance as a
                        whole (updates on lesson plans, praises, concerns, etc.) <br></br><br></br>
                        Your assigned tutor will be sending you weekly reports on your child's particular individual classwork
                        performance and the homework results, so you can keep track of improvement as time passes. Your
                        assigned tutor will also be scheduling a call once a month with you, the parent, to give specialized notes on
                        your child. If there is something of concern occurring, your assigned tutor or I will call you ahead of the
                        monthly scheduled call, so we can solve any real-time obstacle.
                        </p>
                        </div>

                        <div style={{height:'80px', width:'100%', backgroundColor:'#F6E8E1', marginTop:'430px'}}>
                        <p style={{textAlign:'center', margin:'10px 40px 0px 40px', fontSize:'13px', color:'gray'}}><i>
                        Beck College Prep Prides itself in being the top tutoring company in Miami, with over 400 students enrolled in the program each year. Beck College Prep has helped 3000+ high school students increase their scores and get into the colleges of their dreams.</i>
                        </p>
                        </div>
                    </div>
                    
                    <div style={{backgroundImage:`url(${page13})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    
                    </div>
                    
                    <div style={{backgroundImage:`url(${yellowBubble})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    <table style={{width:'50%', marginTop:'180px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th>
                                        <p className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize:'25px', margin:'0'}}>
                                            Mandatory One-Time Fee
                                        </p>
                                        <p style={{margin:'0', color:'white', textAlign:'center', paddingBottom:'5px', fontSize:'17px'}}>Applies only to SAT / ACT Tutoring</p>
                                        </th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-all', width:'100%', paddingTop:'20px', paddingBottom:'20px'}}>
                                        
                                        <p className={styles.headings} style={{color:'black',textAlign:'center', fontWeight:'lighter', fontSize:'25px', margin:'0'}}>
                                            $200
                                        </p>
                                        <p style={{textAlign:'center', margin:'0', color:'black'}}><b style={{fontWeight:'bolder'}}>Will Cover the Following: </b><br></br>
                                            Student's Custom Material <br></br>
                                            Diagnostics-Timed Practice Tests <br></br>
                                            Free Study Sessions, Flexible Homework Assistance
                                        </p>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center', marginTop:'60px', marginBottom:'0'}}>Tutoring Sessions</h1>
                        <p style={{textAlign:'center', marginBottom:'20px', color:'black'}}>
                            IN OFFICE and VIRTUAL
                        </p>
                        <p style={{textAlign:'left', margin:'0', color:'black'}}>
                        ·	Individual or One–on–One Tutoring_____________________________________$140/hr <br></br>
                        ·	2–Person Group_________________________________________________________$90/hr <br></br>
                        ·	Courses 3–6 Students___________________________________________________$80/hr <br></br>
                        ·	Courses 7–10 Students__________________________________________________$70/hr 
                        </p>
                        <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center', marginTop:'20px'}}>Additional Services</h1>
                        <p style={{textAlign:'center', margin:'0', color:'black', paddingBottom:'330px', backgroundColor:'#fff1d1'}}>
                        ·	College Advising (No Essay or Application assistance) ________________$250/hr<br></br>
                        ·	Academic Tutoring (Tutoring for School Classes)________________________$90/hr
                        </p>
                    </div>
                    
                    <div style={{backgroundImage:`url(${page10})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center', marginTop:'170px'}}>Diagnostic Policy</h1>
                    <p style={{textAlign:'left', margin:'30px 60px 0 60px', color:'black', fontSize:'14px'}}><b className={styles.headings}>The initial SAT and ACT Diagnostic exams needed to enroll in Beck College Prep are Free.</b>I We will ask for you to reserve your child's seat by submitting an executed credit card authorization form included in the Beck Billing Policies and Procedures (the last page of this document).<br></br><br></br>

                    <b className={styles.headings}>If we do not receive this executed form 24 hours prior to your child's diagnostic reservation,</b> we will be canceling their diagnostic. We ask for this executed document to ensure your good faith of your diagnostic reservation but we will NOT charge your card for the diagnostics.<br></br><br></br>

                    <b className={styles.headings}>If the diagnostic reservation is canceled</b> in less than 24 hours or your child does not attend the diagnostic, we will charge your credit card provided on the executed authorization form <b style={{fontWeight:'bolder'}}>$20.00</b>, and every time thereafter any other diagnostic reservations that are made and canceled in less than 24 hours or the student does not attend the diagnostic.<br></br><br></br>

                    We have a limited number of seats to take the diagnostic exam; therefore providing your child a seat in our diagnostic denies another student. Should you wish to reschedule the diagnostic, after the missed diagnostic or the cancellation of your reservation, your initial diagnostics will still be free.<br></br><br></br>
                    </p>

                    </div>
                    
                    <div style={{backgroundColor:'white', height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center', marginTop:'30px'}}>Scheduling Policy</h1>
                    <p style={{textAlign:'left', margin:'10px 60px 0 60px', color:'black', fontSize:'11px'}}>To meet every student's demand, we schedule and confirm each tutoring session in advance. When students reserve their session with their tutor, it impacts that tutor's availability with another student. Therefore a less than 24–hour notice to reschedule does not allow everyone in Beck College Prep an equal opportunity to receive the help they deserve.<br></br><br></br>

                    As we are committed to provide students with the flexibility to schedule their tutoring sessions, parents and students can still schedule sessions a week or more in advance. We strive to accomodate extracurriculars since we encourage our students to be well–rounded on their college
                    applications.
                    <br></br><br></br>
                    </p>
                    <table style={{width:'80%', marginTop:'0px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th>
                                        <p className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize:'16px', margin:'0'}}>
                                        One-on-One Tutoring Cancellation or Rescheduling Policy  
                                        </p>
                                        </th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-all', width:'100%', padding:'20px 10px 20px 10px'}}>
                                        <p style={{textAlign:'center', margin:'0', color:'black', fontSize:'11px'}}><b className={styles.headings} style={{fontWeight:'bolder'}}>Cancellation or Rescheduling More Than 24-Hour <br></br> Prior to the Scheduled Group Tutoring Session: </b><br></br>
                                            There will be no charge to the Client <br></br><br></br>
                                            <b className={styles.headings} style={{fontWeight:'bolder'}}>Cancellation or Rescheduling (With or Without Notice) Less Than 24 Hours Prior to the Scheduled Tutoring Session</b><br></br><br></br>
                                            We empathetically understand that emergencies occur; therefore, we do not charge the client for the first cancellation or rescheduling less than 24 hours prior to the scheduled tutoring session. <br></br><br></br>
                                            After the first cancellation, every time the client (student or parent) cancels or reschedules the tutoring session less than 24 hours prior to the tutoring session, the client will be charged 75% of the fee for the scheduled tutoring session. <br></br><br></br>
                                            Example <br></br>
                                            If the tutoring session scheduled is 1 hour, the client will be charged 75% of the 1 hour fee.<br></br>
                                            If the tutoring session scheduled is 2 hour, the client will be charged 75% of the 2 hour fee.
                                        </p>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{width:'80%', marginTop:'10px', border:'5px solid white'}}>
                            <tbody>
                                <tr style={{backgroundColor:'#D13B31'}}>
                                    <th>
                                        <p className={styles.headings} style={{color:'white',textAlign:'center', fontWeight:'lighter', fontSize:'16px', margin:'0'}}>
                                        Group Tutoring Cancellation or Rescheduling Policy 
                                        </p>
                                        </th>
                                </tr>
                                <tr style={{backgroundColor:'#F6E8E1'}}>
                                    <td style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'center', wordBreak: 'break-all', width:'100%',padding:'20px 10px 20px 10px'}}>
                                        <p style={{textAlign:'center', margin:'0', color:'black', fontSize:'11px'}}><b className={styles.headings} style={{fontWeight:'bolder'}}>Cancellation or Rescheduling More Than 24-Hour <br></br>Prior to the Scheduled Group Tutoring Session: </b><br></br>
                                            There will be no charge to the Client <br></br><br></br>
                                            <b className={styles.headings} style={{fontWeight:'bolder'}}>If a client in a group class or course needs to cancel or reschedule more than 24 hours prior to the scheduled tutoring session, the client can request the following:</b></p>
                                            <p style={{textAlign:'left', margin:'0', color:'black', fontSize:'11px'}}>
                                            <b className={styles.headings} style={{fontWeight:'bolder'}}>1.</b>	The client can coordinate with other members of the group or course to meet on a different day.<br></br>
                                            <b className={styles.headings} style={{fontWeight:'bolder'}}>2.</b>	The client can join another group that may be learning the same lesson as his/her original group on the day of the scheduled tutoring session.<br></br>
                                            <b className={styles.headings} style={{fontWeight:'bolder'}}>3.</b>	The client can schedule a one–on–one tutoring session to make–up the lesson taught on the day of the scheduled tutoring session.
                                            <br></br><br></br></p>
                                            <p style={{textAlign:'center', margin:'0', color:'black', fontSize:'11px'}}>
                                            <b className={styles.headings} style={{fontWeight:'bolder'}}>Cancellation (With or Without Notice) Less Than 24 Hours <br></br> Prior to the Scheduled Group Tutoring or Course Session<br></br><br></br></b>
                                            After the first cancellation, every time the client (student or parent) cancels or reschedules the tutoring session less than 24 hours prior to the tutoring session, the client will be charged 75% of the fee for the scheduled tutoring session. <br></br><br></br>
                                            Example: <br></br>
                                            If the group or course tutoring session scheduled is 2 hours, the client will be charged 75% of the 2 hour fee.<br></br><br></br>
                                            In order to remain on the same scheduled lessons as their group or course members, the student would need to
                                            join another group that may be learning the same lesson as his/her original group on the day of the scheduled tutoring session. The client can schedule a one–on–one tutoring session to make–up the lesson taught on the day of the scheduled tutoring session.<br></br><br></br>
                                            If a client is absent more than three sessions consecutively, regardless of the make–up accommodations, the student will be switched to a different platform (one–on–one, two–person group, etc.).
                                        </p>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div style={{backgroundImage:`url(${page12})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>
                    <div style={{height:'560px', width:'100%', backgroundColor:'#F6E8E1',padding:'0px 60px 0px 60px'}}>
                        <h1 className={styles.headings} style={{fontSize:'30px', color:'black', textAlign:'center', marginTop:'60px'}}> Billing Policy</h1>
                        <p style={{textAlign:'left', margin:'0', color:'black', fontSize:'12px'}}>The Beck College Prep Billing Department requires all clients to keep a credit card on file. The credit card on file will be automatically charged after the tutoring session has occurred.<br></br><br></br>

                        A weekly cumulative history (the Balance Statement) of tutoring sessions will be sent to the Beck College Prep client via email and will also be included in the Student's Beck College Drive Portal, which can be accessed by the client at any time.
                        </p>
                        <p style={{textAlign:'center', margin:'20px 0px 20px 0px', color:'black', fontSize:'12px'}}>
                        <b className={styles.headings} style={{fontWeight:'bolder'}}>The balance statement will include the following information:</b><br></br><br></br>
                        ·	The Name of the Student<br></br>
                        ·	The Type of Tutoring The Student Received<br></br>
                        ·	The Time the Tutoring Began<br></br>
                        ·	The Time the Tutoring Ended<br></br>
                        ·	The Length of the Tutoring Session<br></br>
                        ·	The Name of the Tutor who Performed the Tutoring Session<br></br>
                        ·	The Subject Matter Covered in the Tutoring Session<br></br>
                        ·	The Date the Credit Card Was Charged to Pay for the Tutoring
                        </p>
                        <p style={{textAlign:'left', margin:'0', color:'black', fontSize:'12px'}}>If the clients begin tutoring and the billing information is not provided within 24 hours of the tutoring session, Beck College Prep will charge a 10% penalty on the total balance to date of the tutoring fees owed (including the one–time fee, if has not yet been paid) once the Beck College Prep Billing Department receives the clients' credit card information. <br></br><br></br>

                        If a client's credit card is declined at the time in which payment is being processed by Beck College Prep, Beck College Prep will charge a 10% penalty on the total balance of the tutoring fees owed every time this occurs.

                        </p>
                    </div>
                    <div style={{backgroundColor:'#F6E8E1', width:'100%', height:'200px', marginTop:'470px'}}></div>
                    </div>
                    
                    <div style={{backgroundImage:`url(${billingPolicy})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>

                    </div>
                    
                    <div style={{backgroundImage:`url(${page19})`, height:'1122.5px', width:'100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex',alignItems:'center', flexDirection:'column'}}>

                    </div>
                </div>
                <Button onClick={() =>{
                    setConfirmation('generatingPDF')
                    var element = document.getElementById('recapPDF');
                    var opt = {
                        filename:     `${studentName}.pdf`,
                    }
                    html2pdf().set(opt).from(element).save().then(()=>{
                        setConfirmation('generatedPDF');
                        logRecapData();    
                    }
                    ).catch(()=> {
                        setConfirmation('error')
                    });
                }} type="primary" className={styles.submitBtn} style={{width:'100%', marginLeft:'0px', marginTop:'10px', height:'40px'}}>Generate PDF</Button>
            </div>
        </section>
        </div>
        </>
        )
}

export default SendRecap