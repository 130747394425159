import React, { useState, Fragment } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { transferStudent } from '../../../api/api'
import TooltipWrapper from '../../TooltipWrapper/TooltipWrapper'

const CLEAR_STATE_DELAY = 400

const TransferModal = ({ transferInfo, setTransferInfo, currentEventId, allEvents, refreshStudentList, refreshCalendar, eventType }) => {
    const [selection, setSelection] = useState('')
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const performTransfer = () => {
        transferStudent(currentEventId, transferInfo.student._id, selection, eventType)
            .then(response => {
                setSuccess(true)
            })
            .catch(err => {
                if (err.response.data.message == 'Validation Error.' && typeof err.response.data.data == 'string') {
                    setError(err.response.data.data) // The error returned in this instance will indicate that the student already exist in the target diagnostic
                } else {
                    setError('An unidentified error occurred when transfering the student. Please try again later.')
                }
            })
    }

    const handleModalHide = () => {
        setTransferInfo({ show: false, student: null })

        setTimeout(() => { // Timeout set to remove visual annoyance where content of modal changes as it closes
            setSelection('')
            setError(null)
        }, CLEAR_STATE_DELAY)

        if (success) {
            refreshStudentList()
            refreshCalendar()
            setSuccess(false)
        }
    }

    return (
        <Modal
            show={transferInfo.show}
            onHide={handleModalHide}
            size="lg"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontWeight: '300' }}>
                    {success ? (
                        <>
                            Student Successfully Transfered!
                        </>
                    ) : (
                        <>
                            {error ? (
                                <>
                                    Error:
                                </>
                            ) : (
                                <>
                                    Transfering <span style={{ fontWeight: '500' }}>{transferInfo.student?.studentName}</span> to:
                                </>
                            )}
                        </>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {success ? (
                    <>
                        Click close to continue.
                    </>
                ) : (
                    <>
                        {error ? (
                            <div>
                                {error}
                            </div>
                        ) : (
                            <>
                                <Form.Control
                                    as="select"
                                    required
                                    value={selection}
                                    onChange={(event) => setSelection(event.target.value)}
                                    className={"dropDownArrow"}
                                >
                                    <option value='' disabled>Event</option>
                                    {allEvents.map((event, i) => {

                                        function determineDropDownTitle(typeOfEvent) {
                                            if (typeOfEvent == 'diagnostic') {
                                                return event.date.start
                                            } else {
                                                return event.meetings[0].start
                                            }
                                        }

                                        return (
                                            <Fragment key={event.name + ' ' + i}>
                                                {event._id != currentEventId && (
                                                    <option value={event._id} onClick={console.log(eventType)}>{new Date(determineDropDownTitle(eventType)).toLocaleDateString('en-US', {timeZone: 'America/New_York'})} {event.name.toString()}</option>
                                                )}
                                            </Fragment>
                                        )
                                    })}
                                </Form.Control>
                                <p style={{ color: '#D22027', marginBottom: '0px', marginTop: '14px' }}>Warning: Student will not be notified of transfer.</p>
                            </>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className='bcp-general-button' onClick={handleModalHide}>Close</Button>
                {(!error && !success) && (
                    <>
                        {(selection == '' || !selection) ? (
                            <TooltipWrapper text={'Please select a target diagnostic'}>
                                <Button className='bcp-general-button' onClick={performTransfer} disabled={true} style={{ pointerEvents: 'none' }}>Transfer</Button>
                            </TooltipWrapper>
                        ) : (
                            <Button className='bcp-general-button' onClick={performTransfer} disabled={false}>Transfer</Button>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default TransferModal