import React, { useState, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styles from '../Settings.module.css'
import { getTestInventory, addExam, updateExam } from '../../../../api/api'
import { FaArrowDown, FaArrowUp, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { forEach } from 'jszip';

const ExamInventory = () => {

    const [tests, setTests] = useState([])
    const [show, setShow] = useState(false)
    const [clicked, setClicked] = useState(null)
    const [page, setPage] = useState(null)
    const [queryTest, setQueryTest] = useState(null)

    useEffect(() => {
        getTestInventory().then(res => {
            setTests(res.data)
        })
    },[])

    function renderPage(page) {
        switch(page) {
            case null:
                return (
                    <>
            
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <Button className={styles.bcpButton} style={{width:'100%', marginBottom:'20px'}} onClick={() => {
                                setPage('addExam')
                            }}>Add Exam</Button>
                            {
                                tests.map((item, i) => {
                                    return (<>
                                            <div 
                                            key={i} 
                                            style={{display:'flex', flexDirection:'row', width:'100%'}}
                                            onClick = {() => {
                                                setQueryTest(item)
                                                setTimeout(()=>{
                                                    setPage('viewExam')
                                                }, 100)
                                            }}>
                                                <p style={{width:'34%'}}>{item.testName}</p>
                                                <p style={{width:'33%', textAlign:'center'}}>{item.testType.toUpperCase()}</p>
                                                <div style={{width:'33%'}}><FaArrowRight style={{float:'right'}}/></div>
                                        </div></>)})}
                            
                        </div>
            
                    </>
                )
            case 'addExam':
                return <AddExam setPage={setPage}/>
            case 'viewExam':
                return <ViewExam setPage={setPage} queryTest={queryTest}/>
        }
    }

    return (
        renderPage(page)
    )
}

export default ExamInventory

const AddExam = ({setPage}) => {

    const [testName, setTestName] = useState('');
    const [testType, setTestType] = useState('');
    const [confirmation, setConfirmation] = useState(false);

    const [multipleDeficiencies, setMultipleDeficiencies] = useState([])
    const [addedDeficiencies, setAddedDeficiencies] = useState(multipleDeficiencies.length);

      function remove(item, list, countFunction) {
        list.forEach((ask, i) => {
            if(ask == item) {
                list.splice(i, 1)
                console.log(list)
                countFunction(list.length)
            } else {
                console.log('nope')
            }
        })
    }

    function satCount(index, testType) {
        if(testType == 'sat') {
            if (index > 20) {
                return (index-20)
            } else {
                return index
            }
        } else {
            return index
        } 
    }

    var listOfRows = []
    //const [listOfRows, setListOfRows] = useState([])

    function generateDeficiencyInputs(test) {
        if(test == 'act') {
            listOfRows=[]
            for(var i=0; i<60; i++) {
                listOfRows.push(
                    i+1
                )
            }
        }else if(test=='sat') {
            listOfRows=[]
            for(var o=0; o<58; o++) {
                listOfRows.push(
                    o+1
                )
            }
        }
    }

    function getAllDeficiencyInputs () {
        listOfRows.forEach(index => {
            var inputVal = document.getElementById(index.toString()).value;

            multipleDeficiencies.push(inputVal)
        })
    }

                return (
                    <>
                    <div className={styles.backLink}>
                    <span onClick={()=>{setPage(null)}}><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Exam Inventory</span>
                    </div>
                    <table style={{width:'100%'}}>
                        <tbody>
                            <tr>
                                <td>
                                    <p>Test Type:</p>                            
                                </td>
                                <td style={{display:'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                    <Form.Check type='radio' value='act' name='test' label='ACT' onClick={()=>{
                                    setTestType(event.target.value)
                                    listOfRows.forEach(index => {
                                        var inputVal = document.getElementById(index.toString());
                                        inputVal.value = ''
                                    })
                                }}></Form.Check>
                                    <Form.Check type='radio' value='sat' name='test' label='SAT' onClick={()=>{
                                    setTestType(event.target.value)
                                    listOfRows.forEach(index => {
                                        var inputVal = document.getElementById(index.toString());
                                        inputVal.value = ''
                                    })
                                }}></Form.Check>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Test Name:</p>
                                </td>
                                <td>
                                    <Form.Control required type='name' placeholder='Test Name' value={testName} style={{width:'100%'}} onChange={() => {
                                        setTestName(event.target.value)
                                    }}></Form.Control>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{width:'100%', marginTop:'10px'}}>
                        <tbody>
                            {generateDeficiencyInputs(testType)}
                            {(testType=='act' || testType=='sat')?(
                                listOfRows.map((index) => 
                                <>
                                    {(index == 1 && testType =='sat')?(
                                        <tr key ={'divider'+index} style={{height:'100px'}}>
                                            <td colSpan='2'>
                                            <h3 style={{textAlign:'center', margin:'0'}}>Non - Calc Questions</h3>
                                            </td>
                                        </tr>
                                        ):(index == 21 && testType =='sat')?(
                                            <tr key ={'divider'+index} style={{height:'100px'}}>
                                            <td colSpan='2'>
                                            <h3 style={{textAlign:'center', margin:'0'}}>Calculator Questions</h3>
                                            </td>
                                        </tr>
                                        ):(null)}
                                    <tr key={index} >
                                        <td>
                                            <p>Question {satCount(index, testType)}</p>
                                        </td>
                                        <td>
                                        <Form.Control
                                            type="text"
                                            name="deficiency"
                                            placeholder="Deficiency"
                                            id={index}
                                            />
                                        </td>
                                    </tr>
                                    </>
                                )
                            ):(null)}
                        </tbody>
                    </table>
                    <div>
                            {(addedDeficiencies == multipleDeficiencies.length)?(
                                    <>
                                    <table style={{width:'100%', marginTop:'10px'}}>
                                        <tbody>
                                            {multipleDeficiencies.map((weak, i) => 
                                                <tr key={i}>
                                                        <td style={{width:'10%'}}>
                                                            <Button className={styles.xBtn} onClick={()=>{remove(multipleDeficiencies[i], multipleDeficiencies, setAddedDeficiencies)}} ><b>x</b></Button>
                                                        </td >
                                                        <td style={{textAlign: 'left', color: 'black', width:'90%'}}>
                                                        {weak}
                                                        </td>
                                                </tr> 
                                            )}
                                        </tbody>
                                    </table>
                                    </>
                            ):(null)}
                            </div>
                            {testType? (
                                        <Button style={{width:'100%', marginTop:'20px'}} className={styles.bcpBtn} onClick={() => {
                                            getAllDeficiencyInputs()
            
                                            var data ={
                                                testName: testName,
                                                testType: testType,
                                                multipleDeficiencies: multipleDeficiencies
                                            }
            
                                            addExam(data).then(() => {
                                                setConfirmation(true)
                                            })
                                        }}>
                                                    Add Exam to Inventory
                                        </Button>
                                    ):(null)                        
                                    }
                                {confirmation? (
                                    <p style={{margin:'10px 0px 0px 0px', textAlign:'center', color:'#D13B31'}}>Exam Successfully Added to Inventory</p>
                                ):(null)}
                            </>
                ) 
        }

    const ViewExam = ({setPage, queryTest}) => {

        const [testName, setTestName] = useState(queryTest.testName);
        const [testType, setTestType] = useState(queryTest.testType);

        const [multipleDeficiencies, setMultipleDeficiencies] = useState(queryTest.multipleDeficiencies);
    
        function getAllDeficiencyInputs () {
            multipleDeficiencies.forEach((item, index) => {
                var inputVal = document.getElementById((index+1).toString().value);
                if(inputVal) {
                    multipleDeficiencies.splice(index, 1, inputVal)
                }
            })
        }
    
                    return (
                        <>
                        <div className={styles.backLink}>
                    <span onClick={()=>{setPage(null)}}><FaArrowLeft style={{width: 15, height: 15, marginBottom: 3, fontWeight: 600}} /> Back to Exam Inventory</span>
                    </div>
                        <table style={{width:'100%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Test Type:</p>                            
                                    </td>
                                    <td style={{display:'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                        <Form.Check type='radio' value='act' name='test' label='ACT' onClick={()=>{
                                        setTestType(event.target.value)
                                        listOfRows.forEach(index => {
                                            var inputVal = document.getElementById(index.toString());
                                            inputVal.value = ''
                                        })
                                    }}></Form.Check>
                                        <Form.Check type='radio' value='sat' name='test' label='SAT' onClick={()=>{
                                        setTestType(event.target.value)
                                        listOfRows.forEach(index => {
                                            var inputVal = document.getElementById(index.toString());
                                            inputVal.value = ''
                                        })
                                    }}></Form.Check>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Test Name:</p>
                                    </td>
                                    <td>
                                        <Form.Control required type='name' placeholder='Test Name' value={testName} style={{width:'100%'}} onChange={() => {
                                            setTestName(event.target.value)
                                        }} disabled></Form.Control>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{width:'100%', marginTop:'10px'}}>
                            <tbody>
                                {
                                    multipleDeficiencies.map((def, i) => {
                                        var temp = multipleDeficiencies.join('|')
                                       return <tr key={def+i}>
                                            <td>
                                                <p>Question {i+1}</p>
                                            </td>
                                            <td>
                                            <Form.Control
                                                type="text"
                                                name="deficiency"
                                                placeholder={def}
                                                id={i+1}
                                                onChange={()=>{
                                                    if(event.target.value) {
                                                        multipleDeficiencies.splice(i, 1, event.target.value)
                                                    } else {
                                                        multipleDeficiencies.splice(i, 1, temp.split("|")[i])
                                                    }
                                                }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <div>
                                </div>
                                {testType? (
                                            <Button style={{width:'100%', marginTop:'20px'}} className={styles.bcpBtn} onClick={() => {
                                                getAllDeficiencyInputs()
                
                                                var data ={
                                                    testName: testName,
                                                    testType: testType,
                                                    multipleDeficiencies: multipleDeficiencies
                                                }
                
                                                updateExam(queryTest._id, data)
                                            }}>
                                                        Finish Edit
                                            </Button>
                                        ):(null)                        
                                        }
                                </>
                    ) 
            }
