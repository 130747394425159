import React, { useState, useEffect } from 'react'
import styles from "./Filter.module.css"
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import { FaSearch } from 'react-icons/fa'
import { sanitizeFieldHeader } from '../../../utility/general'

const Filter = ({ getEventInfo, setFilterField, filterField, setFilterValue, filterValue, fields, topOfScreen }) => {
    const [searchReady, setSearchReady] = useState(false)
    const [submitReady, setSubmitReady] = useState(false)

    // User can only type into search bar if a filter field has been selected
    useEffect(() => {
        if (filterField != '' && filterField != undefined) {
            setSearchReady(true)
        } else {
            setSearchReady(false)
        }
    }, [filterField])

    // User can only press submit button if both a filter field and a filter value (that's not an empty string) were provided
    useEffect(() => {
        if (searchReady && filterValue && filterValue.length > 0) {
            setSubmitReady(true)
        } else {
            setSubmitReady(false)
        }
    }, [filterValue])

    // Update eventInfo on submit only if a filter field and value were provided
    const handleSubmit = (event) => {
        event.preventDefault()

        if (filterField && filterValue && filterValue.length > 0) {
            getEventInfo()
        }
    }

    // Search bar portion of the component, created this function to avoid duplicate code
    const renderSearchBar = () => {
        return (
            <Form.Control
                required
                type="text"
                placeholder="Search"
                name='filter'
                value={!filterValue ? '' : filterValue}
                onChange={(event) => setFilterValue(event.target.value)}
                className={styles.searchBar}
                disabled={!searchReady}
            />
        )
    }

    return (
        <div className={`${styles.mainContainer} d-flex align-items-center`}>
            <Form noValidate onSubmit={handleSubmit} className={`d-flex`}>
                <Form.Control
                    as="select" 
                    required
                    value={!filterField ? '' : filterField}
                    onChange={(event) => setFilterField(event.target.value)}
                    className={`${styles.dropDown} dropDownArrow`}
                >
                    <option key={'fieldDefault'} value={''}>Filter Field</option>
                    {fields.map((filterField, i) => {
                        return (
                            <option key={'filterField' + i} value={filterField}>{sanitizeFieldHeader(filterField)}</option>
                        )
                    })}
                </Form.Control>
                {!searchReady ? (
                    <OverlayTrigger className="bcp-tooltip" placement={topOfScreen ? 'bottom' : 'top'} overlay={
                        <Tooltip id="tooltip-disabled" className="bcp-tooltip">
                            Please choose a field to filter by
                        </Tooltip>
                    }>
                        {renderSearchBar()}
                    </OverlayTrigger>
                ):(
                    <div>
                        {renderSearchBar()}
                    </div>
                )}
                {!submitReady || !searchReady ? (
                    <OverlayTrigger className="bcp-tooltip" placement='bottom' overlay={
                        <Tooltip id="tooltip-disabled" className="bcp-tooltip">
                            {!searchReady ? 'Please choose a field to filter by' : 'Please provide a value to search for'}
                        </Tooltip>
                    }>
                        <div>
                            <Button type="submit" className={`${styles.submitButton} bcp-general-button`} disabled={true} style={{ pointerEvents: 'none' }}>
                                <FaSearch />
                            </Button>
                        </div>
                    </OverlayTrigger>
                ):(
                    <Button type="submit" className={`${styles.submitButton} bcp-general-button`}>
                        <FaSearch />
                    </Button>
                )}
            </Form>
        </div>
    )
}

export default Filter