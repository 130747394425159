
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../utility/general"

// stops a url from being accessable without the proper login token
const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const [user, setUser] = useState(null);
    const [render, setRender] = useState(false);

    useEffect(() => {
        setRender(renderRoute());
    }, [])
    
    async function renderRoute() {
        let loggedIn = await isLoggedIn();

        if (!loggedIn && location.pathname !== `/portal/signin`) {
            // alert('Sorry! Login expired. Sign back in to refresh.');
            navigate("/portal/signin");
            return false;
        }
        else {
            setUser(loggedIn.data);
            return true;
        }
    }

    return (
        <>
            {render && user ? <Component {...rest} user={user} /> : null}
        </>
    )
}

export default PrivateRoute