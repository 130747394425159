import React, {useState, useEffect} from 'react'
import 'react-resizable/css/styles.css';
import styles from './EventList.module.css';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FaSortUp, FaSortDown, FaCloudDownloadAlt, FaCog, FaRegThumbsUp, FaRegCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { HiOutlineRefresh } from 'react-icons/hi'
import { FiUserPlus } from 'react-icons/fi'
import { BiExpand, BiCollapse } from 'react-icons/bi'
import { 
            getDiagnostic, 
            deleteDiagnostic, 
            deleteCourse, 
            updateDiagnostic, 
            updateCourse, 
            hideDiagnosticRequest, 
            hideCourseRequest, 
            editDiagnosticRequest, 
            editCourseEnrolledStudent, 
            approveDiagnosticRequest,
            sendInformationToMonday, 
            setDiagnosticConfirmation,
            getCourse, 
            submitCourseApprovals,
            showDiagnosticRequest,
            showCourseRequest,
            createSchool
        } from '../../../../api/api'
import { handleDownload } from '../../../../utility/downloadCSV'
import AddStudentDiag from './AddStudentDiag.js'
import AddStudentCourse from './AddStudentCourse.js'
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import DatePicker from "react-datepicker"
import ErrorTooltip from '../../../ErrorTooltip/ErrorTooltip';
import TimePicker from 'rc-time-picker';
import moment from 'moment'
import FormControl from 'react-bootstrap/FormControl';
import Filter from '../Filter'
import { sanitizeFieldHeader } from '../../../../utility/general'
import TooltipWrapper from '../../../TooltipWrapper/TooltipWrapper'
import TransferModal from '../TransferModal';
//import EnrollStudent from '../EnrollButton/EnrollStudent'

const DIAG_SEARCH_FIELDS = ['studentName', 'testType', 'school', 'parentName', 'parentEmail'] // For filter
const COURSE_SEARCH_FIELDS = ['studentName', 'school', 'parentName', 'parentEmail'] // For filter
const DIAG_SCORE_HEADINGS = ['sr' ,'sg', 'sm','st','ag','am', 'ar','as','at']


// Component that deals with manipulating and viewing student data from a single event
const EventList = ({ eventId, refreshCalendar, eventType, allEvents, user}) => {

    const [studentList, setList] = useState([]); // List of student object requests
    const [eventInfo, setEventInfo] = useState(null); // Info related to the chosen event
    const [sortFlag, setSortFlag] = useState([]); // Array containing how the students are sorted
    const [listType, setListType] = useState('all'); // Which list is being displayed
    const [approvedList, setApprovedList] = useState([]); // List of student IDs to be approved
    const [showApprovalModal, setShowApprovalModal] = useState(false) // Show approval confirmation modal
    const [showExpandedInfo, setShowExpandedInfo] = useState(false) // Expand/collapse info
    const [showRegisterStudentDlg, setShowRegisterStudentDlg] = useState(false); // Show 'add student' dialogue
    const [showDisapproveModal, setShowDisapproveModal] = useState(false); // Show 'hide student' confirmation modal
    const [editingIndex, setEditingIndex] = useState(-1); // Index of the student entry being edited
    const [hidingIndex, setHidingIndex] = useState(-1); // Index of the student entry being deleted
    const [filterField, setFilterField] = useState('') // Which heading the search bar is using to search
    const [filterValue, setFilterValue] = useState('') // The search value
    const [showInfo, setShowInfo] = useState(false); // Show course Info
    const [approveLoading, setApproveLoading] = useState(false); // Loading state for the approval button
    const [transferInfo, setTransferInfo] = useState(false)

    useEffect(() => {
        getEventInfo();
    }, [])

    // Refresh event info when event changes, sorting is changed, or expanding info
    useEffect(() => {
        if(!showRegisterStudentDlg){
            getEventInfo();
        }
    }, [eventId, sortFlag])

    useEffect(() => {
        setEditingIndex(-1)
        filterStudentList()
    }, [showExpandedInfo])

    // Clear sorts and refresh on event change
    useEffect(() => {
        setSortFlag([])
        setEventInfo(null)
    }, [eventType])

    // Reset states on list type change
    useEffect(() => {
        filterStudentList()
        setApprovedList([])
        setEditingIndex(-1)
        setHidingIndex(-1)
    }, [listType])

    
    useEffect(() => {
        setEditingIndex(-1)
        if(eventInfo){
            filterStudentList()
        }
    }, [eventInfo])

    // Used to refresh list only after filter fields have been cleared
    useEffect(() => {
        if (filterValue == undefined || filterField == undefined) {
            setFilterValue('')
            setFilterField('')
            getEventInfo()
        }
    }, [filterField, filterValue])

    useEffect(() => {
        // Reset filters whenenver the current event changes
        setFilterField('')
        setFilterValue('')
    }, [eventId])


    useEffect(() => {
        if(editingIndex != -1)
            document.addEventListener('keydown', enterToSubmitEdit, { passive: true })

        return function cleanup() {
            document.removeEventListener('keydown', enterToSubmitEdit, { passive: true })
        }
    }, [editingIndex])

    // Function to submit edits when enter is pressed
    const enterToSubmitEdit = (event) => {
        if (event.code == 'Enter') {
            if (editingIndex != -1) {
                handleSubmitEdit()
            }
        }
    }

    // Function to change editing index state when a student is double-clicked
    const doubleClickToEdit = (index) => {
        // Only trigger editing if the following selections are made
        if ((eventType == 'diagnostic' && (listType == 'all' || listType == 'approved' || listType == 'requests')) ||
            (eventType != 'diagnostic' && (listType == 'approved'))) {
            setEditingIndex(index)
        }
    }
    
    // Function to refresh the student list
    const handleRefresh = () => {
        // Clear fields only if there are values to be cleared (there is a useEffect that will call getEventInfo if filter options are clear)
        if (filterValue && filterValue.length > 0 || filterField && filterField.length > 0) {
            setFilterField(undefined)
            setFilterValue(undefined)
        } else {
            getEventInfo()
        }
    }

    const getFilterableFields = () => {
        if (eventType == 'diagnostic') {
            return DIAG_SEARCH_FIELDS
        } else {
            return COURSE_SEARCH_FIELDS
        }
    }

    // Function to set the sort flag with the appropriate keys
    const handleSort = (key) => {
        let newSortFlag = sortFlag.slice();
        let sortKeyIndex = newSortFlag.findIndex(obj => obj[`sortField`] === key)
        if(sortKeyIndex === -1) {
            let newSortObj = {
                [`sortField`]: key,
                [`sortOrder`]: 'asc'
            }
            newSortFlag.push(newSortObj)
        }
        else{
            if(newSortFlag[sortKeyIndex].sortOrder === 'asc') {
                newSortFlag[sortKeyIndex].sortOrder = 'desc'
            }
            else if(newSortFlag[sortKeyIndex].sortOrder === 'desc') {
                newSortFlag.splice(sortKeyIndex, 1)
            }
        }
        setSortFlag(newSortFlag);
    }

    function checkRole(role) {
        if(role === 'ADMIN') {
            return false
        } else if(role === 'TUTOR') {
            return true
        }
    }

    // Function to retrieve the event info with the proper filters and sorts 
    const getEventInfo = () => {
        // Build query object
        let queries = {}

        if (sortFlag) {
            sortFlag.map((sortObj, i) => {
                queries[`sortField${i}`] = sortObj.sortField
                queries[`sortOrder${i}`] = sortObj.sortOrder
            })
        }

        if (filterField && filterValue && filterValue.length > 0) {
            queries.filterField = filterField
            queries.filterValue = filterValue
        }

        if (eventType !== 'diagnostic') {
            queries.combined = 'true'
        }

        setApprovedList([])
        if(eventType === 'diagnostic'){
            getDiagnostic(eventId, queries)
            .then( res => {
                setEventInfo(res.data)
            })
            .catch(err => console.log(err))
        }
        else {
            getCourse(eventId, queries)
            .then( res => {
                setEventInfo(res.data)
            })
            .catch(err => console.log(err))
        }
        
    }

    // Function to establish what student info gets shown/hidden
    const filterStudentList = () => {
        let info = [];
        if(eventType === 'diagnostic'){ // if event is a diagnostic
            if(eventInfo && eventInfo.studentRequests){
                info = eventInfo.studentRequests.slice();

                if(listType==='requests'){
                    info = info.filter(student => (!student.approved && !student.hidden))
                }
                else if(listType==='approved'){
                    info = info.filter(student => (student.approved && !student.hidden))
                }
                else if(listType==='hidden'){
                    info = info.filter(student => (student.hidden))
                }
                else if(listType === 'all') {
                    info = info.filter(student => (!student.hidden))
                }
                else{
                    info = res.data
                }
            }
        }
        else { // if event is a bootcamp
            if(eventInfo){
                info = eventInfo;

                if(info.requests && info.enrolledStudents && info.allStudents) {
                    if(listType==='requests'){
                        info = info.requests.slice().filter(student => (!student.approved && !student.hidden))
                    }
                    else if(listType==='approved'){
                        info = info.enrolledStudents.slice().filter(student => !student.hidden)
                    }
                    else if(listType==='hidden'){
                        info = info.allStudents.slice().filter(student => student.hidden)
                    }
                    else if(listType === 'all') {
                        info = info.allStudents.slice().filter(student => !student.hidden)
                    }
                    else{
                        info = res.data
                    }
                }
            }
        }

        let arr = []
        if(info.length > 0){
            arr = info.map(request => {
                // Anything that doesn't need to be displayed in a specific position is placed in ...extras
                let {
                    _id, 
                    parents,
                    reportEntry,
                    reportHidden,
                    tutorName,
                    testForm,
                    approved,
                    hidden, 
                    testType, 
                    studentName, 
                    studentPhone, 
                    gradeLevel, 
                    parentEmail, 
                    parentName, 
                    parentPhone, 
                    ...extras
                } = request;

                // New object with sorted headings
                let sortedDiagObj = null
                console.log('in method', showExpandedInfo)
                if(showExpandedInfo && eventType === 'diagnostic') { // Headings for expanded diagnostics
                    sortedDiagObj = {
                        _id: request._id,
                        '#': '-',
                        approved: request.approved,
                        confirmed: request.confirmed,
                        cc: request.cc,
                        attended: request.attended,
                        location: request.location,
                        testType: request.testType,
                        studentName: request.studentName,
                        gradeLevel: request.gradeLevel,
                        extendedTime: request.extendedTime,
                        satForm: request.satForm,
                        sr: request.sr,
                        sg: request.sg, 
                        sm: request.sm,
                        st: request.st,
                        actForm: request.actForm,
                        ag: request.ag,
                        am: request.am, 
                        ar: request.ar,
                        as: request.as,
                        at: request.at,
                        prevScores: request.prevScores,
                        school: request.school,
                        mathClass: request.mathClass,
                        studentPhone: request.studentPhone,
                        studentEmail: request.studentEmail,
                        parentName: request.parentName,
                        parentPhone: request.parentPhone,
                        parentEmail: request.parentEmail,
                        parentPhone: request.parentPhone,
                        parentName2: request.parentName2,
                        parentPhone2: request.parentPhone2,
                        parentEmail2: request.parentEmail2,
                        address: request.address,
                        ...extras
                    }
                }
                else if(showExpandedInfo && eventType !== 'diagnostic'){ // Headings for expanded courses
                    sortedDiagObj = {
                        _id: request._id,
                        '#': '-',
                        approved: request.approved,
                        studentName: request.studentName,
                        location: request.location,
                        gradeLevel: request.gradeLevel,
                        school: request.school,
                        studentPhone: request.studentPhone,
                        studentEmail: request.studentEmail,
                        parentName: request.parentName,
                        parentPhone: request.parentPhone,
                        parentEmail: request.parentEmail,
                        parentName2: request.parentName2,
                        parentPhone2: request.parentPhone2,
                        parentEmail2: request.parentEmail2,
                        ...extras
                    }
                }
                else if(eventType === 'diagnostic') { // Headings for collapsed diagnostics
                    sortedDiagObj = {
                        _id: request._id,
                        '#': '-',
                        approved: request.approved,
                        confirmed: request.confirmed,
                        cc: request.cc,
                        attended: request.attended,
                        location: request.location,
                        testType: request.testType,
                        studentName: request.studentName,
                        gradeLevel: request.gradeLevel,
                        extendedTime: request.extendedTime,
                        satForm: (request.satForm? request.satForm : null),
                        sr: request.sr,
                        sg: request.sg, 
                        sm: request.sm,
                        st: request.st,
                        actForm: (request.actForm? request.actForm : null),
                        ag: request.ag,
                        am: request.am, 
                        ar: request.ar,
                        as: request.as,
                        at: request.at,
                        prevScores: request.prevScores
                    }
                }
                else {
                    sortedDiagObj = { // Headings for collapsed courses
                        _id: request._id,
                        '#': '-',
                        approved: request.approved,
                        studentName: request.studentName,
                        location: request.location,
                        gradeLevel: request.gradeLevel,
                        parentEmail: request.parentEmail
                    }
                }
                return sortedDiagObj;
            })
        }
        setList(arr);
    }
    
    // Function to add IDs to the array of IDs to approve
    const handleCheck = (e, request) => {
        let newApprovedList = approvedList.slice();
        if(e.target.checked)
            newApprovedList.push(request._id)
        else {
            const index = approvedList.indexOf(request._id);
            if (index > -1) {
                newApprovedList.splice(index, 1);
            }
        }
        console.log(newApprovedList)
        setApprovedList(newApprovedList)
    }

    // Submit approvals for list of students in approvedList
    const submitApprovals = () => {
        setApproveLoading(true)
        let approvedData = {
            requests: approvedList
        }

        sendInformationToMonday(eventInfo._id, approvedData)
            .then(res => {
                console.log(res);
            })
            .then(()=>getEventInfo())
            .catch(err => {console.log(err); setApproveLoading(false)})

        if(eventType === 'diagnostic')

            approveDiagnosticRequest(eventInfo._id, approvedData)
            .then(res => {
                setApproveLoading(false)
                console.log(res);
                setShowApprovalModal(false);
                refreshCalendar();
            })
            .then(()=>getEventInfo())
            .catch(err => {console.log(err); setApproveLoading(false)})
        else    
            submitCourseApprovals(eventInfo._id, approvedData)
            .then(res => {
                console.log(res);
                setApproveLoading(false)
                setShowApprovalModal(false);
                refreshCalendar();
            })
            .then(()=>getEventInfo())
            .catch(err => console.log(err))
    }

    // Function to submit a single students confirmation (used to be built for multiple students at once)
    const submitConfirmation = (id, e) => {

        let dummyArr = [{studentId: id, value: e.target.checked, name: e.target.name}]
        let approvedData = {
            requests: dummyArr,
        }

        setDiagnosticConfirmation(eventInfo._id, approvedData)
        .then(res => {
            console.log(res);
        })
        .then(()=>getEventInfo())
        .catch(err => console.log(err))
        
    }

    const handleShowExpandedInfo = () => {
        if(showExpandedInfo){
            setShowExpandedInfo(false)
        } 
        else{
            setShowExpandedInfo(true)
        }
    }

    // Sub-component modal for approvals
    const ConfirmationModal = () => {
        return(
            <Modal
                    show={showApprovalModal}
                    onHide={() => setShowApprovalModal(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Body>
                        <p>Are you sure you want to submit approvals?</p>
                        <p style={{color: '#D22027'}}>Number of new Approvals: {approvedList.length} / 
                        {eventInfo? eventInfo.enrollmentMax : '32'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => {
                            setShowApprovalModal(false);
                            submitApprovals();
                        }}>
                            Submit
                        </Button>
                    <Button variant="secondary" onClick={() => setShowApprovalModal(false)}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
        )
    }

    // BIG sub-component
    const EventOptions = ({diagnosticInfo, getList, setEventInfo}) => {

        const [showDelete, setShowDelete] = useState(false);
        const [showEdit, setShowEdit] = useState(false);
        const [showAddSchool, setShowAddSchool] = useState(false);
    
        return(
            <>
            <Dropdown className={`${styles.settingsBtn}`}>
                <Dropdown.Toggle as={CustomDropdown}></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={CustomItem} onClick={() => {setShowEdit(true)}}>Edit</Dropdown.Item>
                    <Dropdown.Item as={CustomItem} onClick={() => {setShowDelete(true)}}>Delete</Dropdown.Item>
                    <Dropdown.Item as={CustomItem} onClick={() => {handleDownload(eventInfo, (eventType === 'diagnostic' ? 'singleDiagnostic' : 'singleCourse'))}}>Export to CSV</Dropdown.Item>
                    <Dropdown.Item as={CustomItem} onClick={() => {setShowAddSchool(true)}}>Add New School Name</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <EditModal diagnosticInfo={diagnosticInfo} showEdit={showEdit} setShowEdit={setShowEdit} getList={getList}/>
            <DeleteModal 
                diagnosticInfo={diagnosticInfo} 
                showDelete={showDelete} 
                setShowDelete={setShowDelete} 
                getList={getList}
                setEventInfo={setEventInfo}
            />
            <AddSchoolModal showAddSchool={showAddSchool} setShowAddSchool={setShowAddSchool}/>
            </>
        )
    }

    // Delete Dialogue for Event Options
    const DeleteModal = ({diagnosticInfo, showDelete, setShowDelete, getList, setEventInfo}) => {
        const [loading, setLoading] = useState(false);
        const [deleted, setDeleted] = useState(false);
    
        const handleDelete = (id) => {
            setLoading(true);
            console.log(id);
            
            if(eventType === 'diagnostic')
                deleteDiagnostic(id)
                .then(res => {
                    console.log(res);
                    setLoading(false);
                    setDeleted(true);
                    getList();
                    setEventInfo(null);
                    refreshCalendar()
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
            else
                deleteCourse(id)
                .then(res => {
                    console.log(res);
                    setLoading(false);
                    setDeleted(true);
                    getList();
                    setEventInfo(null);
                    refreshCalendar()
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        }
    
        const handleCloseDelete = () => {
            setShowDelete(false);
            setTimeout(()=> {
                setLoading(false);
                setDeleted(false);
            }, 500);
        }
    
        return (
            
            <>
            <Modal
                show={showDelete}
                onHide={handleCloseDelete}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    {eventType==='diagnostic'
                    ?
                        (!deleted 
                        ? 
                            <>
                            <p>Are you sure you want to delete {diagnosticInfo.name}?</p>
                            <p>Date: {`${new Date(diagnosticInfo.date.start).getMonth()+1}/${new Date(diagnosticInfo.date.start).getDate()}/${new Date(diagnosticInfo.date.start).getFullYear()}`}</p>
                            <p>Time: {`${new Date(diagnosticInfo.date.start).toLocaleTimeString()}`}</p>
                            <p style={{color: '#D22027'}}>Number of Requests: {diagnosticInfo.studentRequests.length}</p>
                            </>
                        : `Diagnostic successfully deleted`
                        )
                    :
                        (!deleted 
                        ? 
                            <>
                            <p>Are you sure you want to delete {diagnosticInfo.name}?</p>
                            <p style={{color: '#D22027'}}>Number of Requests: {diagnosticInfo.enrolledStudents.length}</p> {/* Only enrolled?*/}
                            </>
                        : `Course successfully deleted`
                        )
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    {deleted 
                    ?   null
                    : 
                        (loading ? 
                            <Button 
                                variant="primary"
                                disabled
                                style={{minWidth: '79px'}}
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                        :
                            <Button variant="danger" onClick={() => handleDelete(diagnosticInfo._id)}>
                                Delete
                            </Button>
                        )
                    }
                <Button variant="secondary" onClick={handleCloseDelete}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }

    // Edit Dialogue for Event Options
    const EditModal = ({showEdit, setShowEdit, diagnosticInfo, getList}) => {
            const [edited, setEdited] = useState(false);
            const [name, setName] = useState(diagnosticInfo.name);
            const [description, setDesc] = useState(diagnosticInfo.description)
            const [max, setMax] = useState(diagnosticInfo.enrollmentMax)
            const [diagnosticDate, setDate] = useState(diagnosticInfo.date ? new Date(diagnosticInfo.date.start) : null);
            const [startTime, setStart] = useState(moment(diagnosticInfo.date ? diagnosticInfo.date.start : null)); // modal start datenew Date(diagnosticInfo.date.start)
            const [endTime, setEnd] = useState(moment(diagnosticInfo.date ? diagnosticInfo.date.end : null)); // modal end date
            const [errors, setErrors] = useState(['', '', '']); // list of errors
            const [loadingEdit, setLoadingEdit] = useState(false); 
            const [sendEmail, setSendEmail] = useState(false); 

        useEffect(() => {
            if(showEdit){
                setName(diagnosticInfo.name)
                setDesc(diagnosticInfo.description)
                setDate(diagnosticInfo.date ? new Date(diagnosticInfo.date.start) : null);
                setStart(moment(diagnosticInfo.date ? diagnosticInfo.date.start : null))
                setEnd(moment(diagnosticInfo.date ? diagnosticInfo.date.end : null))
            }
            
        }, [showEdit])

        // clears the error from a single form, using the form's index
        const clearError = (index) => {
            let copy = errors.slice();
            copy[index] = '';
        }
    
        const handleEdit = (id) => {
            if(eventType === 'diagnostic') {
                setLoadingEdit(true);
                console.log(id);
                // let name = document.getElementById('name').value;
                // let description = document.getElementById('desc').value;
                
                let date = {
                    start: new Date(
                        diagnosticDate.getFullYear(),
                        diagnosticDate.getMonth(),
                        diagnosticDate.getDate(),
                        moment(startTime).hour(),
                        moment(startTime).minute(),
                        0, 0
                    ),
                    end: new Date(
                        diagnosticDate.getFullYear(),
                        diagnosticDate.getMonth(),
                        diagnosticDate.getDate(),
                        moment(endTime).hour(),
                        moment(endTime).minute(),
                        0, 0
                    ),
                }
        
                let diagnosticObject;


                if(max === ''){
                    diagnosticObject = {
                        studentRequests: diagnosticInfo.studentRequests,
                        name: name, 
                        description: description, 
                        date: date,
                        enrollmentMax: 32,
                        sendEmail: sendEmail
                    }
                }
                else{
                    diagnosticObject = {
                        studentRequests: diagnosticInfo.studentRequests,
                        name: name, 
                        description: description, 
                        date: date,
                        enrollmentMax: max,
                        sendEmail: sendEmail
                    }
                }
        
                updateDiagnostic(id, diagnosticObject)
                .then(res => {
                    console.log(res);
                    setEdited(true);
                    setLoadingEdit(false);
                    getList();
                    getEventInfo();
                })
                .catch(function (err) {
                    console.log(err.response);
                    console.log(err.response.status)
                    if(err.response.data.status === 500){
                        alert(err.response.data.message)
                    }
                    else if(err.response.status === 401) {
                        alert('Sorry! Login expired. Sign back in to refresh.');
                        navigate("/portal/signin");
                    }
                    else {
                        initializeFlags(err.response.data.data, setErrors)
                    }
                    setLoadingEdit(false);
                });
            }
            else{
                setLoadingEdit(true);

                let courseObject;
                if(max === ''){
                    courseObject = {
                        name: name, 
                        description: description,
                        meetings: diagnosticInfo.meetings,
                        enrollmentMax: 20
                    };
                }
                else{
                    courseObject = {
                        name: name, 
                        description: description,
                        meetings: diagnosticInfo.meetings,
                        enrollmentMax: max
                    };
                }

                updateCourse(id, courseObject)
                .then(res => {
                    console.log(res);
                    setEdited(true);
                    setLoadingEdit(false);
                    getList();
                    getEventInfo();
                })
                .catch(function (err) {
                    console.log(err.response);
                    console.log(err.response.status)
                    if(err.response.data.status === 500){
                        alert(err.response.data.message)
                    }
                    else if(err.response.status === 401) {
                        alert('Sorry! Login expired. Sign back in to refresh.');
                        navigate("/portal/signin");
                    }
                    else {
                        initializeFlags(err.response.data.data, setErrors)
                    }
                    setLoadingEdit(false);
                });
            }
        }
    
        const handleCloseEdit = () => {
            console.log('closing')
            setShowEdit(false);
            setTimeout(()=> {
                setLoadingEdit(false);
                setEdited(false);
                setErrors(['','',''])
                setName('')
                setDesc('')
                setDate(null);
                setStart(null)
                setEnd(null);
                setSendEmail(false);
            }, 500);
        }

        return (
            <>
            <Modal
                show={showEdit}
                onHide={handleCloseEdit}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <h4 style={{color: 'grey', marginBottom: '0px'}}>Edit {diagnosticInfo.name}</h4>
                </Modal.Header>
                <Modal.Body>
                    {eventType === 'diagnostic'
                    ? 
                        <>
                        <Form id='requestForm'>
                            <Form.Group>
                            <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                                {errors[0].length > 0 ? <ErrorTooltip id={'name'} errorMsg={errors[0]}/> : null}
                                <Form.Control 
                                    value={name} 
                                    onChange={(e) => setName(e.target.value)} 
                                    // onKeyDown={event => {
                                    //     if (event.key === ' ') {
                                    //         setName(name + ' ')
                                    //     }
                                    // }}
                                    type='text' id='name' placeholder="Name"
                                />
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(1)} >
                                {errors[1].length > 0 ? <ErrorTooltip id='desc' errorMsg= {errors[1]}/> : null}
                                <Form.Control value={description} onChange={(e) => setDesc(e.target.value)} 
                                    // onKeyDown={event => {
                                    //     if (event.key === ' ') {
                                    //         setDesc(description + ' ')
                                    //     }
                                    // }}
                                    type='text' id='desc' placeholder="Description" required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control  
                                    value={max} 
                                    onChange={(e) => setMax(e.target.value)} 
                                    type='text' 
                                    id='max' 
                                    placeholder="Max Approved Students"/>
                            </Form.Group>
                            <Form.Group className='d-flex'>
                                <p style={{marginRight: '10px', marginTop: 'auto', marginBottom: 'auto', fontSize: '20px'}}>Date: </p>
                                <DatePicker
                                    className={''}
                                    id='date'
                                    selected={diagnosticDate}
                                    onChange={date => setDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                />
                            </Form.Group>
                            <div onFocus={()=>clearError(2)} style={{position: 'relative'}}>
                                {errors[2].length > 0 ? <ErrorTooltip id='time' errorMsg={errors[2]}/> : null}
                                <Form.Group className='d-flex'>
                                    <p style={{marginBottom: 'auto', marginTop: 'auto', marginRight: '10px', fontSize: '20px'}}>Time: </p>
                                    <TimePicker 
                                        className='timePicker'
                                        use12Hours 
                                        showSecond = {false} 
                                        format = 'h:mm a' 
                                        placeholder = 'Start' 
                                        value = {startTime} 
                                        minuteStep = {15} 
                                        onChange = {(time)=>setStart(time)}
                                    />
                                    <p style={{margin: 'auto 5px auto 5px', fontWeight: '600'}}> - </p>
                                    <TimePicker
                                        className='timePicker'
                                        use12Hours 
                                        showSecond = {false} 
                                        format = 'h:mm a' 
                                        placeholder = 'End' 
                                        value = {endTime}  
                                        minuteStep = {15} 
                                        onChange = {(time)=>setEnd(time)}
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                        <div className ='d-flex align-items-center'>
                            <input  
                                style={{}}
                                type="checkbox" 
                                onChange={(e) => {console.log(e.target.checked); setSendEmail(e.target.checked)}}
                            /> 
                            <p style={{marginBottom: '0px', marginLeft: '5px'}}>Send Email Notification</p>
                        </div>
                        </>
                    :   
                        <>
                        <Form id='requestForm'>
                            <Form.Group>
                            <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                                {errors[0].length > 0 ? <ErrorTooltip id={'name'} errorMsg={errors[0]}/> : null}
                                <Form.Control 
                                    value={name} 
                                    onChange={(e) => setName(e.target.value)} 
                                    // onKeyDown={event => {
                                    //     if (event.key === ' ') {
                                    //         setName(name + ' ')
                                    //     }
                                    // }}
                                    type='text' id='name' placeholder="Name"
                                />
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
                                {errors[1].length > 0 ? <ErrorTooltip id='desc' errorMsg= {errors[1]}/> : null}
                                <Form.Control 
                                    value={description} 
                                    onChange={(e) => setDesc(e.target.value)} 
                                    // onKeyDown={event => {
                                    //     if (event.key === ' ') {
                                    //         setDesc(description + ' ')
                                    //     }
                                    // }}
                                    type='text' 
                                    id='desc' 
                                    placeholder="Description" 
                                    required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control  
                                    value={max} 
                                    onChange={(e) => setMax(e.target.value)} 
                                    type='text' 
                                    id='max' 
                                    placeholder="Max Approved Students"/>
                            </Form.Group>
                        </Form>
                        <p style={{color: '#D22027'}}>WARNING: Enrolled Students ({diagnosticInfo.enrolledStudents.length}) will not be notified of any changes.</p>
                        </>           
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    {edited 
                    ?   null
                    : 
                        (loadingEdit ? 
                            <Button 
                                variant="primary"
                                disabled
                                style={{minWidth: '79px'}}
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                        :
                            <Button variant="danger" onClick={() => handleEdit(diagnosticInfo._id)}>
                                Submit
                            </Button>
                        )
                    }
                <Button variant="secondary" onClick={handleCloseEdit}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }

    // Modal for school name input
    const AddSchoolModal = ({showAddSchool, setShowAddSchool}) => {
        const [loading, setLoading] = useState(false);
        const [added, setAdded] = useState(false);
    
        const handleAdd = (name) => {
            setLoading(true);
            let data = {name: name}
            createSchool(data)
            .then(res => {
                setLoading(false)
                setAdded(true)
            })
            .catch(err => console.log(err))
        }
    
        const handleCloseAdd = () => {
            setShowAddSchool(false);
            setTimeout(()=> {
                setLoading(false);
                setAdded(false);
            }, 500);
        }
    
        return (
            
            <>
            <Modal
                show={showAddSchool}
                onHide={handleCloseAdd}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add School Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    {!added
                    ? 
                        <Form.Group>
                            <Form.Control  className = {``} id='schoolName' placeholder="School Name" required/>
                        </Form.Group>
                    : `School name successfully added`
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    {added
                    ?   null
                    : 
                        (loading ? 
                            <Button 
                                variant="primary"
                                disabled
                                style={{minWidth: '79px'}}
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                        :
                            <Button variant="danger" onClick={() => handleAdd(document.getElementById('schoolName').value)}>
                                Add
                            </Button>
                        )
                    }
                <Button variant="secondary" onClick={handleCloseAdd}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }

    // Sub-component for student options dropdown
    const renderStudentOptions = (student, index) => {

        return (
            <Dropdown>
                <Dropdown.Toggle as={CustomDropdown}></Dropdown.Toggle>
                <Dropdown.Menu>
                    {listType !== 'hidden'
                    ?
                    <>
                        {/* {eventType !== 'diagnostic' && listType === 'all' ?
                            <Dropdown.Item as={CustomItem}>No Current Options</Dropdown.Item>
                        :
                            null
                        } */}

                        {listType === 'all' ?
                            <Dropdown.Item as={CustomItem} onClick={() => {setEditingIndex(index)}}>Edit</Dropdown.Item>
                        :
                            null
                        }
                        
                        {listType === 'all' ?
                            <Dropdown.Item as={CustomItem} onClick={() => {setHidingIndex(index); setShowDisapproveModal(true)}}>Hide</Dropdown.Item>
                        :
                            null
                        }

                        {listType === 'all' ?
                            <Dropdown.Item as={CustomItem} onClick={() => setTransferInfo({ show: true, student: studentList[index]})}>Transfer Student</Dropdown.Item>
                        :
                            null
                        }
                    </>
                    :
                        (eventType === 'diagnostic' ?
                            <Dropdown.Item as={CustomItem} 
                                onClick={() => {
                                    setHidingIndex(index); 
                                    showDiagnosticRequest(eventInfo._id, studentList[index]._id)
                                    .then(res => {
                                        getEventInfo()
                                        setHidingIndex(-1)
                                        console.log(res);
                                        refreshCalendar()
                                    })
                                    .catch(err => console.log(err))
                                }}
                            >Unhide</Dropdown.Item>
                        :
                            <Dropdown.Item as={CustomItem} 
                                onClick={() => {
                                    setHidingIndex(index); 
                                    showCourseRequest(eventInfo._id, studentList[index]._id)
                                    .then(res => {
                                        getEventInfo()
                                        setHidingIndex(-1)
                                        console.log(res);
                                        refreshCalendar()
                                    })
                                    .catch(err => console.log(err))
                                }}
                            >Unhide</Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    // Submit student entry edit
    const handleSubmitEdit = () => {
        let edits = Array.from(document.getElementsByClassName('edit-student-input'))
        let editObject = {}
        let count = 0;
        Object.entries(handleHeadings()).map((key, i) => {
            if(key[0] !== 'approved' && key[0] !== 'confirmed' && key[0] !== 'cc' && key[0] !== '_id' && key[0] !== '#' && key[0] !== 'timeRequested' && key[0] !== 'studentPhone') {
                if(key[0] === 'extendedTime'){
                    editObject[key[0]] = edits[count].value
                    count++
                }
                else if(key[0] === 'gradeLevel'){
                    editObject[key[0]] = (edits[count].value === "None" ? null : edits[count].value)
                    count++
                }
                else {
                    console.log(count)
                    console.log(edits)
                    editObject[key[0]] = (edits[count].value === '' ? null : edits[count].value)
                    count++
                }
            }
        })

        console.log(editObject)

        if(eventType === 'diagnostic')
            editDiagnosticRequest(eventInfo._id, studentList[editingIndex]._id, editObject)
            .then(res => {
                setEditingIndex(-1);
                getEventInfo();
            })
            .catch(err => {
                alert(err)
                setEditingIndex(-1);
            })
        else
            editCourseEnrolledStudent(eventInfo._id, studentList[editingIndex]._id, editObject)
            .then(res => {
                setEditingIndex(-1);
                getEventInfo();
            })
            .catch(err => {
                alert(err)
                setEditingIndex(-1);
            })
    }

    // Custom dropdown
    const CustomDropdown = React.forwardRef(({onClick}, ref) => (
        <div
            href=''
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e)
            }}
            className={styles.settingsIcon}
        >
            <FaCog/>
        </div>
    ));
    
    // Custom dropdown item
    const CustomItem = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy, onClick }, ref) => {
      
          return (
            <div
              ref={ref}
              style={style}
              className={className}
              aria-labelledby={labeledBy}
              onClick = {onClick}
            >
              {children}
            </div>
          ); 
        },
    );

    // Dialogue to confirm disapproval of a student
    const DisapproveModal = () => {
        
        const handleDisapprove = () => {
            if(eventType === 'diagnostic')
                hideDiagnosticRequest(eventInfo._id, studentList[hidingIndex]._id)
                .then(res => {
                    getEventInfo()
                    setHidingIndex(-1)
                    console.log(res);
                    refreshCalendar()
                })
                .catch(err => console.log(err))
            else   
                hideCourseRequest(eventInfo._id, studentList[hidingIndex]._id)
                .then(res => {
                    getEventInfo()
                    setHidingIndex(-1)
                    console.log(res);
                    refreshCalendar()
                })
                .catch(err => console.log(err))
                
        }

        return(
            <Modal
                show={showDisapproveModal}
                onHide={() => setShowDisapproveModal(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <p>Are you sure you want to disapprove this student?</p>
                    <p>Name: {(hidingIndex !== -1 && studentList.length > 0) ? studentList[hidingIndex].studentName : null}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="danger" 
                        onClick={() => {
                            setShowDisapproveModal(false)
                            handleDisapprove();
                        }}
                    >
                        Submit
                    </Button>
                <Button variant="secondary" onClick={() => setShowDisapproveModal(false)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    // Function to find the object with the most keys to use those as list headings 
    const handleHeadings = () => {
        if(eventType !== 'diagnostic')
            return studentList.slice().sort((a, b) => a.hasOwnProperty("school") ? -1 : b.hasOwnProperty("school") ? 1 : 0)[0] 
        else
            return studentList.slice().sort((a, b) => a.hasOwnProperty("location") ? -1 : b.hasOwnProperty("location") ? 1 : 0)[0]
    }

    // Function to handle the list headings format
    const renderTableHeadings = () => {
        return (
            <>
            {Object.entries(handleHeadings()).map((key, i) => {
                if(key[0] !== '_id') { // skip _id
                    // if key is sortable
                    if(key[0] === 'studentName' 
                        || key[0] === 'approved'
                        || key[0] === 'confirmed'
                        || key[0] === 'cc'
                        || key[0] === 'parentName' 
                        || key[0] === 'testType' 
                        || key[0] === 'school' 
                        || key[0] === 'gradeLevel'
                        || key[0] === 'attended'
                        || key[0] === 'extendedTime'
                        || key[0] === 'mathClass'
                        || key[0] === 'codeUsed'
                        || key[0] === 'location'
                        || key[0] === 'satForm'
                        || key[0] === 'actForm'
                        || DIAG_SCORE_HEADINGS.find(element => element === key[0])
                    ) { 
                        let foundField = sortFlag.find(obj => obj[`sortField`] === key[0])
                        let foundIndex = sortFlag.findIndex(obj => obj[`sortField`] === key[0]) + 1
                        // if no sort selected or current key is not being sorted
                        if(foundField === undefined){ 
                            return (
                                <th key={i + eventId + 'th'} onClick={() => handleSort(key[0])} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                    {key[0] === 'gradeLevel' 
                                    ?   'Grade'
                                    :   (key[0] === 'extendedTime' 
                                        ?   'ET'
                                        :   (key[0] === 'confirmed' 
                                            ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                    Confirmed
                                                </p>
                                            : (key[0] === 'cc' 
                                            ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                    CC
                                                </p>
                                            :   (key[0] === 'satForm' 
                                                ?   'SAT Form'
                                                :   (key[0] === 'actForm' 
                                                    ?   'ACT Form'
                                                    :   sanitizeFieldHeader(key[0])
                                                    )
                                                )
                                            )
                                        )
                                    )
                                    }
                                </th>
                            )
                        }
                        // if ascending sort
                        else if(foundField.sortOrder === 'asc') {
                            return (
                                <th key={i + eventId + 'th'} onClick={() => handleSort(key[0])} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                    <div className='d-flex'>
                                        {key[0] === 'gradeLevel' 
                                        ?   'Grade'
                                        :   (key[0] === 'extendedTime' 
                                            ?   'ET'
                                            :   (key[0] === 'confirmed' 
                                                ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                        Confirmed
                                                    </p>
                                                : (key[0] === 'cc' 
                                                ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                        CC
                                                    </p>
                                                :  (key[0] === 'satForm' 
                                                    ?   'SAT Form'
                                                    :   (key[0] === 'actForm' 
                                                        ?   'ACT Form'
                                                        :   sanitizeFieldHeader(key[0])
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                        }
                                        <FaSortUp style={{width: '10px', color: 'grey'}}></FaSortUp>
                                        <p style={{fontSize: '12px', marginBottom: '0px'}}>({foundIndex})</p>
                                    </div>
                                </th>
                            )
                        }  
                        // if descending sort
                        else if(foundField.sortOrder === 'desc') {
                            return (
                                <th key={i + eventId + 'th'} onClick={() => handleSort(key[0])} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                    <div className='d-flex'>
                                        {key[0] === 'gradeLevel' 
                                        ?   'Grade'
                                        :   (key[0] === 'extendedTime' 
                                            ?   'ET'
                                            :   (key[0] === 'confirmed' 
                                                ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                        Confirmed
                                                    </p>
                                                :  (key[0] === 'cc' 
                                                ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                        CC
                                                    </p>
                                                : (key[0] === 'satForm' 
                                                    ?   'SAT Form'
                                                    :   (key[0] === 'actForm' 
                                                        ?   'ACT Form'
                                                        :   sanitizeFieldHeader(key[0])
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                        }
                                        <FaSortDown style={{width: '10px', color: 'grey'}}></FaSortDown>
                                        <p style={{fontSize: '12px', marginBottom: '0px'}}>({foundIndex})</p>
                                    </div>
                                </th>
                            )
                        }                                               
                    }
                    // if key is not sortable
                    else{
                        return (
                            <th key={i + eventId + 'th'} className={`${styles.headings} student-table-heading ${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading}`}>
                                {key[0] === 'gradeLevel' 
                                ?   'Grade'
                                :   (key[0] === 'extendedTime' 
                                    ?   'ET'
                                    :   (key[0] === 'confirmed' 
                                        ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                Confirmed
                                            </p>
                                        :  (key[0] === 'cc' 
                                        ?   <p style={{marginBottom: '0', textAlign: 'center', color: '#212529', fontWeight: '600'}}>
                                                CC
                                            </p>
                                        : (key[0] === 'satForm' 
                                            ?   'SAT Form'
                                            :   (key[0] === 'actForm' 
                                                ?   'ACT Form'
                                                :   sanitizeFieldHeader(key[0])
                                                )
                                            )
                                        )
                                    )
                                )
                                }
                            </th>
                        )
                    }
                }
            })}
            <th className={`${styles.headings} student-table-heading options`}>
                Options
            </th>
            </>
        )
    }


    // Function to handle format of list data
    const renderTableData = (request, i) => {
        let headings = handleHeadings()
        return (
            <>
            {Object.entries(headings).map((key, j) => { // Data entries
                                                            
            if(key[0] !== '_id') { //Don't include _id
                if(j === 1) // First column is the #
                    return (
                        <td className={`student-table-data`} key={j + request._id}>
                            {i + 1}
                        </td>
                    )
                else if(i === editingIndex){ // if this request is being edited
                    if(key[0] === 'studentName')
                        return (
                            <th scope='row' className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                                <FormControl
                                    className = 'edit-student-input'
                                    defaultValue = {request[key[0]] ? request[key[0]]: ''}
                                />
                            </th>
                        )
                    else if(key[0] === 'confirmed' || key[0] === 'cc' || (key[0] === 'approved' && (eventType === 'diagnostic' || (eventType !== 'diagnostic' && listType !== 'all')))) // if the column is for approvals
                        return (
                            <td key={j + request._id + ' edit'} className={`${styles[key[0]]} d-flex justify-content-center align-items-center student-table-data`}>
                                <label className={styles.checkContainer}>
                                <input 
                                    className={`${styles.check} form-check-input Nav${i}-checkbox`} 
                                    type="checkbox"
                                    disabled
                                    readOnly
                                />
                                    <span className={styles.checkmark}></span>
                                </label>
                            </td>
                        );
                    else if( // If column cannot be edited
                        key[0] === 'studentPhone' 
                    ) 
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                {request[key[0]] ? request[key[0]]: "-"}
                            </td>
                        )
                    else if(key[0] === 'extendedTime' || key[0] === 'attended') // if editing yes/no column
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                <Form.Control as="select" className = 'edit-student-input' defaultValue = {request[key[0]]}>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Form.Control>
                            </td>
                        )
                    else if(key[0] === 'gradeLevel') // if editing grade level
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                <Form.Control as="select" className = 'edit-student-input' defaultValue = {request[key[0]]}>
                                    <option value={null}>None</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                </Form.Control>
                            </td>
                        )
                    else if(key[0] === 'testType') // if editing test type
                        return (
                            <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                                <Form.Control as="select" className = 'edit-student-input' defaultValue = {request[key[0]]}>
                                    <option value={'Completed'}>Completed</option>
                                    <option value={'SAT'}>SAT</option>
                                    <option value={'ACT'}>ACT</option>
                                </Form.Control>
                            </td>
                        )
                    else // if editing anything else
                        return (
                            <td className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                                <FormControl
                                    className = 'edit-student-input'
                                    defaultValue = {request[key[0]] ? request[key[0]]: ''}
                                />
                            </td>
                        );
                }
                // no longer editing
                else if(key[0] === 'approved') {// if approved column
                    // if approved column can be displayed
                    if((eventType === 'diagnostic' || (eventType !== 'diagnostic' && (listType !== 'all' && listType !== 'hidden')))){
                        return (
                            <td key={j + request._id} className={`${styles[key[0]]} d-flex align-items-center justify-content-center student-table-data`}>
                                <label className={styles.checkContainer}>
                                <input 
                                    className={`${styles.check} form-check-input Nav${i}-checkbox`} 
                                    type="checkbox" 
                                    onChange={(e) => handleCheck(e, request)}
                                    checked = {request.approved || (eventType !== 'diagnostic' && listType === 'approved') || approvedList.find(id => id === request._id) ? true : undefined}
                                    disabled = {request.approved || (eventType !== 'diagnostic' && listType === 'approved') ? true : false}
                                    readOnly = {request.approved || (eventType !== 'diagnostic' && listType === 'approved') ? true : false}
                                />
                                <span className={styles.checkmark}></span>
                                </label>
                            </td>
                        );
                    }
                    else {
                        return (
                            <td key={j + request._id} className={`${styles[key[0]]} d-flex align-items-center justify-content-center student-table-data`}>
                                <p style={{color: 'black'}}>Change Lists</p>
                            </td>
                        );
                    }
                }
                else if(key[0] === 'confirmed') {// if confirmed column
                    // if confirmed column can be displayed
                    if(eventType === 'diagnostic'){
                        return (
                            <td key={j + request._id} className={`${styles[key[0]]} d-flex align-items-center justify-content-center student-table-data`}>
                                <label className={styles.checkContainer}>
                                <input 
                                    className={`${styles.check} form-check-input Nav${i}-checkbox`} 
                                    type="checkbox" 
                                    name="confirmed"
                                    onChange={(e) => submitConfirmation(request._id, e)}
                                    checked = {request.confirmed}
                                />
                                <span className={styles.checkmark}></span>
                                </label>
                            </td>
                        );
                    } 
                    else {
                        return (
                            <td key={j + request._id} className={`${styles[key[0]]} d-flex justify-content-center student-table-data`}>
                                <p style={{color: 'black'}}>Change Lists</p>
                            </td>
                        );
                    }
                }
                else if(key[0] === 'cc') {// if cc column
                    // if cc column can be displayed
                    if(eventType === 'diagnostic'){
                        return (
                            <td key={j + request._id} className={`${styles[key[0]]} d-flex align-items-center justify-content-center student-table-data`}>
                                <label className={styles.checkContainer}>
                                <input 
                                    className={`${styles.check} form-check-input Nav${i}-checkbox`} 
                                    type="checkbox" 
                                    name="cc"
                                    onChange={(e) => submitConfirmation(request._id, e)}
                                    checked = {request.cc}
                                />
                                <span className={styles.checkmark}></span>
                                </label>
                            </td>
                        );
                    } else {
                        return (
                            <td key={j + request._id} className={`${styles[key[0]]} d-flex justify-content-center student-table-data`}>
                                <p style={{color: 'black'}}>Change Lists</p>
                            </td>
                        );
                    }
                }
                else if(key[0] === 'extendedTime' || key[0] === 'attended') // if yes/no column
                    return (
                        <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                            {request[key[0]] === true ? 'Yes' : 'No'}
                        </td>
                    )
                else if(key[0] === 'timeRequested' || key[0] === 'timeApproved' || key[0] === 'timeEnrolled') // if time column
                    return (
                        <td className={`${styles[key[0]]} student-table-data`} key={j + request._id}>
                            {!request[key[0]] ? 
                                '-'
                            :
                                `${new Date(request[key[0]]).toLocaleDateString(undefined, {timeZone: 'America/New_York'})} - ${new Date(request[key[0]]).toLocaleTimeString(undefined, {timeZone: 'America/New_York'})}`
                            }
                            
                        </td>
                    )
                else if(key[0] === 'studentName')
                    return (
                        <th scope='row' className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                            {request[key[0]] ? request[key[0]]: "-"}
                        </th>
                    )
                else // all other columns
                    return (
                        <td className={`${key[0].length !== 2 ? styles[key[0]] : styles.scoreHeading} student-table-data`} key={j + request._id}>
                            {request[key[0]] ? request[key[0]]: "-"}
                        </td>
                    );
            }
        })}
        <td className={`student-table-data ${styles.optionsHeading}`} hidden={checkRole(user.role)}>
            {i === editingIndex
            ?   <Button className={styles.submitBtn} onClick={() => handleSubmitEdit()}>Submit</Button>
            :   renderStudentOptions(request, i)
            }
        </td>
        </>
        )
    }

    return(
        <>
        {eventInfo && eventId === eventInfo._id?
            
            <div key={eventId} className={`d-flex justify-content-center h-100`}>
                <DisapproveModal/>
                <ConfirmationModal/>
                {eventInfo && eventType === 'diagnostic' && <AddStudentDiag 
                    show={showRegisterStudentDlg} 
                    setShowDiag={setShowRegisterStudentDlg} 
                    refresh={getEventInfo}
                    diagModalInfo={{
                        start: new Date(eventInfo.date.start),
                        end: new Date(eventInfo.date.end),
                        title: eventInfo.name,
                        desc: eventInfo.description,
                        id: eventInfo._id,
                        ...eventInfo
                    }}
                ></AddStudentDiag>}
                {eventInfo && eventType !== 'diagnostic' && <AddStudentCourse 
                    show={showRegisterStudentDlg} 
                    setShowCourse={setShowRegisterStudentDlg} 
                    refresh={getEventInfo}
                    bootcampModalInfo={{
                        title: eventInfo.name,
                        desc: eventInfo.description,
                        id: eventInfo._id,
                        ...eventInfo
                    }}
                ></AddStudentCourse>}
                {eventInfo ?
                    <div style={{width: '100%', height: '100%'}}>
                        <div className={`${styles.listHeading}`}>
                            <div className={styles.listNav}>
                                <p style={{fontSize: '24px', marginLeft: '10px', marginBottom: '0px'}}>List: </p>
                                <Form.Control
                                    as="select" 
                                    required
                                    value={!listType ? '' : listType}
                                    onChange={(event) => setListType(event.target.value)}
                                    className={`${styles.listDropDown} dropDownArrow`}
                                >
                                    {eventType === 'diagnostic' ?
                                    <>
                                        <option value={'all'}>All ({eventInfo.studentRequests.slice().filter(student => (!student.hidden)).length})</option>
                                        <option value={'approved'}>Approved ({eventInfo.studentRequests.slice().filter(student => (student.approved && !student.hidden)).length}/{eventInfo.enrollmentMax})</option>
                                        <option value={'requests'}>Requests ({eventInfo.studentRequests.slice().filter(student => (!student.approved && !student.hidden)).length})</option>
                                        <option value={'hidden'}>Hidden ({eventInfo.studentRequests.slice().filter(student => (student.hidden)).length})</option>
                                    </>
                                    :
                                    <> 
                                        <option value={'all'}>All ({eventInfo.requests.slice().filter(student => (!student.hidden)).length + eventInfo.enrolledStudents.length})</option>
                                        <option value={'approved'}>Approved ({eventInfo.enrolledStudents.length}/{eventInfo.enrollmentMax})</option>
                                        <option value={'requests'}>Requests ({eventInfo.requests.slice().filter(student => (!student.hidden)).length})</option>
                                        <option value={'hidden'}>Hidden ({eventInfo.requests.slice().filter(student => (student.hidden)).length})</option>
                                    </>
                                    }
                                </Form.Control>
                                {eventType === 'diagnostic' ?
                                    <TooltipWrapper text={'Export ALL Diagnostics'}>
                                        <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} style={{}} onClick={() => handleDownload(allEvents, 'allDiagnostic')}><FaCloudDownloadAlt/></Button>
                                    </TooltipWrapper>
                                :
                                    <TooltipWrapper text={'Export ALL Courses'}>
                                        <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} style={{}} onClick={() => handleDownload(allEvents, 'allCourse')}><FaCloudDownloadAlt/></Button>
                                    </TooltipWrapper>
                                }
                            </div>
                            <div className='d-flex align-items-center'>
                                <h4 
                                    title={eventInfo.name} 
                                    style={{marginBottom: '0px', maxWidth: '675px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                >
                                    {eventType === 'diagnostic'
                                    ?
                                        `${new Date(eventInfo.date.start).getMonth()+1}/${new Date(eventInfo.date.start).getDate()}/${new Date(eventInfo.date.start).getFullYear()} |
                                        ${new Date(eventInfo.date.start).toLocaleTimeString()} |
                                        ${eventInfo.name} (${eventInfo.studentRequests.slice().filter((request)=>(request.approved && !request.hidden)).length} / ${eventInfo.enrollmentMax} Enrolled)`
                                    :
                                        ` ${new Date(eventInfo.meetings[0].start).getMonth()+1}/${new Date(eventInfo.meetings[0].start).getDate()} - 
                                        ${new Date(eventInfo.meetings[eventInfo.meetings.length-1].start).getMonth()+1}/${new Date(eventInfo.meetings[eventInfo.meetings.length-1].start).getDate()} |
                                        ${eventInfo.name} (${eventInfo.enrolledStudents.length} / ${eventInfo.enrollmentMax} Enrolled)`
                                    }
                                    
                                </h4>
                                {eventType !== 'diagnostic'
                                ?   
                                <>
                                    <Modal
                                        show={showInfo}
                                        onHide={() => setShowInfo(false)}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Course Info</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {eventInfo?
                                            <>
                                                <p>Name: {eventInfo.name}</p>
                                                <p>Description: {eventInfo.description}</p>
                                                <p>Price: {eventInfo.price}</p>
                                                <p>Meetings:</p>
                                                {
                                                    eventInfo.meetings
                                                    .map((meeting, i) => (
                                                        <p style={{textAlign: 'center', color: 'black'}} key = {i}>
                                                            <span style={{fontWeight: '500'}}>{`Day ${i+1}: `}</span>
                                                            {`${moment(meeting.start).format('dddd')}, ${new Date(meeting.start).toLocaleDateString()}, ${moment(meeting.start).format('h:mm A')} - 
                                                            ${moment(meeting.end).format('h:mm A')}`}
                                                        </p>
                                                    ))
                                                }
                                            </>
                                            :
                                                null
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowInfo(false)}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <TooltipWrapper text={'Bootcamp Info'}>
                                        <a style={{cursor: 'pointer'}} className={`${styles.settingsBtn}`} onClick={()=>setShowInfo(true)}><FaInfoCircle style={{width: '16px', marginLeft: '5px'}} className={styles.settingsIcon}/></a>
                                    </TooltipWrapper>
                                    </>
                                :   null
                                }
                            </div>
                            <div className={styles.listNav}>
                                <Filter getEventInfo={getEventInfo} setFilterField={setFilterField} filterField={filterField} setFilterValue={setFilterValue} filterValue={filterValue} fields={getFilterableFields()}/>
                                <TooltipWrapper text={'Add Student'}>
                                    <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} onClick={() => { setEditingIndex(-1); setShowRegisterStudentDlg(true); setFilterField(undefined); setFilterValue(undefined); }} hidden={checkRole(user.role)}><FiUserPlus/></Button>
                                </TooltipWrapper>
                                <TooltipWrapper text={'Refresh List'}>
                                    <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} onClick={handleRefresh}><HiOutlineRefresh/></Button>
                                </TooltipWrapper>
                                <TooltipWrapper text={'Approve Students'}>
                                    {approveLoading
                                    ? <Button style={{height: '46px'}} className={`bcp-general-button d-flex align-items-center ${styles.generalButton} ${styles.iconButton}`} disabled> 
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button>
                                    : <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} onClick={() => {setEditingIndex(-1); setShowApprovalModal(true)}} hidden={checkRole(user.role)}><FaRegCheckCircle/></Button>
                                    }
                                    
                                </TooltipWrapper>
                                {showExpandedInfo ? (
                                    <TooltipWrapper text={'Collapse'}>
                                        <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} onClick={handleShowExpandedInfo}><BiCollapse/></Button>
                                    </TooltipWrapper>
                                ) : (
                                    <TooltipWrapper text={'Expand'}>
                                        <Button className={`bcp-general-button ${styles.generalButton} ${styles.iconButton}`} onClick={handleShowExpandedInfo}><BiExpand/></Button>
                                    </TooltipWrapper>
                                )}
                                <div className={`d-flex align-items-center`} style={{ marginRight: '5px' }} >
                                    <TooltipWrapper text={'Event Options'}>
                                        <div hidden={checkRole(user.role)}>
                                            <EventOptions 
                                                diagnosticInfo={eventInfo} 
                                                getList={refreshCalendar} 
                                                setEventInfo={setEventInfo}
                                            />
                                        </div>
                                    </TooltipWrapper>
                                </div>
                            </div>
                        </div>
                        {studentList.length > 0
                        ?
                            <div className={styles.studentList}>
                                {eventType == eventType && (
                                    <TransferModal transferInfo={transferInfo} setTransferInfo={setTransferInfo} currentEventId={eventInfo._id} allEvents={allEvents} refreshStudentList={getEventInfo} refreshCalendar={refreshCalendar} eventType={eventType}/>
                                )}
                                <Table hover size="sm" className={styles.portalStudentList} id='list-table'>
                                    <thead className={styles.stickyListHeading} id='list-heading'>
                                        <tr id='table-headings-container'>
                                            {renderTableHeadings()}
                                        </tr>
                                    </thead>
                                    <tbody className={styles.tableBody}>
                                        {(() => {
                                            
                                            return studentList.slice().map((request, i) => {
                                                return (
                                                    <tr key={`data-table-row-${i}`} className={styles.dataRow} onDoubleClick={(event) => doubleClickToEdit(i)}>
                                                        {renderTableData(request, i)}
                                                    </tr>
                                                )
                                            })}
                                        )()}
                                        
                                    </tbody>
                                </Table>
                            </div>
                        :
                        <div className={styles.emptyList}>
                            Sorry, No Entries in this List
                        </div>
                        }
                    </div>
                : null}
            </div>
        :
            null
        }
        </>
        
        )
}

export default EventList;

