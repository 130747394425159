import React,{useState, useEffect} from 'react'
import styles from './AddEventButton.module.css'
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import DatePicker from "react-datepicker"
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ErrorTooltip from '../../../ErrorTooltip/ErrorTooltip';
import { newGeneralEvent } from '../../../../api/api'
import { navigate } from "gatsby"
import moment from 'moment';

const DEFAULT_START_TIME = moment({ hour:9, minute:0 });
const DEFAULT_END_TIME = moment({ hour:12, minute:30 });

// Component
const CreateGeneralEventForm = ({setSubmitted}) => {

    const [name, setName] = useState('');
    const [diagnosticDate, setDate] = useState(new Date()); 
    const [startTime, setStart] = useState(DEFAULT_START_TIME); // modal start date
    const [endTime, setEnd] = useState(DEFAULT_END_TIME); // modal end date
    const [errors, setErrors] = useState(['', '']); // list of errors

    // When start time is selected, end time updates to the same day +3.5 Hours
    useEffect(() => {
        let newEnd = new Date(startTime);
        newEnd.setHours( newEnd.getHours() + 3);
        newEnd.setMinutes(newEnd.getMinutes() + 30)
        setEnd(newEnd);
    }, [startTime]);

    const handleCreate = () => {
        let name = document.getElementById('name').value;

        let date = {
            start: new Date(
                diagnosticDate.getFullYear(),
                diagnosticDate.getMonth(),
                diagnosticDate.getDate(),
                moment(startTime).hour(),
                moment(startTime).minute(),
                0, 0
            ),
            end: new Date(
                diagnosticDate.getFullYear(),
                diagnosticDate.getMonth(),
                diagnosticDate.getDate(),
                moment(endTime).hour(),
                moment(endTime).minute(),
                0, 0
            ),
        }

        let generalEventObject = {
            studentRequests: [],
            name: name,
            date: date,
        };

        newGeneralEvent(generalEventObject)
        .then(res => {
            console.log(res);
            setSubmitted(true);
        })
        .catch(function (err) {
            console.log(err.response);
            console.log(err.response.status)
            if(err.response.data.status === 500){
                alert(err.response.data.message)
            }
            else if(err.response.status === 401) {
                alert('Sorry! Login expired. Sign back in to refresh.');
                navigate("/portal/signin");
            }
            else {
                initializeFlags(err.response.data.data, setErrors)
            }
        });
    }

    // Function to traverse through the array of errors, and passing the appropriate message into the errors state
    const initializeFlags = () => {
        let newErrors = ['', ''];
        if(errors)
        {
            for(var i = 0; i < errors.length; i++)
            {
                if(errors[i].param === "name")
                {
                    newErrors[0] = errors[i].msg;
                }
            }
        }
        setErrors(newErrors);
    }

    const handleDateChange = date => {
        setDate(date);
    }
    const handleStartChange = date => {
        setStart(date);
    };
    const handleEndChange = date => {
        setEnd(date);
    };

    // clears the error from a single form, using the form's index
    const clearError = (index) => {
        let copy = errors.slice();
        copy[index] = '';
        setErrors(copy);
    }
    
    return (
        <>
        <Form id='diagnostic-form'>
        <Form.Group>
            <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                {errors[0].length > 0 ? <ErrorTooltip id={'name'} errorMsg={errors[0]}/> : null}
                <Form.Control defaultValue={name} className = {``} id='name' placeholder="Name" required/>
            </div>
        </Form.Group>
        <Form.Group className='d-flex'>
            <p style={{marginRight: '10px', marginTop: 'auto', marginBottom: 'auto', fontSize: '20px'}}>Date: </p>
            <DatePicker
                className={styles.dayPicker}
                id='date'
                selected={diagnosticDate}
                onChange={(date) => {handleDateChange(date)}}
                dateFormat="MM/dd/yyyy"
            />
        </Form.Group>
        <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
            {errors[1].length > 0 ? <ErrorTooltip id='time' errorMsg={errors[1]}/> : null}
            <Form.Group className='d-flex'>
                <p style={{marginBottom: 'auto', marginTop: 'auto', marginRight: '10px', fontSize: '20px'}}>Time: </p>
                <TimePicker 
                    className='timePicker'
                    use12Hours 
                    showSecond = {false} 
                    format = 'h:mm a' 
                    placeholder = 'Start' 
                    value = {moment(startTime)} 
                    minuteStep = {15} 
                    onChange = {(time)=>handleStartChange(time)}
                />
                <p style={{margin: 'auto 5px auto 5px', fontWeight: '600'}}> - </p>
                <TimePicker
                    className='timePicker'
                    use12Hours 
                    showSecond = {false} 
                    format = 'h:mm a' 
                    placeholder = 'End' 
                    value = {moment(endTime)}  
                    minuteStep = {15} 
                    onChange = {(time)=>handleEndChange(time)}
                />
            </Form.Group>
        </div>
        </Form>
        <Button variant="primary" className={`${styles.newButton} ${styles.submitBtn}`} type='submit' onClick={function () {handleCreate()}}>Create</Button>
        </>
    )
}

export default CreateGeneralEventForm;