import React from 'react';
import styles from './PortalCard.module.css'
import { navigate } from "gatsby"

import Card from 'react-bootstrap/Card'

const PortalCard = ({color, heading, pic, onRouteChange}) => {
    return(
        <a onClick={() => navigate(onRouteChange)}>
            <Card style={{backgroundColor: color}} className={`${styles.cardBox} d-flex align-items-center justify-content-center`}>
                <div style={{backgroundImage: `url(${pic})`}} className={styles.pic}></div>
                <h2 className={styles.heading}>{heading}</h2>
            </Card>
        </a>
    )
}

export default PortalCard;