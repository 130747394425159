import React,{useState, useEffect} from 'react'
import styles from '../../../calendar_components/DiagEventModal/DiagEventModal.module.css'
import '../../../calendar_components/DiagEventModal/DiagEventModal.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import ErrorTooltip from '../../../ErrorTooltip/ErrorTooltip';
import moment from 'moment'
import {newCurrentDiagnosticRequest} from '../../../../api/api'
import { scryRenderedComponentsWithType } from 'react-dom/test-utils'

const BLANK_ERRORS =  ['', '', '', '', '', '', '', '', '', '']

// Function to traverse through the array of errors, and passing the appropriate message into the errors state
const initializeFlags = (errors, setErrors) => {
    let newErrors = BLANK_ERRORS.slice();
    if(errors)
    {
      for(var i = 0; i < errors.length; i++)
      {
        if(errors[i].param === "studentName")
        {
          newErrors[0] = errors[i].msg;
        }
        else if(errors[i].param === "studentPhone")
        {
          if(newErrors[1].length === 0)
          {
            newErrors[1] = errors[i].msg
          }
        }
        else if(errors[i].param === "gradeLevel")
        {
          newErrors[2] = errors[i].msg
        }
        else if(errors[i].param === "school")
        {
          newErrors[3] = errors[i].msg
        }
        else if(errors[i].param === "parentName")
        {
          newErrors[4] = errors[i].msg
        }
        else if(errors[i].param === "parentEmail")
        {
          if(newErrors[5].length === 0)
          {
            newErrors[5] = errors[i].msg
          }
        }
        else if(errors[i].param === "parentPhone")
        {
          if(newErrors[6].length === 0)
          {
            newErrors[6] = errors[i].msg
          }
        }
        else if(errors[i].param === "mathClass")
        {
            newErrors[8] = errors[i].msg
        }
        else if(errors[i].param === "extendedTime")
        {
            newErrors[7] = errors[i].msg
        }
        else if(errors[i].param === "location")
        {
            newErrors[8] = errors[i].msg
        }
      }
    }
    setErrors(newErrors);
}
  
// Component
const AddStudentDiag = ({show, setShowDiag, diagModalInfo, refresh}) => {
    
    const [errors, setErrors] = useState(BLANK_ERRORS); //list of errors
    const [studentSubmitted, setSubmit] = useState(false); //request status
    const [type, setType] = useState(null);
    const [extended, setExtended] = useState('');
    const [location, setLocation] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(show){
          document.documentElement.style.overflow = 'hidden';
        }
        else{
          document.documentElement.style.overflow = 'auto';
        }

        return () => {document.documentElement.style.overflow = 'auto';}
    }, [show]);

    // Reset errors when a request is submitted, or new diagModalInfo is opened
    useEffect(() => {
        setErrors(BLANK_ERRORS);
    }, [studentSubmitted, show]);

   
    // function to send a student request to the database
    const handleRegister = () => {
        setLoading(true);
        let name = document.getElementById('name').value;
        let phone = document.getElementById('phone').value;
        let parentEmail = document.getElementById('parentemail').value;
    
        let requestObject = {
            testType: type,
            studentName: name, 
            studentPhone: phone,
            parentName: null, 
            parentEmail: parentEmail, 
            parentPhone: null, 
            extendedTime: extended,
            location: location
        };
    
        newCurrentDiagnosticRequest(diagModalInfo.id, requestObject)
        .then(res => {
        setLoading(false);
        console.log(res); 
        setSubmit(true);
        refresh();
        })
        .catch(function (err) {
            console.log(err.response.data.status);
            if(err.response.data.status === 500){
                alert(err.response.data.message)
            }
            else {
                initializeFlags(err.response.data.data, setErrors)
            }
            setLoading(false);
        });        
    }

    const handleClose = () => {
        setShowDiag(false);
        setTimeout(()=> {
            setType(null);
            setSubmit(false);
            setExtended('');
            setLocation('');
            setLoading(false);
        }, 500);
    }

    const clearError = (index) => {
        let copy = errors.slice();
        copy[index] = '';
        setErrors(copy);
    }

    const handleType = (e) => {
        console.log(e.target.value)
        setType(e.target.value)
    }

    const handleExtended = (e) => {
        setExtended(e.target.value);
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='dialog'
        >
            <Modal.Header closeButton>
            <Modal.Title>Add Student to Diagnostic</Modal.Title>
            </Modal.Header>
            {studentSubmitted 
            ?
                <>
                <Modal.Body>
                    <p style={{fontWeight:'500'}}>Your request has been submitted!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
                </>
            :  
                <>
                <Modal.Body>
                    <p style={{fontSize: '18px'}}>Date: <span style={{color: 'black'}}>{moment(diagModalInfo.start).format('dddd M/D/YYYY')}</span></p>
                    <p style={{fontSize: '18px'}}>Time: <span style={{color: 'black'}}>{diagModalInfo.start.toLocaleTimeString()} - {diagModalInfo.end.toLocaleTimeString()}</span></p>
                    <Form id='requestForm'>
                        <Form.Group>
                            <p style={{marginBottom: '10px', fontSize: '18px'}}>Please select the test type for the student: </p>
                            <div>
                                <Form.Check 
                                    inline
                                    type='radio'
                                    name='diagtype'
                                    value='SAT'
                                    label={`SAT`}
                                    onChange={handleType}
                                />
                                <Form.Check 
                                    inline
                                    type='radio'
                                    name='diagtype'
                                    value='ACT'
                                    label={`ACT`}
                                    onChange={handleType}
                                />
                            </div>
                        </Form.Group>
                        {type ?
                            <>
                            <p style={{marginBottom: '10px', fontSize: '18px'}}>Please fill out the form below: </p>
                            <Form.Group>
                            <div onFocus={()=>clearError(0)} style={{position: 'relative'}}>
                                {errors[0].length > 0 ? <ErrorTooltip id='name' errorMsg={errors[0]}/> : null}
                                <Form.Control  className = {``} id='name' placeholder="Student Name" required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(1)} style={{position: 'relative'}}>
                                {errors[1].length > 0 ? <ErrorTooltip id='phone' errorMsg={errors[1]}/> : null}
                            <Form.Control className = {``} id='phone' placeholder="Student Phone Number" required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(5)} style={{position: 'relative'}}>
                                {errors[5].length > 0 ? <ErrorTooltip id='parentemail' errorMsg={errors[5]}/> : null}
                                <Form.Control className = {``} id='parentemail' type='email' placeholder="Parent/Guardian Email" required/>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(7)} style={{position: 'relative'}}>
                                {errors[7].length > 0 ? <ErrorTooltip id='extended' errorMsg={errors[7]}/> : null}
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>Does the student require extended time?: </p>
                                <div>
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='extended'
                                        value={true}
                                        label={`Yes`}
                                        onChange={handleExtended}
                                    />
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='extended'
                                        value={false}
                                        label={`No`}
                                        onChange={handleExtended}
                                    />
                                </div>
                            </div>
                            </Form.Group>
                            <Form.Group>
                            <div onFocus={()=>clearError(8)} style={{position: 'relative'}}>
                                {errors[8].length > 0 ? <ErrorTooltip id='extended' errorMsg={errors[8]}/> : null}
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>How will the Student be attending?: </p>
                                <div>
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='location'
                                        value={'Zoom'}
                                        label={`Zoom`}
                                        onChange={() => setLocation('Zoom')}
                                    />
                                    <Form.Check 
                                        inline
                                        type='radio'
                                        name='location'
                                        value={'In Person'}
                                        label={`In Person`}
                                        onChange={() => setLocation('In Person')}
                                    />
                                </div>
                            </div>
                            </Form.Group>
                            </>
                        :   
                            null
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className={styles.cancel} onClick={handleClose}>
                        Cancel
                    </Button>
                    {type ? 
                        (loading ?
                            <Button 
                                variant="primary" 
                                className={styles.subButton}
                                disabled
                                style={{minWidth: '83px'}}
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                        :
                            <Button 
                                variant="primary" 
                                className={styles.subButton}
                                type='submit' 
                                onClick={function () {handleRegister()}}
                            >
                                Register
                            </Button>
                        )
                    :
                        <Button 
                            variant="primary" 
                            className={styles.subButton}
                            disabled
                        >
                            Register
                        </Button>
                    }
                </Modal.Footer>
                </>
            }
        </Modal>
    )
}
export default AddStudentDiag;